import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfitAnalysis, getProfitabilityTracker } from '../../features/profitAnalysis/profitAnanlysisApi';
const useProfitAnalysis = () => {
    const dispatch = useDispatch();
    const { metrics, profitabilityTracker } = useSelector(state => state.profitAnalysis);
    const { apiPayload } = useSelector(state => state.filter);
    const { fromDate, toDate, geoLocationID, customerTypeID, entityID } = apiPayload;

    useEffect(() => {
        if (!fromDate && !toDate && !geoLocationID && !customerTypeID && !entityID) return;

        const payload = {
            fromDate,
            toDate,
            geoLocationID,
            entityID,
            customerTypeID,
        };

        dispatch(getProfitAnalysis(payload));
        dispatch(getProfitabilityTracker(payload));
    }, [dispatch, fromDate, toDate, geoLocationID, customerTypeID, entityID]);

    return {
        metrics,
        profitabilityTracker,
    };
};

export default useProfitAnalysis;
