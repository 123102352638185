import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { isPositive } from './common';

const { Text } = Typography;
const status = {
    true: <ArrowUpOutlined style={{ color: 'green' }} />,
    false: <ArrowDownOutlined style={{ color: 'red' }} />,
};

const formatNumbers = str => {
    const number = Number(str);
    const isPositiveNumber = isPositive(number);
    if (isPositiveNumber) {
        return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
    const removeNegative = Math.abs(number);
    return `(${removeNegative.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })})`;
};

export const getForexImpactAnalysisColumn = (tableHeadings, compareTo) => {
    if (!tableHeadings) return;

    return [
        {
            title: tableHeadings?.components,
            dataIndex: 'components',
            key: 'components',
            width: '32%',
            render: (names, all) => (all?.components === 'Total' ? <Text strong>{names}</Text> : <Text>{names}</Text>),
        },
        {
            title: tableHeadings?.iRvsPO,
            dataIndex: 'iRvsPO',
            key: 'iRvsPO',
            render: (iRvsPO, all) =>
                all?.components === 'Total' ? (
                    <Text strong>${formatNumbers(iRvsPO?.value)}</Text>
                ) : (
                    <Text>${formatNumbers(iRvsPO?.value)}</Text>
                ),
        },
        {
            title: tableHeadings?.gRvsPO,
            key: 'gRvsPO',
            dataIndex: 'gRvsPO',
            render: (gRvsPO, all) =>
                all?.components === 'Total' ? (
                    <Text strong>${formatNumbers(gRvsPO?.value)}</Text>
                ) : (
                    <Text>${formatNumbers(gRvsPO?.value)}</Text>
                ),
        },
        {
            title: tableHeadings?.lYvsCYIR,
            key: 'lYvsCYIR',
            dataIndex: 'lYvsCYIR',
            render: (lYvsCYIR, all) =>
                all?.components === 'Total' ? (
                    <Text strong>${formatNumbers(lYvsCYIR?.value)}</Text>
                ) : (
                    <Text>${formatNumbers(lYvsCYIR?.value)}</Text>
                ),
        },
        {
            title: tableHeadings?.lYvsCYGR,
            key: 'lYvsCYGR',
            dataIndex: 'lYvsCYGR',
            render: (lYvsCYGR, all) =>
                all?.components === 'Total' ? (
                    <Text strong>${formatNumbers(lYvsCYGR?.value)}</Text>
                ) : (
                    <Text>${formatNumbers(lYvsCYGR?.value)}</Text>
                ),
        },
    ];
};

export const getCurrencyPairImpactColumn = (tableHeadings, compareTo) => {
    if (!tableHeadings) return;

    return [
        {
            title: tableHeadings?.components,
            dataIndex: 'components',
            key: 'components',
            width: '32%',
            render: (names, all) => (all?.components === 'Total' ? <Text strong>{names}</Text> : <Text>{names}</Text>),
        },
        {
            title: tableHeadings?.iRvsPO,
            dataIndex: 'iRvsPO',
            key: 'iRvsPO',
            render: (iRvsPO, all) =>
                all?.components === 'Total' ? (
                    <Text strong>${formatNumbers(iRvsPO?.value)}</Text>
                ) : (
                    <Text>${formatNumbers(iRvsPO?.value)}</Text>
                ),
        },
        {
            title: tableHeadings?.gRvsPO,
            key: 'gRvsPO',
            dataIndex: 'gRvsPO',
            render: (gRvsPO, all) =>
                all?.components === 'Total' ? (
                    <Text strong>${formatNumbers(gRvsPO?.value)}</Text>
                ) : (
                    <Text>${formatNumbers(gRvsPO?.value)}</Text>
                ),
        },
        {
            title: tableHeadings?.lYvsCYIR,
            key: 'lYvsCYIR',
            dataIndex: 'lYvsCYIR',
            render: (lYvsCYIR, all) =>
                all?.components === 'Total' ? (
                    <Text strong>${formatNumbers(lYvsCYIR?.value)}</Text>
                ) : (
                    <Text>${formatNumbers(lYvsCYIR?.value)}</Text>
                ),
        },
        {
            title: tableHeadings?.lYvsCYGR,
            key: 'lYvsCYGR',
            dataIndex: 'lYvsCYGR',
            render: (lYvsCYGR, all) =>
                all?.components === 'Total' ? (
                    <Text strong>${formatNumbers(lYvsCYGR?.value)}</Text>
                ) : (
                    <Text>${formatNumbers(lYvsCYGR?.value)}</Text>
                ),
        },
    ];
};
export const getTopImpactItemsData = (tableHeadings, compareTo) => {
    if (!tableHeadings) return;

    return [
        {
            title: tableHeadings?.components,
            dataIndex: 'components',
            key: 'components',
            width: '32%',
            render: components => <div style={{ overflowX: 'clip' }}>{components}</div>,
        },
        {
            title: tableHeadings?.iRvsPO,
            dataIndex: 'iRvsPO',
            key: 'iRvsPO',
            render: iRvsPO => <Text>${formatNumbers(iRvsPO?.value)}</Text>,
        },
        {
            title: tableHeadings?.gRvsPO,
            key: 'gRvsPO',
            dataIndex: 'gRvsPO',
            render: gRvsPO => <Text>${formatNumbers(gRvsPO?.value)}</Text>,
        },
        {
            title: tableHeadings?.lYvsCYIR,
            key: 'lYvsCYIR',
            dataIndex: 'lYvsCYIR',
            render: lYvsCYIR => <Text>${formatNumbers(lYvsCYIR?.value)}</Text>,
        },
        {
            title: tableHeadings?.lYvsCYGR,
            key: 'lYvsCYGR',
            dataIndex: 'lYvsCYGR',
            render: lYvsCYGR => <Text>${formatNumbers(lYvsCYGR?.value)}</Text>,
        },
    ];
};

export const getTopImpactItemsGains = (tableHeadings, compareTo) => {
    if (!tableHeadings) return;

    return [
        {
            title: tableHeadings?.components,
            dataIndex: 'components',
            key: 'components',
            width: '32%',
            render: (names, all) => (all?.components === 'Total' ? <Text strong>{names}</Text> : <Text>{names}</Text>),
        },
        {
            title: tableHeadings?.iRvsPO,
            dataIndex: 'iRvsPO',
            key: 'iRvsPO',
            render: (iRvsPO, all) =>
                all?.components === 'Total' ? (
                    <Text strong>${formatNumbers(iRvsPO?.value)}</Text>
                ) : (
                    <Text>${formatNumbers(iRvsPO?.value)}</Text>
                ),
        },
        {
            title: tableHeadings?.gRvsPO,
            key: 'gRvsPO',
            dataIndex: 'gRvsPO',
            render: (gRvsPO, all) =>
                all?.components === 'Total' ? (
                    <Text strong>${formatNumbers(gRvsPO?.value)}</Text>
                ) : (
                    <Text>${formatNumbers(gRvsPO?.value)}</Text>
                ),
        },
        {
            title: tableHeadings?.lYvsCYIR,
            key: 'lYvsCYIR',
            dataIndex: 'lYvsCYIR',
            render: (lYvsCYIR, all) =>
                all?.components === 'Total' ? (
                    <Text strong>${formatNumbers(lYvsCYIR?.value)}</Text>
                ) : (
                    <Text>${formatNumbers(lYvsCYIR?.value)}</Text>
                ),
        },
        {
            title: tableHeadings?.lYvsCYGR,
            key: 'lYvsCYGR',
            dataIndex: 'lYvsCYGR',
            render: (lYvsCYGR, all) =>
                all?.components === 'Total' ? (
                    <Text strong>${formatNumbers(lYvsCYGR?.value)}</Text>
                ) : (
                    <Text>${formatNumbers(lYvsCYGR?.value)}</Text>
                ),
        },
    ];
};
