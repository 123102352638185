import React from 'react';

const PowerIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="none" viewBox="0 0 16 17">
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M10.579 2.533a.83.83 0 011.127-.325c2.523 1.395 4.184 4.125 4.05 7.195-.19 4.328-3.872 7.674-8.216 7.484-4.344-.19-7.721-3.843-7.532-8.172A7.821 7.821 0 014.03 2.22a.83.83 0 01.808 1.45 6.162 6.162 0 00-3.173 5.118c-.148 3.402 2.509 6.291 5.947 6.441 3.439.15 6.338-2.496 6.486-5.898a6.172 6.172 0 00-3.195-5.67.83.83 0 01-.324-1.128z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M7.747 0a.83.83 0 01.83.83v7.052a.83.83 0 11-1.66 0V.83a.83.83 0 01.83-.83z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
};

export default PowerIcon;
