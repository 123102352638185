import { configureStore } from '@reduxjs/toolkit';
import summary from './features/summary';
import keyMetricsSlice from './features/Keymetrics';
import filter from './features/filters';
import forexImpact from './features/forexImpact';
import operationAnalysis from './features/operationAnalysis';
import profitAnalysis from './features/profitAnalysis';
import salesAnalysis from './features/salesAnalysis';
import transportRate from './features/transportRate';
export const store = configureStore({
    reducer: {
        summary,
        keyMetricsSlice,
        filter,
        salesAnalysis,
        forexImpact,
        profitAnalysis,
        operationAnalysis,
        transportRate,
    },
});
