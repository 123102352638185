import { createSlice } from '@reduxjs/toolkit';
import { getTransportImpact, getTransportMetrics } from './transportRateApi';

const initialState = {
    metrics: {
        isLoading: true,
        isError: false,
        data: [],
    },
    transportImpact: {
        isLoading: true,
        isError: false,
        data: [],
    },
};

const transportRateSlice = createSlice({
    name: 'transportRate',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getTransportMetrics.pending, state => {
                state.metrics.isLoading = true;
            })
            .addCase(getTransportMetrics.fulfilled, (state, action) => {
                state.metrics.isLoading = false;
                state.metrics.data = action.payload;
            })
            .addCase(getTransportMetrics.rejected, (state, action) => {
                state.metrics.isLoading = false;
            })
            .addCase(getTransportImpact.pending, state => {
                state.transportImpact.isLoading = true;
            })
            .addCase(getTransportImpact.fulfilled, (state, action) => {
                state.transportImpact.isLoading = false;
                state.transportImpact.data = action.payload;
            })
            .addCase(getTransportImpact.rejected, (state, action) => {
                state.transportImpact.isLoading = false;
            });
    },
});

export default transportRateSlice.reducer;
