import React from 'react';
import { Row, Col, Space, Select, Table, Card, Typography, Avatar } from 'antd';
import '../../helpers/override.scss';

const Glossary = () => {
    const { Title, Text } = Typography;
    const data = {
        head: 'Transportation Impact',
        month: '',
        label: 'Transportation Impact',
    };

    const project = [
        {
            title: 'Acronym',
            dataIndex: 'acronym',
            width: '32%',
        },
        {
            title: 'Full Form',
            dataIndex: 'fullform',
        },
    ];
    const dataproject = [
        {
            key: '1',

            acronym: (
                <>
                    <Avatar.Group>
                        <div className="avatar-info">
                            <Title level={5}>COGS</Title>
                        </div>
                    </Avatar.Group>
                </>
            ),
            fullform: (
                <>
                    <div>Cost Of Goods Sold</div>
                </>
            ),
        },

        {
            key: '2',
            acronym: (
                <>
                    <Avatar.Group>
                        <div className="avatar-info">
                            <Title level={5}>PPV</Title>
                        </div>
                    </Avatar.Group>
                </>
            ),
            fullform: (
                <>
                    <div>Purchase Price Variance</div>
                </>
            ),
        },

        {
            key: '3',
            acronym: (
                <>
                    <Avatar.Group>
                        <div className="avatar-info">
                            <Title level={5}>PO</Title>
                        </div>
                    </Avatar.Group>
                </>
            ),
            fullform: (
                <>
                    <div>Purchase Order</div>
                </>
            ),
        },

        {
            key: '4',
            acronym: (
                <>
                    <Avatar.Group>
                        <div className="avatar-info">
                            <Title level={5}>LY</Title>
                        </div>
                    </Avatar.Group>
                </>
            ),
            fullform: (
                <>
                    <div>Last Year</div>
                </>
            ),
        },
        {
            key: '5',
            acronym: (
                <>
                    <Avatar.Group>
                        <div className="avatar-info">
                            <Title level={5}>CY</Title>
                        </div>
                    </Avatar.Group>
                </>
            ),
            fullform: (
                <>
                    <div>Current Year</div>
                </>
            ),
        },
        {
            key: '6',
            acronym: (
                <>
                    <Avatar.Group>
                        <div className="avatar-info">
                            <Title level={5}>GR</Title>
                        </div>
                    </Avatar.Group>
                </>
            ),
            fullform: (
                <>
                    <div>Goods Receipt date</div>
                </>
            ),
        },
        {
            key: '7',
            acronym: (
                <>
                    <Avatar.Group>
                        <div className="avatar-info">
                            <Title level={5}>IR</Title>
                        </div>
                    </Avatar.Group>
                </>
            ),
            fullform: (
                <>
                    <div>Invoice Receipt date</div>
                </>
            ),
        },
    ];

    return (
        <div>
            {/* <Breadcrumb label={data.label} /> */}
            <div className="main-container">
                <div className="jv-table">
                    <Row gutter={[16, 24]}>
                        <Col span={24}>
                            <Card bordered={false} className="criclebox tablespace mb-24">
                                <div className="table-responsive">
                                    <Table
                                        columns={project}
                                        dataSource={dataproject}
                                        pagination={false}
                                        className="ant-border-space"
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default Glossary;
