import React from 'react';

const ProfitCenter = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none" viewBox="0 0 23 23">
            <path
                fill="#6A6A6A"
                d="M11.369 14.053c-.055-2.947 2.384-5.77 5.814-5.777 3.195-.007 5.82 2.645 5.813 5.84-.007 3.153-2.666 5.784-5.862 5.756-3.401-.027-5.84-2.844-5.765-5.819zm5.16-1.277c.008-.426.392-.728.79-.598.2.069.379.2.558.323.33.234.687.206.92-.069.227-.274.193-.646-.096-.92-.082-.076-.172-.172-.268-.206-.577-.186-.721-.577-.66-1.148.035-.336-.274-.584-.625-.584a.603.603 0 00-.618.598c-.007.151-.014.295 0 .447.013.226-.042.357-.275.474-.64.322-.99.872-1.024 1.587-.048 1.14.728 1.95 1.938 2.04.282.02.522.158.57.412.041.206-.02.481-.13.66a.5.5 0 01-.674.165 3.126 3.126 0 01-.625-.46c-.289-.248-.64-.276-.9-.049-.275.24-.31.618-.056.914.159.178.324.391.537.474.55.22.721.584.652 1.154-.04.337.282.584.626.577a.616.616 0 00.618-.57c.014-.158.02-.323 0-.481-.027-.234.062-.357.282-.474a1.89 1.89 0 00.88-2.405c-.323-.796-.949-1.167-1.78-1.236-.413-.007-.646-.247-.64-.625z"
            ></path>
            <path
                fill="#6A6A6A"
                d="M8.771 22.49V5.659c-.639 0-1.25.007-1.862 0-.481-.007-.77-.33-.68-.77a.803.803 0 01.24-.398C7.94 3.22 9.417 1.949 10.902.69c.302-.26.604-.253.9-.006 1.477 1.264 2.955 2.528 4.432 3.799.323.275.399.605.158.872a.984.984 0 01-.611.289c-.605.041-1.21.014-1.856.014 0 .618-.007 1.209.007 1.8.007.233-.062.357-.275.48-2.563 1.45-3.986 4.356-3.498 7.255.371 2.212 1.56 3.895 3.498 5.029.213.123.289.247.275.48-.014.378 0 .756-.007 1.134-.007.398-.24.653-.639.66-1.491 0-2.975-.007-4.515-.007z"
            ></path>
            <path
                fill="#6A6A6A"
                d="M7.459 9.574v12.888H4.937a4.865 4.865 0 01-.014-.323V10.365c0-.591.2-.79.777-.79h1.759zM3.549 22.49c-.66 0-1.285.013-1.918-.007C1.226 22.476 1 22.2 1 21.748v-5.324c0-.344-.007-.687 0-1.03.007-.386.24-.653.598-.66.64-.014 1.278-.007 1.952-.007v7.763z"
            ></path>
        </svg>
    );
};

export default ProfitCenter;
