import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);
dayjs.extend(localeData);
const dateFormate = 'YYYY/MM/DD';

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export const formatDates = dateData => {
    const date = dayjs(dateData);
    return date.format('YYYY-MM');
};

/**
 * Get the current date and the date 30 days ago and format them.
 *
 * @returns {Object} An object containing the current date and the date 30 days ago in formatted strings.
 * @property {string} currentDate - The current date in the format 'DD-MM-YYYY'.
 * @property {string} last30Days - The date 30 days ago in the format 'DD-MM-YYYY'.
 */
export const getFirstAndLast30Days = () => {
    const currentDate = dayjs();
    // Get the month and year of the previous month
    const lastMonth = currentDate.subtract(1, 'month');
    return {
        currentDate: formatDates(currentDate),
        last30Days: formatDates(lastMonth),
    };
};

export const getPreviousYearDate = currentDate => {
    // Parse the original date string using dayjs
    const dateObj = dayjs(currentDate);
    const month = dateObj.month() + 1;
    const lastYearDate = dateObj.subtract(1, 'year');
    const previousYearFirstMonth = lastYearDate.startOf('year');
    const day = dateObj.date();

    // Set the year, month, and day of the new date
    const previousYearSubtractMonth = lastYearDate.set({ year: lastYearDate.year(), month, date: day });

    return { previousYearFirstMonth, previousYearSubtractMonth };
};
