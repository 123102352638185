import React from 'react';

const ForexImpact = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none" viewBox="0 0 23 23">
            <path
                fill="#6A6A6A"
                d="M19.207 3.195V1.74c0-.13-.007-.262.016-.385.054-.37.33-.608.646-.577.332.03.555.277.555.662.007.577 0 1.155 0 1.732.046.023.077.046.108.054 1.008.177 1.493.747 1.493 1.763V16.498c0 1.262-.354 1.717-1.601 2.025V20.2c0 .454-.239.739-.608.731-.34 0-.593-.285-.609-.708-.015-.562 0-1.124 0-1.71-.123-.03-.23-.053-.33-.084-.809-.215-1.232-.747-1.232-1.578V4.842c0-.815.438-1.37 1.24-1.578.091-.023.191-.046.322-.069zM1.632 17.853c-.208-.046-.37-.07-.531-.116a1.54 1.54 0 01-1.07-1.493 2693.91 2693.91 0 010-11.155c0-.878.562-1.424 1.601-1.601 0-.655-.023-1.317.008-1.979.007-.208.108-.462.254-.593.115-.107.43-.146.554-.069.185.131.362.377.385.6.054.509.015 1.025.023 1.54v.5c.185.047.346.078.5.132a1.446 1.446 0 011.055 1.416c.008 3.757.008 7.514 0 11.27 0 .724-.516 1.34-1.232 1.456-.284.046-.354.161-.338.423.015.5.007 1 0 1.501 0 .123-.008.254-.039.377-.077.331-.292.508-.639.478-.346-.039-.531-.262-.539-.593 0-.678.008-1.37.008-2.094zM13.349 9.046c0-.293-.008-.562 0-.832.015-.4.27-.67.6-.677.354-.008.6.277.616.693.008.27 0 .531 0 .793 1.178.23 1.61.762 1.61 1.947v8.392c0 1.155-.455 1.693-1.61 1.909 0 .27.008.554 0 .831-.007.393-.261.662-.608.662-.316 0-.57-.261-.6-.63-.016-.14-.008-.286-.008-.424v-.44c-.062-.014-.108-.03-.154-.038-.893-.138-1.44-.708-1.44-1.616-.015-2.98-.015-5.959 0-8.93.008-.94.555-1.478 1.594-1.64zM7.49 7.044c0-.385-.007-.746 0-1.1.008-.424.247-.701.601-.709.346 0 .6.277.608.7.008.363 0 .717 0 1.102.07.015.123.038.177.046.94.192 1.417.754 1.417 1.724.007 1.748.007 3.488 0 5.235-.008.993-.408 1.432-1.602 1.77 0 .347.008.709 0 1.063-.007.454-.254.74-.608.74-.37 0-.6-.286-.6-.747v-1.063c-.2-.054-.4-.085-.585-.154-.685-.277-1.009-.816-1.016-1.532a314.284 314.284 0 010-5.389c.015-.954.515-1.462 1.608-1.686z"
            ></path>
        </svg>
    );
};

export default ForexImpact;
