import { Checkbox } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCheckedEntity } from '../../features/filters';
const Entity = ({ selectedEntity, handleCustomerTypeChange, handleCustomerType }) => {
    const dispatch = useDispatch();
    const { checkedEntity } = useSelector(state => state.filter);

    return (
        <div>
            <>
                <h2>All</h2>
                <Checkbox
                    className="jv-breadcrumb__checkbox--all-checkbox"
                    checked={checkedEntity.includes(0)}
                    onClick={() => {
                        let newCheckEntity;
                        if (checkedEntity.includes(0)) {
                            newCheckEntity = [0];
                        } else {
                            newCheckEntity = [];
                        }
                        const latestGeography = newCheckEntity.length === 0 ? [0] : [];
                        dispatch(setCheckedEntity(latestGeography));
                        handleCustomerType([0])
                        handleCustomerTypeChange(latestGeography,'entity')
                    }}
                >
                    All
                </Checkbox>
            </>
            {selectedEntity?.length > 0 &&
                selectedEntity?.map(item => {
                    return (
                        <React.Fragment key={item.geographyID}>
                            <h2>{item.geography}</h2>
                            {item.entities.map(entity => {
                                const isCheck = checkedEntity.includes(entity.value);
                                return (
                                    <Checkbox
                                        key={entity.value}
                                        checked={isCheck}
                                        className="jv-breadcrumb__checkbox--entity"
                                        onClick={() => {
                                            if (isCheck) {
                                                const newValue = checkedEntity.filter(item => item !== entity.value);
                                                dispatch(setCheckedEntity(newValue));
                                                handleCustomerTypeChange(newValue,'entity')
                                            } else {
                                                const newValue = [...checkedEntity, entity.value].filter(
                                                    item => item !== 0,
                                                );
                                                dispatch(setCheckedEntity(newValue));
                                                handleCustomerType([0])
                                                handleCustomerTypeChange(newValue,'entity')
                                            }
                                        }}
                                    >
                                        {entity.label}
                                    </Checkbox>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
        </div>
    );
};

export default Entity;
