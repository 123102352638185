import { ArrowDownOutlined, ArrowUpOutlined, ConsoleSqlOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { formatNumbers, isPositive } from './common';
const { Title, Text } = Typography;
const status = {
    true: <ArrowUpOutlined style={{ color: 'green' }} />,
    false: <ArrowDownOutlined style={{ color: 'red' }} />,
};

export const getRegionalContributionColumn = (tableHeadings, compareTo) => {
    if (!tableHeadings) return;

    return [
        {
            title: tableHeadings?.components,
            dataIndex: 'components',
            key: 'components',
            width: '25%',
            render: (names, record) =>
                record.components === 'Total' ? <Text strong>{names}</Text> : <Text>{names}</Text>,
        },
        {
            title: tableHeadings?.grossSales,
            dataIndex: 'grossSales',
            key: 'grossSales',
            render: (grossSales, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(grossSales?.value) ? 'green' : 'red'}`
                    }>
                    {record.isChild || '$'}
                    {formatNumbers(grossSales?.value)}
                </Text>
            ),
        },
        {
            title: tableHeadings?.discounts,
            colSpan: 1,
            key: 'discounts',
            dataIndex: 'discounts',
            render: (discounts, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(discounts?.value) ? 'green' : 'red'}`
                    }>
                    {record.isChild || '$'}
                    {formatNumbers(discounts?.value)}
                    {record.isChild && ' %'}
                </Text>
            ),
        },
        {
            title: tableHeadings?.cogs,
            key: 'cogs',
            dataIndex: 'cogs',
            render: (cogs, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(cogs?.value) ? 'green' : 'red'}`
                    }>
                    {record.isChild || '$'}
                    {formatNumbers(cogs?.value)}
                    {record.isChild && ' %'}
                </Text>
            ),
        },
        {
            title: tableHeadings?.ppv,
            key: 'ppv',
            dataIndex: 'ppv',
            render: (ppv, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(ppv?.value) ? 'green' : 'red'}`
                    }>
                    {record.isChild || '$'}
                    {formatNumbers(ppv?.value)}
                    {record.isChild && ' %'}
                </Text>
            ),
        },
        {
            title: tableHeadings?.variances,
            key: 'variances',
            dataIndex: 'variances',
            render: (variances, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(variances?.value) ? 'green' : 'red'}`
                    }>
                    {record.isChild || '$'}
                    {formatNumbers(variances?.value)}
                    {record.isChild && ' %'}
                </Text>
            ),
        },
        {
            title: tableHeadings?.delivery,
            key: 'delivery',
            dataIndex: 'delivery',
            render: (delivery, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(delivery?.value) ? 'green' : 'red'}`
                    }>
                    {record.isChild || '$'}
                    {formatNumbers(delivery?.value)}
                    {record.isChild && ' %'}
                </Text>
            ),
        },
    ];
};

export const getEntityContributionColumn = (tableHeadings, compareTo) => {
    if (!tableHeadings) return;

    return [
        {
            title: tableHeadings?.components,
            dataIndex: 'components',
            key: 'components',
            width: '25%',
            render: (names, record) =>
                record.components === 'Total' ? <Text strong>{names}</Text> : <Text>{names}</Text>,
        },
        {
            title: tableHeadings?.grossSales,
            dataIndex: 'grossSales',
            key: 'grossSales',
            render: (grossSales, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(grossSales?.value) ? 'green' : 'red'}`
                    }>
                    {record.isChild || '$'}
                    {formatNumbers(grossSales?.value)}
                </Text>
            ),
        },
        {
            title: tableHeadings?.discounts,
            colSpan: 1,
            key: 'discounts',
            dataIndex: 'discounts',
            render: (discounts, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(discounts?.value) ? 'green' : 'red'}`
                    }>
                    {record.isChild || '$'}
                    {formatNumbers(discounts?.value)}
                    {record.isChild && ' %'}
                </Text>
            ),
        },
        {
            title: tableHeadings?.cogs,
            key: 'cogs',
            dataIndex: 'cogs',
            render: (cogs, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(cogs?.value) ? 'green' : 'red'}`
                    }>
                    {record.isChild || '$'}
                    {formatNumbers(cogs?.value)}
                    {record.isChild && ' %'}
                </Text>
            ),
        },
        {
            title: tableHeadings?.ppv,
            key: 'ppv',
            dataIndex: 'ppv',
            render: (ppv, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(ppv?.value) ? 'green' : 'red'}`
                    }>
                    {record.isChild || '$'}
                    {formatNumbers(ppv?.value)}
                    {record.isChild && ' %'}
                </Text>
            ),
        },
        {
            title: tableHeadings?.variances,
            key: 'variances',
            dataIndex: 'variances',
            render: (variances, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(variances?.value) ? 'green' : 'red'}`
                    }>
                    {record.isChild || '$'}
                    {formatNumbers(variances?.value)}
                    {record.isChild && ' %'}
                </Text>
            ),
        },
        {
            title: tableHeadings?.delivery,
            key: 'delivery',
            dataIndex: 'delivery',
            render: (delivery, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(delivery?.value) ? 'green' : 'red'}`
                    }>
                    {record.isChild || '$'}
                    {formatNumbers(delivery?.value)}
                    {record.isChild && ' %'}
                </Text>
            ),
        },
    ];
};

export const getCustomerTypeColumn = (tableHeadings, compareTo) => {
    if (!tableHeadings) return;

    return [
        {
            title: tableHeadings?.components,
            dataIndex: 'components',
            key: 'components',
            width: '25%',
            render: (names, record) =>
                record.components === 'Total' ? <Text strong>{names}</Text> : <Text>{names}</Text>,
        },
        {
            title: tableHeadings?.grossSales,
            dataIndex: 'grossSales',
            key: 'grossSales',
            render: (grossSales, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(grossSales?.value) ? 'green' : 'red'}`
                    }>
                    {record.isChild || '$'}
                    {formatNumbers(grossSales?.value)}
                </Text>
            ),
        },
        {
            title: tableHeadings?.discounts,
            colSpan: 1,
            key: 'discounts',
            dataIndex: 'discounts',
            render: (discounts, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(discounts?.value) ? 'green' : 'red'}`
                    }>
                    {record.isChild || '$'}
                    {formatNumbers(discounts?.value)}
                    {record.isChild && ' %'}
                </Text>
            ),
        },
        {
            title: tableHeadings?.cogs,
            key: 'cogs',
            dataIndex: 'cogs',
            render: (cogs, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(cogs?.value) ? 'green' : 'red'}`
                    }>
                    {record.isChild || '$'}
                    {formatNumbers(cogs?.value)}
                    {record.isChild && ' %'}
                </Text>
            ),
        },
        {
            title: tableHeadings?.ppv,
            key: 'ppv',
            dataIndex: 'ppv',
            render: (ppv, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(ppv?.value) ? 'green' : 'red'}`
                    }>
                    {record.isChild || '$'}
                    {formatNumbers(ppv?.value)}
                    {record.isChild && ' %'}
                </Text>
            ),
        },
        {
            title: tableHeadings?.variances,
            key: 'variances',
            dataIndex: 'variances',
            render: (variances, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(variances?.value) ? 'green' : 'red'}`
                    }>
                    {record.isChild || '$'}
                    {formatNumbers(variances?.value)}
                    {record.isChild && ' %'}
                </Text>
            ),
        },
        {
            title: tableHeadings?.delivery,
            key: 'delivery',
            dataIndex: 'delivery',
            render: (delivery, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(delivery?.value) ? 'green' : 'red'}`
                    }>
                    {record.isChild || '$'}
                    {formatNumbers(delivery?.value)}
                    {record.isChild && ' %'}
                </Text>
            ),
        },
    ];
};

export const getRegionalContributionComponentVarianceColumn = (tableHeadings, compareTo) => {
    if (!tableHeadings) return;

    return [
        {
            title: tableHeadings?.components,
            dataIndex: 'components',
            key: 'components',
            width: '25%',
            render: (names, record) =>
                record.isTotal === true ? <Text strong={record?.isTotal}>{names}</Text> : <Text>{names}</Text>,
        },
        {
            title: tableHeadings?.grossSales,
            dataIndex: 'grossSales',
            key: 'grossSales',
            render: (grossSales, record) => (
                <Text
                    strong={record?.isTotal}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(grossSales?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(grossSales?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: tableHeadings?.discounts,
            colSpan: 1,
            key: 'discounts',
            dataIndex: 'discounts',
            render: (discounts, record) => (
                <Text
                    strong={record?.isTotal}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(discounts?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(discounts?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: tableHeadings?.cogs,
            key: 'cogs',
            dataIndex: 'cogs',
            render: (cogs, record) => (
                <Text
                    strong={record?.isTotal}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(cogs?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(cogs?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: tableHeadings?.ppv,
            key: 'ppv',
            dataIndex: 'ppv',
            render: (ppv, record) => (
                <Text
                    strong={record?.isTotal}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(ppv?.percentvalue) ? 'green' : 'red'}`
                    }>
                    { ppv?.percentvalue ? `${formatNumbers(ppv?.percentvalue)} %` : '0 %'}
                    {record.isChild && ' %'}
                </Text>
            ),
        },
        {
            title: tableHeadings?.variances,
            key: 'variances',
            dataIndex: 'variances',
            render: (variances, record) => (
                <Text
                    strong={record?.isTotal}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(variances?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(variances?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: tableHeadings?.delivery,
            key: 'delivery',
            dataIndex: 'delivery',
            render: (delivery, record) => (
                <Text
                    strong={record?.isTotal}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(delivery?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(delivery?.percentvalue)} %
                </Text>
            ),
        },
    ];
};

export const getEntityContributionComponentVarianceColumn = (tableHeadings, compareTo) => {
    if (!tableHeadings) return;

    return [
        {
            title: tableHeadings?.components,
            dataIndex: 'components',
            key: 'components',
            width: '25%',
            render: (names, record) =>
                record.isTotal === true ? <Text strong={record?.isTotal}>{names}</Text> : <Text>{names}</Text>,
        },
        {
            title: tableHeadings?.grossSales,
            dataIndex: 'grossSales',
            key: 'grossSales',
            render: (grossSales, record) => (
                <Text
                    strong={record?.isTotal}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(grossSales?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(grossSales?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: tableHeadings?.discounts,
            colSpan: 1,
            key: 'discounts',
            dataIndex: 'discounts',
            render: (discounts, record) => (
                <Text
                    strong={record?.isTotal}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(discounts?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(discounts?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: tableHeadings?.cogs,
            key: 'cogs',
            dataIndex: 'cogs',
            render: (cogs, record) => (
                <Text
                    strong={record?.isTotal}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(cogs?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(cogs?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: tableHeadings?.ppv,
            key: 'ppv',
            dataIndex: 'ppv',
            render: (ppv, record) => (
                <Text
                    strong={record?.isTotal}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(ppv?.percentvalue) ? 'green' : 'red'}`
                    }>
                    { ppv?.percentvalue ? `${formatNumbers(ppv?.percentvalue)} %` : '0 %'}
                    {record.isChild && ' %'}
                </Text>
            ),
        },
        {
            title: tableHeadings?.variances,
            key: 'variances',
            dataIndex: 'variances',
            render: (variances, record) => (
                <Text
                    strong={record?.isTotal}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(variances?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(variances?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: tableHeadings?.delivery,
            key: 'delivery',
            dataIndex: 'delivery',
            render: (delivery, record) => (
                <Text
                    strong={record?.isTotal}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(delivery?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(delivery?.percentvalue)} %
                </Text>
            ),
        },
    ];
};

export const getCustomerTypeContributionComponentVarianceColumn = (tableHeadings, compareTo) => {
    if (!tableHeadings) return;

    return [
        {
            title: tableHeadings?.components,
            dataIndex: 'components',
            key: 'components',
            width: '25%',
            render: (names, record) =>
                record.isTotal === true ? <Text strong={record?.isTotal}>{names}</Text> : <Text>{names}</Text>,
        },
        {
            title: tableHeadings?.grossSales,
            dataIndex: 'grossSales',
            key: 'grossSales',
            render: (grossSales, record) => (
                <Text
                    strong={record?.isTotal}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(grossSales?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(grossSales?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: tableHeadings?.discounts,
            colSpan: 1,
            key: 'discounts',
            dataIndex: 'discounts',
            render: (discounts, record) => (
                <Text
                    strong={record?.isTotal}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(discounts?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(discounts?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: tableHeadings?.cogs,
            key: 'cogs',
            dataIndex: 'cogs',
            render: (cogs, record) => (
                <Text
                    strong={record?.isTotal}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(cogs?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(cogs?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: tableHeadings?.ppv,
            key: 'ppv',
            dataIndex: 'ppv',
            render: (ppv, record) => (
                <Text
                    strong={record?.isTotal}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(ppv?.percentvalue) ? 'green' : 'red'}`
                    }>
                    { ppv?.percentvalue ? `${formatNumbers(ppv?.percentvalue)} %` : '0 %'}
                    {record.isChild && ' %'}
                </Text>
            ),
        },
        {
            title: tableHeadings?.variances,
            key: 'variances',
            dataIndex: 'variances',
            render: (variances, record) => (
                <Text
                    strong={record?.isTotal}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(variances?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(variances?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: tableHeadings?.delivery,
            key: 'delivery',
            dataIndex: 'delivery',
            render: (delivery, record) => (
                <Text
                    strong={record?.isTotal}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(delivery?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(delivery?.percentvalue)} %
                </Text>
            ),
        },
    ];
};

export const transformData = (salesEntityContributionData, tableIdentifier) => {
    return salesEntityContributionData.map(item => {
        const newItem = { ...item };

        newItem.children = [
            {
                id: tableIdentifier + item.id + 9,
                discounts: { value: item.discounts.percentvalue },
                cogs: { value: item.cogs.percentvalue },
                ppv: { value: item.ppv.percentvalue },
                variances: { value: item.variances.percentvalue },
                delivery: { value: item.delivery.percentvalue },
                isChild: true,
            },
        ];

        return newItem;
    });
};

export const updateComponentVarianceData = (data, salesEntityItemTotals = null) => {
    data = [
        ...data,
        {
            components: 'Total',
            grossSales: { percentvalue: salesEntityItemTotals?.netSalesTotal },
            discounts: { percentvalue: salesEntityItemTotals?.discountsTotal },
            cogs: { percentvalue: salesEntityItemTotals?.cogsTotal },
            ppv: { percentvalue: salesEntityItemTotals?.ppvTotal },
            variances: { percentvalue: salesEntityItemTotals?.variancesTotal },
            delivery: { percentvalue: salesEntityItemTotals?.deliveryTotal },
            isTotal: true,
        },
    ];
    return data;
};
