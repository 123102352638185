import axios from 'axios';
import { getCookie } from '../helpers/cookies';
import { baseUrl } from './path';
const instance = axios.create({
    baseURL: baseUrl,
});

instance.interceptors.request.use(
    function (request) {
        // Do something before request is sent
        const token = getCookie('token');
        if (token) {
            request.headers.logintoken = token;
        }
        return request;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    },
);

export default instance;
