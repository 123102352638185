import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCustomerType, getEntitiesOption, getGeographyOptions } from '../../helpers/formatFilterValues';
import axios from '../../service/axiosInstance';
import { Path } from '../../service/path';

export const getAllFilter = createAsyncThunk('filters/GetAllFilter', async (payload, thunkAPI) => {
    try {
        const [response1, response2, response3] = await Promise.all([
            axios.get(Path.filters),
            axios.get(Path.geoLocations),
            axios.get(Path.countries),
        ]);
        const filter = response1.data;
        const geoLocations = response2.data.geoLocations;
        const countries = response3.data.countries;
        const dataLastUpdated = response1?.data?.dataLastUpdated;
        const datePickerDates = response1?.data?.dates;

        return {
            geoGraphies: getEntitiesOption(filter.geographies),
            geoLocations: getGeographyOptions(geoLocations),
            countries,
            dataLastUpdated,
            datePickerDates,
        };
    } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
    }
});

export const getCustomerTypeData = createAsyncThunk('filters/getCustomerTypeData', async (payload, thunkAPI) => {
    try {
        const response = await axios.post(Path.customerType, payload);
        const data = await response.data;

        return getCustomerType(data?.customerTypes);
    } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
    }
});

export const getCustomerTypeOnclick = createAsyncThunk('filters/getCustomerTypeOnclick', async (payload, thunkAPI) => {
    try {
        const response = await axios.post(Path.customerType, payload);
        const data = await response.data;

        return getCustomerType(data?.customerTypes);
    } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
    }
});
