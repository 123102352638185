import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Card, Table, Popover, Row, Col } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Info } from '../icons';
import { useSelector } from 'react-redux';

import './tables.scss';

const Tables = props => {
    const { apiPayload } = useSelector(state => state.filter);
    const { compareTo } = apiPayload;
    const {
        columns,
        data,
        title,
        hasTotal = false,
        totalDataSet = {},
        type = '',
        bgColor,
        rightSideContent = {},
    } = props;

    const [state, setState] = useState({
        primaryContent: {},
        secondaryContent: {},
        extendedContent: '',
        rightSideContent: {},
    });

    const memoizedState = useMemo(() => state, [state]);

    const updateState = useCallback(newState => {
        setState(prevState => ({
            ...prevState,
            ...newState,
        }));
    }, []);

    useEffect(() => {
        if (
            rightSideContent?.none &&
            Object.keys(rightSideContent.none).length !== 0 &&
            rightSideContent?.previous &&
            Object.keys(rightSideContent.previous).length !== 0
        ) {
            if (compareTo !== 'none') {
                updateState({
                    primaryContent: {
                        text: rightSideContent.previous.legend1.text || '',
                        color: rightSideContent.previous.legend1.color || 'none',
                    },
                    secondaryContent: {
                        text: rightSideContent.previous.legend2.text || '',
                        color: rightSideContent.previous.legend2.color || 'none',
                    },
                    extendedContent: rightSideContent.previous.tooltip || '',
                });
            } else {
                updateState({
                    primaryContent: {
                        text: rightSideContent.none.legend1.text || '',
                        color: rightSideContent.none.legend1.color || 'none',
                    },
                    secondaryContent: {
                        text: rightSideContent.none.legend2.text || '',
                        color: rightSideContent.none.legend2.color || 'none',
                    },
                    extendedContent: rightSideContent.none.tooltip || '',
                });
            }
        }
    }, [rightSideContent, compareTo, updateState]);

    const DisplayTitleDynamically = () => {
        let mainTitle = memoizedState.extendedContent ? 9 : 10;
        let primaryTitle = 7;
        let secondaryTitle = 7;
        let tooltip = !memoizedState.extendedContent ? 0 : 1;

        if (!memoizedState?.primaryContent?.text && !memoizedState?.secondaryContent?.text) {
            mainTitle = memoizedState.extendedContent ? 23 : 24;
            primaryTitle = 0;
            secondaryTitle = 0;
            tooltip = !memoizedState.extendedContent ? 0 : tooltip;
        } else if (!memoizedState?.primaryContent?.text && memoizedState?.secondaryContent?.text) {
            mainTitle = memoizedState.extendedContent ? 12 : 13;
            primaryTitle = 0;
            secondaryTitle = 11;
            tooltip = !memoizedState.extendedContent ? 0 : tooltip;
        } else if (!memoizedState?.secondaryContent?.text && memoizedState?.primaryContent?.text) {
            mainTitle = memoizedState.extendedContent ? 12 : 23;
            primaryTitle = 11;
            secondaryTitle = 0;
            tooltip = !memoizedState.extendedContent ? 0 : tooltip;
        }

        return (
            <Row>
                <Col span={mainTitle}>
                    <span className="jv-table__main-heading">{title}</span>
                </Col>
                {memoizedState.primaryContent.text && (
                    <Col span={primaryTitle} style={{ textAlign: 'right' }}>
                        <span
                            className={`jv-table__right-info-text jv-table__right-info-text-${memoizedState.primaryContent.color}`}>
                            {memoizedState.primaryContent.text}
                        </span>
                    </Col>
                )}
                {memoizedState.secondaryContent.text && (
                    <Col span={secondaryTitle} style={{ textAlign: 'right' }}>
                        <span
                            className={`jv-table__right-info-text jv-table__right-info-text-${memoizedState.secondaryContent.color}`}>
                            {memoizedState.secondaryContent.text}
                        </span>
                    </Col>
                )}
                {memoizedState.extendedContent && (
                    <Col span={tooltip} style={{ textAlign: 'right' }}>
                        <Popover placement="rightBottom" content={memoizedState.extendedContent}>
                            <span className="jv-table__info-icon">
                                <Info style={{ cursor: 'pointer' }} />
                            </span>
                        </Popover>
                    </Col>
                )}
            </Row>
        );
    };

    return (
        <Card bordered={false} className={`criclebox tablespace mb-24 ${bgColor} `} title={<DisplayTitleDynamically />}>
            <div className="table-responsive">
                <Table
                    columns={columns}
                    dataSource={hasTotal ? getTotalData(data, type, totalDataSet) : data}
                    rowKey={data => data.id}
                    pagination={false}
                    className="ant-border-space jv-table"
                    expandable={{ defaultExpandAllRows: true }}
                />
            </div>
        </Card>
    );
};

const getTotalData = (data, type, totalDataSet) => {
    if (['regionalContribution', 'entityContribution', 'customerTypeContribution'].includes(type)) {
        const totalData = {
            id: data.length + 1,
            components: 'Total',
            grossSales: {
                value: totalDataSet?.netSalesTotal,
                percentalue: null,
            },
            discounts: {
                value: totalDataSet?.discountsTotal,
                percentvalue: null,
            },
            cogs: {
                value: totalDataSet?.cogsTotal,
                percentvalue: null,
            },
            ppv: {
                value: totalDataSet?.ppvTotal,
                percentvalue: null,
            },
            variances: {
                value: totalDataSet?.variancesTotal,
                percentvalue: null,
            },
            delivery: {
                value: totalDataSet?.deliveryTotal,
                percentvalue: null,
            },
        };

        totalData.children = [
            {
                id: data.length + 1 + '-child',
                discounts: { value: totalDataSet?.discountsTotalPercent },
                cogs: { value: totalDataSet?.cogsTotalPercent },
                ppv: { value: totalDataSet?.ppvTotalPercent },
                variances: { value: totalDataSet?.variancesTotalPercent },
                delivery: { value: totalDataSet?.deliveryTotalPercent },
                isChild: true,
                isChildTotal: true,
            },
        ];

        data = [...data, totalData];
    } else if (['key-metrics'].includes(type)) {
        const totalData = {
            id: data.length + 1,
            name: 'Total',
            cogs: {
                value: totalDataSet?.totalCostofSales,
                percentvalue: null,
            },
            grossProfitPercentage: {
                value: totalDataSet?.totalGrossProfit,
                percentalue: null,
            },
            grossSales: {
                value: totalDataSet?.totalNetSales,
                percentalue: null,
            },
            volume: {
                value: totalDataSet?.totalVolume,
                percentvalue: null,
            },
        };

        data = [...data, totalData];
    } else {
        let totalgRvsPO = 0;
        let totaliRvsPO = 0;
        let totallYvsCYGR = 0;
        let totallYvsCYIR = 0;

        data.forEach(({ gRvsPO, iRvsPO, lYvsCYGR, lYvsCYIR }) => {
            totalgRvsPO += Number(gRvsPO?.value);
            totaliRvsPO += Number(iRvsPO?.value);
            totallYvsCYGR += Number(lYvsCYGR?.value);
            totallYvsCYIR += Number(lYvsCYIR?.value);
        });

        data = [
            ...data,
            {
                components: 'Total',
                gRvsPO: { value: totalgRvsPO.toFixed(2) },
                iRvsPO: { value: totaliRvsPO.toFixed(2) },
                lYvsCYGR: { value: totallYvsCYGR.toFixed(2) },
                lYvsCYIR: { value: totallYvsCYIR.toFixed(2) },
            },
        ];
    }

    return data;
};

export default Tables;
