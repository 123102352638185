import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../service/axiosInstance';
import { salesAnalysis } from '../../service/path';

export const getSalesMetrics = createAsyncThunk('salesAnalysis/getSalesMetrics', async (payload, thunkAPI) => {
    try {
        const resp = await axios.post(salesAnalysis.metrics, payload);
        return resp.data.salesAnalysisTiles;
    } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
    }
});

export const getLinegraph = createAsyncThunk('salesAnalysis/getLinegraph', async (payload, thunkAPI) => {
    try {
        const [current, previous] = await Promise.all([
            axios.post(salesAnalysis.linegraph, payload.payload),
            axios.post(salesAnalysis.linegraph, payload.previous),
        ]);

        return {
            current: current.data,
            previous: previous.data,
        };
    } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
    }
});

export const getRegionalContribution = createAsyncThunk(
    'salesAnalysis/getRegionalContribution',
    async (payload, thunkAPI) => {
        try {
            const resp = await axios.post(salesAnalysis.regionContribution, payload);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    },
);

export const getEntityContribution = createAsyncThunk(
    'salesAnalysis/getEntityContribution',
    async (payload, thunkAPI) => {
        try {
            const resp = await axios.post(salesAnalysis.entityContribution, payload);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    },
);

export const getCustomerTypeContribution = createAsyncThunk(
    'salesAnalysis/getCustomerTypeContribution',
    async (payload, thunkAPI) => {
        try {
            const resp = await axios.post(salesAnalysis.customerTypeContribution, payload);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    },
);

export const getRegionalContributionComponentVariance = createAsyncThunk(
    'salesAnalysis/getRegionalContributionComponentVariance',
    async (payload, thunkAPI) => {
        try {
            const resp = await axios.post(salesAnalysis.regionContributionComponentVariance, payload);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    },
);

export const getEntityContributionComponentVariance = createAsyncThunk(
    'salesAnalysis/getEntityContributionComponentVariance',
    async (payload, thunkAPI) => {
        try {
            const resp = await axios.post(salesAnalysis.entityContributionComponentVariance, payload);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    },
);

export const getCustomerTypeContributionComponentVariance = createAsyncThunk(
    'salesAnalysis/getCustomerTypeContributionComponentVariance',
    async (payload, thunkAPI) => {
        try {
            const resp = await axios.post(salesAnalysis.customerTypeContributionComponentVariance, payload);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    },
);
