import React from 'react';
import { Col, Row, Skeleton, Divider, Card, Typography } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Tables from '../../components/Tables';
import MetricsCards from '../../components/metricCards';
import { useSelector } from 'react-redux';
import {
    getForexImpactAnalysisColumn,
    getCurrencyPairImpactColumn,
    getTopImpactItemsData,
    getTopImpactItemsGains,
} from '../../helpers/forexImpact';
import '../../helpers/override.scss';
import useForexImpact from './useForexImpact';
const { Title } = Typography;

const ForexImpact = () => {
    const { metrics, analysis, currencyPairImpact, topImpactItems, linegraph, topImpactGains, compareTo } =
        useForexImpact();
    const { isLoading: metricsLoading, data: metricsData } = metrics;
    const { isLoading: analysisLoading, data: analysisData } = analysis;
    const { isLoading: currencyPairImpactLoading, data: currencyPairImpactData } = currencyPairImpact;
    const { isLoading: topImpactItemsLoading, data: topImpactItemsData } = topImpactItems;
    const { isLoading: topImpactGainsDataLoading, data: topImpactGainsData } = topImpactGains;

    const { overviews, updatedChartSettings, linegraphLoading } = linegraph;
    const chartTitle = overviews?.[0]?.title;

    return (
        <div className="main-container">
            {metricsLoading ? (
                <Skeleton />
            ) : (
                <MetricsCards
                    showMillion={false}
                    showDollar={true}
                    data={metricsData?.forexImpactMatricsTiles}
                    isMillion={false}
                    forexDollar={true}
                />
            )}
            <div className="jv-table">
                <Row gutter={[16, 24]}>
                    <Col span={24}>
                        {!linegraphLoading ? (
                            updatedChartSettings?.series?.[0]?.data ? (
                                <Card bordered={false} className="criclebox h-full" title={<Title>{chartTitle}</Title>}>
                                    <HighchartsReact highcharts={Highcharts} options={updatedChartSettings} />
                                </Card>
                            ) : (
                                <>
                                    <Card bordered={false} className="criclebox h-full" title={chartTitle}>
                                        No data available for chart
                                    </Card>
                                </>
                            )
                        ) : (
                            <Skeleton />
                        )}
                    </Col>
                </Row>
                <Divider />
                <Row gutter={[16, 24]}>
                    <Col span={24}>
                        {analysisLoading ? (
                            <Skeleton />
                        ) : (
                            <Tables
                                title={analysisData?.forexAnalysisData?.title || ''}
                                columns={getForexImpactAnalysisColumn(
                                    analysisData?.forexAnalysisData?.forexAnalysisTableHeadings,
                                    compareTo,
                                )}
                                data={analysisData?.forexAnalysisData?.forexAnalysisData || []}
                                hasTotal={true}
                                rightSideContent={analysisData?.forexAnalysisData?.info}
                            />
                        )}
                    </Col>
                    <Col span={24}>
                        {currencyPairImpactLoading ? (
                            <Skeleton />
                        ) : (
                            <Tables
                                title={currencyPairImpactData?.forexAnalysisData?.title || ''}
                                columns={getCurrencyPairImpactColumn(
                                    currencyPairImpactData?.forexAnalysisData?.forexAnalysisTableHeadings,
                                    compareTo,
                                )}
                                data={currencyPairImpactData?.forexAnalysisData?.forexAnalysisData || []}
                                hasTotal={true}
                                rightSideContent={currencyPairImpactData?.forexAnalysisData?.info}
                            />
                        )}
                    </Col>
                    <Col span={24}>
                        {topImpactItemsLoading ? (
                            <Skeleton />
                        ) : (
                            <Tables
                                title={topImpactItemsData?.forexAnalysisData?.title || ''}
                                columns={getTopImpactItemsData(
                                    topImpactItemsData?.forexAnalysisData?.forexAnalysisTableHeadings,
                                    compareTo,
                                )}
                                data={topImpactItemsData?.forexAnalysisData?.forexAnalysisData || []}
                                rightSideContent={topImpactItemsData?.forexAnalysisData?.info}
                            />
                        )}
                    </Col>
                    <Col span={24}>
                        {topImpactGainsDataLoading ? (
                            <Skeleton />
                        ) : (
                            <Tables
                                title={topImpactGainsData?.title || ''}
                                columns={getTopImpactItemsGains(
                                    topImpactGainsData?.forexAnalysisTableHeadings,
                                    compareTo,
                                )}
                                data={topImpactGainsData?.forexAnalysisData || []}
                                rightSideContent={topImpactGainsData?.info}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ForexImpact;
