import React from 'react';

const ArrowIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="13" fill="none" viewBox="0 0 20 13">
            <path stroke="#A20C33" strokeLinecap="round" strokeWidth="3" d="M2 11.5l8-9 8 9"></path>
        </svg>
    );
};

export default ArrowIcon;
