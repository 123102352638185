import { LoadingOutlined } from '@ant-design/icons';
import { Drawer, Layout, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { getAllFilter } from '../../features/filters/filtersApi';
import Footer from '../Footer';
import useQuerySetter from '../../hooks/useQuerySetter';
import Header from '../Header/Header';
import Sidenav from '../Sidebar/Sidenav';
import Breadcrumb from '../breadcrumb';
import './layout.scss';

const { Header: AntHeader, Content, Sider } = Layout;

function Main() {
    const dispatch = useDispatch();
    const { isLoading } = useSelector(state => state.filter);
    const [visible, setVisible] = useState(false);
    const [sidenavColor, setSidenavColor] = useState('#1890ff');
    const [sidenavType, setSidenavType] = useState('transparent');
    const [fixed, setFixed] = useState(false);
    const openDrawer = () => setVisible(!visible);
    const handleSidenavType = type => setSidenavType(type);
    const handleSidenavColor = color => setSidenavColor(color);
    const handleFixedNavbar = type => setFixed(type);
    useQuerySetter()
    const { pathname } = useLocation();

    useEffect(() => {
        dispatch(getAllFilter());
    }, [dispatch]);

    if (isLoading) {
        return <Spin className="main_loading" indicator={<LoadingOutlined spin />} />;
    }
    return (
        <Layout className="layout-dashboard " style={{ position: 'relative' }}>
            <Drawer
                title={false}
                placement="left"
                closable={false}
                onClose={() => setVisible(false)}
                open={visible}
                key="left"
                width={250}
                className="drawer-sidebar"
            >
                <Layout className="layout-dashboard">
                    <Sider
                        trigger={null}
                        width={250}
                        theme="light"
                        className={`sider-primary ant-layout-sider-primary ${
                            sidenavType === '#fff' ? 'active-route' : ''
                        }`}
                        style={{ background: sidenavType }}
                    >
                        <Sidenav color={sidenavColor} />
                    </Sider>
                </Layout>
            </Drawer>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                trigger={null}
                width={250}
                theme="light"
                className={`sider-primary ant-layout-sider-primary ${sidenavType === '#fff' ? 'active-route' : ''}`}
                style={{ background: sidenavType }}
            >
                <Sidenav color={sidenavColor} />
            </Sider>
            <Layout>
                <Header
                    onPress={openDrawer}
                    name={pathname}
                    subName={pathname}
                    handleSidenavColor={handleSidenavColor}
                    handleSidenavType={handleSidenavType}
                    handleFixedNavbar={handleFixedNavbar}
                />
                <Breadcrumb />
                <Content className="content-ant">
                    <Outlet />
                </Content>
                <Footer />
            </Layout>
        </Layout>
    );
}

export default Main;
