
import {getPath} from '../helpers/settings'
export const baseUrl = getPath();

export const summary = {
    regionalSummaryComplete: '/summary/regionalsummary-complete',
    entitySummaryComplete: '/summary/entitysummary-complete',
};

const keyMetrics = {
    metrics: '/key-metrics/metrics',
    salesTrending: '/key-metrics/sales-trending',
    volumeTrending: '/key-metrics/volume-trending',
    businessShare: '/key-metrics/businessshare',
    contribution: 'key-metrics/geographycontribution',
    businessType: '/key-metrics/business-type',
    entitycontribution: '/key-metrics/entitycontribution',
    regionalContributionComponentVariance: '/key-metrics/regionalContributionComponentVariance',
    entityContributionComponentVariance: '/key-metrics/entityContributionComponentVariance',
    customergroupComponentVariance: '/key-metrics/customergroupComponentVariance',
};

export const salesAnalysis = {
    metrics: '/sales-analysis/metrics',
    linegraph: '/sales-analysis/lineGraph',
    regionContribution: 'sales-analysis/regionalContribution',
    entityContribution: '/sales-analysis/entityContribution',
    customerTypeContribution: '/sales-analysis/customerTypeContribution',
    regionContributionComponentVariance: 'sales-analysis/regionalContributionComponentVariance',
    entityContributionComponentVariance: '/sales-analysis/entityContributionComponentVariance',
    customerTypeContributionComponentVariance: '/sales-analysis/customerGroupContributionComponentVariance',
};

export const forexImpact = {
    metrics: '/forex-impact/metrics',
    forexforexMonthlyAnalysis: '/forex-impact/forexMonthlyAnalysis',
    topImpactItems: '/forex-impact/topImpactItems',
    currencyPairImpact: '/forex-impact/currencyPairImpact',
    monthlyLineGraph: '/forex-impact/monthlyLineGraph',
    topImpactItems_gain: '/forex-impact/topImpactItems_gain'
};

export const profitAnalysis = {
    metrics: '/profit-center-analysis/metrics',
    profitabilityTracker: '/profit-center-analysis/profitability-tracker',
};

export const operationAnalysis = {
    metrics: '/operation-analysis/metrics',
    importDelivery: '/operation-analysis/import-delivery',
    exportDelivery: '/operation-analysis/export-delivery',
};

export const transportRate = {
    metrics: '/transportation-rate-variance/metrics',
    transportImpact: '/transportation-rate-variance/transportation-impact',
};

const filters = {
    countries: 'masterlists/countries',
    geoLocations: 'masterlists/geolocations',
    filters: 'masterlists/filters',
    entities: 'masterlists/entities',
    customerType: 'masterlists/filteredCustomerTypes'
};

export const Path = {
    login: '/user/login/authenticate',
    ...keyMetrics,
    ...filters,
};
