import React from 'react';

const MailIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="15" fill="none" viewBox="0 0 25 15">
            <path
                stroke="#fff"
                strokeWidth="1.5"
                d="M.75 9.967V1.304L7.63 5.31.75 9.967zm0 1.811l8.319-5.631 2.4 1.397a2.75 2.75 0 002.831-.038l2.154-1.334 7.796 6.01V13c0 .69-.56 1.25-1.25 1.25H2c-.69 0-1.25-.56-1.25-1.25v-1.222zm23.5-1.49l-6.433-4.96 6.433-3.982v8.941zM2.78.75h19.584l-6.258 3.873-2.595 1.607a1.25 1.25 0 01-1.287.018L9.416 4.613 2.779.75z"
                opacity="0.8"
            ></path>
        </svg>
    );
};

export default MailIcon;
