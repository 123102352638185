import { Routes, Route } from 'react-router-dom';
import { pathName } from './constants/routes';
import Main from './components/layout/Main';
import Login from './pages/login';
import Summary from './pages/summary';
import KeyMetrics from './pages/key-metrics';
import SalesAnalysis from './pages/sales-analysis';
import ProfitCenterAnalysis from './pages/profit-center-analysis';
import OperationAnalysis from './pages/operation-analysis';
import ForexImpact from './pages/forex-impact';
import TransportationRate from './pages/transportationRate';
import Glossary from './pages/glossary';
import Error from './pages/Error';
import PrivateRoute from './components/private-route';

function App() {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Error />} />
            <Route element={<PrivateRoute />}>
                <Route element={<Main />}>
                    <Route path={pathName.summary} element={<Summary />} />
                    <Route path={pathName.keyMetrics} element={<KeyMetrics />} />
                    <Route path={pathName.salesAnalysis} element={<SalesAnalysis />} />
                    <Route path={pathName.profitCenterAnalysis} element={<ProfitCenterAnalysis />} />
                    <Route path={pathName.operationAnalysis} element={<OperationAnalysis />} />
                    <Route path={pathName.forexImpact} element={<ForexImpact />} />
                    <Route path={pathName.glossary} element={<Glossary />} />
                    <Route path={pathName.transportationRate} element={<TransportationRate />} />
                </Route>
            </Route>
        </Routes>
    );
}

export default App;
