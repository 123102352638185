import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useCallback, useEffect, useState } from 'react';
import { spline } from '../../helpers/charts';
import { formatNumbers } from '../../helpers/common';
const Chart = ({
    data = [],
    title = '',
    showMillion = true,
    forexDollar = false,
    hasSizeInPercent = false,
    showDollar = false,
}) => {
    const [splineData, setSplineData] = useState(spline);
    const getTooltipFormatter = useCallback(() => {
        return function () {
            return `<b>${forexDollar && showDollar && !hasSizeInPercent ? '$ ' : ''}
            ${hasSizeInPercent && title === 'Total COGS' ? '$ ' : ''}
            ${showMillion ? formatNumbers(this.y, true, title) : formatNumbers(this.y, false)} 
            ${hasSizeInPercent && title === 'Total COGS' ? `(` + this.point.name + ` %)` : ''}
            ${hasSizeInPercent && title === 'Gross Profit' ? `% ($` + formatNumbers(this.point.name) + `)` : ''}</b>`;
        };
    }, [title]);

    useEffect(() => {
        if (!data && !title) return;
        let newSpline = { ...splineData };
        newSpline.series = [
            {
                name: '',
                data,
                color: 'rgba(158, 11, 49, 1)',
                shadow: {
                    color: 'rgba(158, 11, 49, 1)',
                    offsetY: 2,
                    opacity: 0.3,
                    width: 3,
                },
            },
        ];
        // We are updating using deep copy because it overwrites
        newSpline = {
            ...newSpline,
            tooltip: {
                ...newSpline.tooltip,
                formatter: getTooltipFormatter(),
            },
        };
        setSplineData(newSpline);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, title]);

    return <>{splineData?.series?.[0]?.data && <HighchartsReact highcharts={Highcharts} options={splineData} />}</>;
};

export default Chart;
