import React from 'react';

const KeyMetrics = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
            <path
                fill="#6A6A6A"
                d="M14 0h6s2 0 2 2v9s0 2-2 2h-6s-2 0-2-2V2s0-2 2-2M2 0h6s2 0 2 2v4s0 2-2 2H2S0 8 0 6V2s0-2 2-2M2 9h6s2 0 2 2v9s0 2-2 2H2s-2 0-2-2v-9s0-2 2-2M14 14h6s2 0 2 2v4s0 2-2 2h-6s-2 0-2-2v-4s0-2 2-2"
            ></path>
        </svg>
    );
};

export default KeyMetrics;
