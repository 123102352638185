import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../service/axiosInstance';
import { forexImpact } from '../../service/path';

export const getForexImpactmetrics = createAsyncThunk(
    'forexImpact/getForexImpactmetrics',
    async (payload, thunkAPI) => {
        try {
            const resp = await axios.post(forexImpact.metrics, payload);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    },
);

export const getforexforexMonthlyAnalysis = createAsyncThunk(
    'forexImpact/getforexforexMonthlyAnalysis',
    async (payload, thunkAPI) => {
        try {
            const resp = await axios.post(forexImpact.forexforexMonthlyAnalysis, payload);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    },
);

export const getTopImpactItems = createAsyncThunk(
    'forexImpact/getTopImpactItems',
    async (payload, thunkAPI) => {
        try {
            const resp = await axios.post(forexImpact.topImpactItems, payload);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    },
);

export const getCurrencyPairImpact = createAsyncThunk(
    'forexImpact/getCurrencyPairImpact',
    async (payload, thunkAPI) => {
        try {
            const resp = await axios.post(forexImpact.currencyPairImpact, payload);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    },
);
export const getForexLineGraph = createAsyncThunk(
    'forexImpact/getLineGraph',
    async (payload, thunkAPI) => {
        try {
            const resp = await axios.post(forexImpact.monthlyLineGraph, payload);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    },
);

export const getTopImpactItemsGain = createAsyncThunk(
    'forexImpact/getTopImpactItemsGain',
    async (payload, thunkAPI) => {
        try {
            const resp = await axios.post(forexImpact.topImpactItems_gain, payload);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    },
);
