import { useState, useEffect, useLayoutEffect } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Row, Col, Dropdown, Drawer, Typography, Switch, Affix } from 'antd';
import SelectedFilter from '../selectedFilters';
import useQuerySetter from '../../hooks/useQuerySetter';

import Icon from '../../assets/icon';
import { useAuth } from '../../context/authContext';
import './navbar.scss';

const { Title, Text } = Typography;

function Header({ placement, onPress, handleFixedNavbar }) {
    const globalAny = global;
    const { logout, getName } = useAuth();
    const [ screenWidth, setScreenWidth ] = useState( globalAny?.window?.screen?.availWidth );
    const [ visible, setVisible ] = useState(false);
    const { filterData: { dataLastUpdated } } = useQuerySetter();

    const items = [
        {
            key: 'overview',
            icon: <Icon className="icn" icon="avtar" />,
            label: (
                <div className="jv-navbar__name">
                    { getName() }
                </div>
            ),
        },
        {
            type: 'divider',
        },
        {
            key: 'profile',
            label: 'Profile',
        },
        {
            key: 'logout',
            label: 'Log Out',
        },
    ];

    useEffect(() => window.scrollTo(0, 0));

    const hideDrawer = () => setVisible(false);

    const handleDropdownItemClick = e => {
        if (e.key === 'logout') {
            logout();
        }
    };

    useLayoutEffect( () => {
        function handleResize() {
            setScreenWidth( globalAny?.window?.screen?.availWidth );
        }

        window.addEventListener( 'resize', handleResize );
    
        return () => {
            window.removeEventListener( 'resize', handleResize );
        };
    }, [ globalAny?.window?.screen?.availWidth ] );

    return (
        <>
            <Affix offsetTop={10}>
                <div className="jv-navbar">
                    <Row>
                        <Col span={ 24 }>
                            <Row gutter={[16, 16]}>
                                <Col xs={20} sm={20} md={18} lg={12} span={24} align="left">
                                    {
                                        screenWidth < 992 && (
                                            <MenuOutlined className="jv-navbar__sidebar-toggle" onClick={() => onPress()} />
                                        )
                                    }
                                    <span className="jv-navbar__last-updated">
                                        {
                                            `${ dataLastUpdated?.label }: ${ dataLastUpdated?.lastUpdated }`
                                        }
                                    </span>
                                </Col>
                                <Col xs={4} sm={4} md={6} lg={12} span={24} align="right">
                                    <div className="jv-navbar__right">
                                        <div className="jv-navbar__right-profile">
                                            <Dropdown
                                                overlayClassName="jv-navbar__dropdown"
                                                menu={{ items, onClick: handleDropdownItemClick }}
                                            >
                                                <div className="jv-navbar__user-icon">
                                                    <Icon className="icn" icon="avtar" />
                                                </div>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Drawer
                                className="settings-drawer"
                                mask={true}
                                width={360}
                                onClose={hideDrawer}
                                placement={placement}
                                open={visible}
                            >
                                <div>
                                    <div className="header-top">
                                        <Title level={4}>
                                            Configurator
                                            <Text className="subtitle">See our dashboard options.</Text>
                                        </Title>
                                    </div>

                                    <div className="sidebar-color">
                                        <Title level={5}>Sidebar Color</Title>
                                        <div className="theme-color mb-2"></div>

                                        <div className="sidebarnav-color mb-2">
                                            <Title level={5}>Sidenav Type</Title>
                                            <Text>Choose between 2 different sidenav types.</Text>
                                        </div>
                                        <div className="fixed-nav mb-2">
                                            <Title level={5}>Navbar Fixed </Title>
                                            <Switch onChange={e => handleFixedNavbar(e)} />
                                        </div>
                                    </div>
                                </div>
                            </Drawer>
                        </Col>
                    </Row>
                </div>
            </Affix>
            <SelectedFilter />
        </>
    );
}

export default Header;
