import { createSlice } from '@reduxjs/toolkit';
import { getProfitAnalysis, getProfitabilityTracker } from './profitAnanlysisApi';

const initialState = {
    metrics: {
        isLoading: true,
        isError: false,
        data: [],
    },
    profitabilityTracker: {
        isLoading: true,
        isError: false,
        data: [],
    },
};

const profitAnalysisSlice = createSlice({
    name: 'profitAnalysis',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getProfitAnalysis.pending, state => {
                state.metrics.isLoading = true;
            })
            .addCase(getProfitAnalysis.fulfilled, (state, action) => {
                state.metrics.isLoading = false;
                state.metrics.data = action.payload;
            })
            .addCase(getProfitAnalysis.rejected, (state, action) => {
                state.metrics.isLoading = false;
            })
            .addCase(getProfitabilityTracker.pending, state => {
                state.profitabilityTracker.isLoading = true;
            })
            .addCase(getProfitabilityTracker.fulfilled, (state, action) => {
                state.profitabilityTracker.isLoading = false;
                state.profitabilityTracker.data = action.payload;
            })
            .addCase(getProfitabilityTracker.rejected, (state, action) => {
                state.profitabilityTracker.isLoading = false;
            });
    },
});

export default profitAnalysisSlice.reducer;
