/**
 * Get the value of a cookie by its name.
 *
 * @param {string} cname - The name of the cookie to retrieve.
 * @returns {string} The value of the cookie or an empty string if the cookie is not found.
 */
export const getCookie = cname => {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};
