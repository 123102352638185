import { ArrowDownOutlined, ArrowUpOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Typography, Popover } from 'antd';
import { formatNumbers, isPositive } from './common';
import { Info } from '../components/icons';
const { Text } = Typography;
const status = {
    true: <ArrowUpOutlined style={{ color: 'green' }} />,
    false: <ArrowDownOutlined style={{ color: 'red' }} />,
};

/**
 * An object representing table headings.
 * @typedef {Object} GeoContributionHeading
 * @property {string} volume - The heading for the volume column.
 * @property {string} revenue - The heading for the revenue column.
 * @property {string} margin - The heading for the margin column.
 */

/**
 * Generates an array of table column definitions for a geo-contribution table.
 *
 * @type {GetGeoContributionHeading} - An object containing table headings.
 *
 * @returns {Array} An array of column definitions for the geo-contribution table.
 */
export const getGeoContributionCOlumn = (tableHeadings, compareTo, info) => {
    if (!tableHeadings) return;

    return [
        {
            title: tableHeadings?.component,
            dataIndex: 'name',
            key: 'name',
            width: '32%',
            render: (name, record) =>
                (record.components === 'Total' || record.name === 'Total') ? <Text strong>{name}</Text> : <Text>{name}</Text>,
        },
        {
            title: `${tableHeadings?.volume} (lbs)`,
            dataIndex: 'volume',
            key: 'volume',
            render: (volume, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(volume?.difference) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(volume?.value)}
                </Text>
            ),
        },
        {
            title: `${tableHeadings?.grossSales}`,
            key: 'grossSales',
            dataIndex: 'grossSales',
            render: (grossSales, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(grossSales?.difference) ? 'green' : 'red'}`
                    }>
                    ${formatNumbers(grossSales?.value)}
                </Text>
            ),
        },
        {
            title: index =>
                compareTo === 'previous period' ? (
                    <>
                        {`${tableHeadings?.cogs} `}
                        <Popover placement="rightBottom" content={info?.previous?.table_heading[0]?.item_text}>
                            <span>
                                <Info />
                            </span>
                        </Popover>
                    </>
                ) : (
                    `${tableHeadings?.cogs}`
                ),
            key: 'cogs',
            dataIndex: 'cogs',
            render: (cogs, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(cogs?.difference) ? 'green' : 'red'}`
                    }>
                    ${formatNumbers(cogs?.value)}
                </Text>
            ),
        },
        {
            title: tableHeadings?.grossProfitPercentage ?? '',
            key: 'grossProfitPercentage',
            dataIndex: 'grossProfitPercentage',
            render: (grossProfitPercentage, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(grossProfitPercentage?.difference) ? 'green' : 'red'}`
                    }>
                    ${formatNumbers(grossProfitPercentage?.value)}
                </Text>
            ),
        },
    ];
};

/**
 * An object representing table headings.
 * @typedef {Object} BusinessTypeColumn
 * @property {string} components - The heading for the components column.
 * @property {string} revenue - The heading for the revenue column.
 * @property {string} cogs - The heading for the cogs column.
 * @property {string} grossProfit - The heading for the grossProfit column.
 * @property {string} ebitda - The heading for the ebitda column.
 */

/**
 * Generates an array of table column definitions for a getBusinessTypeColumn table.
 *
 * @type {BusinessTypeColumn} - An object containing table headings.
 *
 * @returns {Array} An array of column definitions for the getBusinessTypeColumn table.
 */
export const getBusinessTypeColumn = (tableHeadings, compareTo, info) => {
    if (!tableHeadings) return;

    return [
        {
            title: tableHeadings?.components,
            dataIndex: 'name',
            key: 'name',
            width: '32%',
            render: (name, record) =>
                (record.components === 'Total' || record.name === 'Total' )? <Text strong>{name}</Text> : <Text>{name}</Text>,
        },
        {
            title: `${tableHeadings?.volume} (lbs)`,
            dataIndex: 'volume',
            key: 'volume',
            render: (volume, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(volume?.difference) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(volume?.value)}
                </Text>
            ),
        },
        {
            title: `${tableHeadings?.grossSales}` ?? '',
            key: 'grossSales',
            dataIndex: 'grossSales',
            render: (grossSales, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(grossSales?.difference) ? 'green' : 'red'}`
                    }>
                    ${formatNumbers(grossSales?.value)}
                </Text>
            ),
        },
        {
            title: index =>
                compareTo === 'previous period' ? (
                    <Text>
                        {`${tableHeadings?.cogs} `}
                        <Popover placement="rightBottom" content={info?.previous?.table_heading[0]?.item_text}>
                            <span>
                                <Info />
                            </span>
                        </Popover>
                    </Text>
                ) : (
                    `${tableHeadings?.cogs}`
                ),
            key: 'cogs',
            dataIndex: 'cogs',
            render: (cogs, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(cogs?.difference) ? 'green' : 'red'}`
                    }>
                    ${formatNumbers(cogs?.value)}
                </Text>
            ),
        },
        {
            title: tableHeadings?.grossProfitPercentage ?? '',
            key: 'grossProfitPercentage',
            dataIndex: 'grossProfitPercentage',
            render: (grossProfitPercentage, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(grossProfitPercentage?.difference) ? 'green' : 'red'}`
                    }>
                    ${formatNumbers(grossProfitPercentage?.value)}
                </Text>
            ),
        },
    ];
};

export const getEntityContributionColumn = (tableHeadings, compareTo, info) => {
    if (!tableHeadings) return;

    return [
        {
            title: 'Entity',
            dataIndex: 'name',
            key: 'name',
            width: '32%',
            render: (name, record) =>
                (record.components === 'Total' || record.name === 'Total') ? <Text strong>{name}</Text> : <Text>{name}</Text>,
        },
        {
            title: `${tableHeadings?.volume} (lbs)`,
            dataIndex: 'volume',
            key: 'volume',
            render: (volume, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(volume?.difference) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(volume?.value)}
                </Text>
            ),
        },
        {
            title: `${tableHeadings?.grosssales}` ?? '',
            key: 'grossSales',
            dataIndex: 'grossSales',
            render: (grossSales, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(grossSales?.difference) ? 'green' : 'red'}`
                    }>
                    ${formatNumbers(grossSales?.value)}
                </Text>
            ),
        },
        {
            title: index =>
                compareTo === 'previous period' ? (
                    <Text>
                        {`${tableHeadings?.cogs} `}
                        <Popover placement="rightBottom" content={info?.previous?.table_heading[0]?.item_text}>
                            <span>
                                <Info />
                            </span>
                        </Popover>
                    </Text>
                ) : (
                    `${tableHeadings?.cogs}`
                ),
            key: 'cogs',
            dataIndex: 'cogs',
            render: (cogs, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' && `compared ${isPositive(cogs?.difference) ? 'green' : 'red'}`
                    }>
                    ${formatNumbers(cogs?.value)}
                </Text>
            ),
        },
        {
            title: tableHeadings?.grossprofitpercentage ?? '',
            key: 'grossProfitPercentage',
            dataIndex: 'grossProfitPercentage',
            render: (grossProfitPercentage, record) => (
                <Text
                    strong={record.components === 'Total' || record.isChildTotal === true || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(grossProfitPercentage?.difference) ? 'green' : 'red'}`
                    }>
                    ${formatNumbers(grossProfitPercentage?.value)}
                </Text>
            ),
        },
    ];
};

export const getBusinessShareColumn = (tableHeadings, compareTo) => {
    if (!tableHeadings) return;

    return [
        {
            title: tableHeadings?.component,
            dataIndex: 'name',
            key: 'name',
            width: '32%',
        },
        {
            title: `${tableHeadings?.volume} (lbs)`,
            dataIndex: 'volume',
            key: 'volume',
            render: volume => <Text className="author-info">{formatNumbers(volume?.value)}</Text>,
        },
        {
            title: `${tableHeadings?.revenue}`,
            key: 'revenue',
            dataIndex: 'revenue',
            render: revenue => (
                <Text>
                    {compareTo === 'previous period' ? status[isPositive(revenue?.difference)] : ''} $
                    {formatNumbers(revenue?.value)}
                </Text>
            ),
        },
        {
            title: tableHeadings?.margin,
            key: 'margin',
            dataIndex: 'margin',
            render: margin => (
                <Text className={`semibold ${margin?.value >= 0 ? 'green' : 'red'}`}>{margin?.value}</Text>
            ),
        },
    ];
};

export const regionalContributionColumn = (tableHeadings, compareTo, info) => {
    if (!tableHeadings) return;

    return [
        {
            title: tableHeadings?.components,
            dataIndex: 'components',
            key: 'components',
            width: '32%',
            render: (names, record) =>
                (record.isTotal === true || record.name === 'Total') ? <Text strong={record?.isTotal}>{names}</Text> : <Text>{names}</Text>,
        },
        {
            title: tableHeadings?.volume,
            dataIndex: 'volumeValue',
            key: 'volumeValue',
            render: (volumeValue, record) => (
                <Text
                    strong={record?.isTotal || record.name === 'Total' || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(volumeValue?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(volumeValue?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: tableHeadings?.netSales,
            key: 'netSalesValue',
            dataIndex: 'netSalesValue',
            render: (netSalesValue, record) => (
                <Text
                    strong={record?.isTotal || record.name === 'Total' || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(netSalesValue?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(netSalesValue?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: index =>
                compareTo === 'previous period' ? (
                    <Text>
                        {`${tableHeadings?.costOfSales} `}
                        <Popover placement="rightBottom" content={info?.previous?.table_heading[0]?.item_text}>
                            <span>
                                <Info />
                            </span>
                        </Popover>
                    </Text>
                ) : (
                    `${tableHeadings?.costOfSales}`
                ),
            key: 'costOfSalesValue',
            dataIndex: 'costOfSalesValue',
            render: (costOfSalesValue, record) => (
                <Text
                    strong={record?.isTotal || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(costOfSalesValue?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(costOfSalesValue?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: tableHeadings?.grossProfit,
            key: 'grossProfitValue',
            dataIndex: 'grossProfitValue',
            render: (grossProfitValue, record) => (
                <Text
                    strong={record?.isTotal || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(grossProfitValue?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(grossProfitValue?.percentvalue)} %
                </Text>
            ),
        },
    ];
};

export const entityContributionComponentColum = (tableHeadings, compareTo, info) => {
    if (!tableHeadings) return;

    return [
        {
            title: tableHeadings?.components,
            dataIndex: 'components',
            key: 'components',
            width: '32%',
            render: (names, record) =>
                (record.isTotal === true || record.name === 'Total' ) ? <Text strong={record?.isTotal}>{names}</Text> : <Text>{names}</Text>,
        },
        {
            title: tableHeadings?.volume,
            dataIndex: 'volumeValue',
            key: 'volumeValue',
            render: (volumeValue, record) => (
                <Text
                    strong={record?.isTotal || record.name === 'Total' || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(volumeValue?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(volumeValue?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: tableHeadings?.netSales,
            key: 'netSalesValue',
            dataIndex: 'netSalesValue',
            render: (netSalesValue, record) => (
                <Text
                    strong={record?.isTotal || record.name === 'Total' || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(netSalesValue?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(netSalesValue?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: index =>
                compareTo === 'previous period' ? (
                    <Text>
                        {`${tableHeadings?.costOfSales} `}
                        <Popover placement="rightBottom" content={info?.previous?.table_heading[0]?.item_text}>
                            <span>
                                <Info />
                            </span>
                        </Popover>
                    </Text>
                ) : (
                    `${tableHeadings?.costOfSales}`
                ),
            key: 'costOfSalesValue',
            dataIndex: 'costOfSalesValue',
            render: (costOfSalesValue, record) => (
                <Text
                    strong={record?.isTotal || record.name === 'Total' || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(costOfSalesValue?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(costOfSalesValue?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: tableHeadings?.grossProfit,
            key: 'grossProfitValue',
            dataIndex: 'grossProfitValue',
            render: (grossProfitValue, record) => (
                <Text
                    strong={record?.isTotal || record.name === 'Total' || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(grossProfitValue?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(grossProfitValue?.percentvalue)} %
                </Text>
            ),
        },
    ];
};

export const customergroupComponentColumn = (tableHeadings, compareTo, info) => {
    if (!tableHeadings) return;

    return [
        {
            title: tableHeadings?.components,
            dataIndex: 'components',
            key: 'components',
            width: '32%',
            render: (names, record) =>
                ( record.isTotal === true || record.name === 'Total')? <Text strong={record?.isTotal}>{names}</Text> : <Text>{names}</Text>,
        },
        {
            title: tableHeadings?.volume,
            dataIndex: 'volumeValue',
            key: 'volumeValue',
            render: (volumeValue, record) => (
                <Text
                    strong={record?.isTotal || record.name === 'Total' || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(volumeValue?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(volumeValue?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: tableHeadings?.netSales,
            key: 'netSalesValue',
            dataIndex: 'netSalesValue',
            render: (netSalesValue, record) => (
                <Text
                    strong={record?.isTotal || record.name === 'Total' || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(netSalesValue?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(netSalesValue?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: index =>
                compareTo === 'previous period' ? (
                    <Text>
                        {`${tableHeadings?.costOfSales} `}
                        <Popover placement="rightBottom" content={info?.previous?.table_heading[0]?.item_text}>
                            <span>
                                <Info />
                            </span>
                        </Popover>
                    </Text>
                ) : (
                    `${tableHeadings?.costOfSales}`
                ),
            key: 'costOfSalesValue',
            dataIndex: 'costOfSalesValue',
            render: (costOfSalesValue, record) => (
                <Text
                    strong={record?.isTotal || record.name === 'Total' || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(costOfSalesValue?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(costOfSalesValue?.percentvalue)} %
                </Text>
            ),
        },
        {
            title: tableHeadings?.grossProfit,
            key: 'grossProfitValue',
            dataIndex: 'grossProfitValue',
            render: (grossProfitValue, record) => (
                <Text
                    strong={record?.isTotal || record.name === 'Total'}
                    className={
                        compareTo === 'previous period' &&
                        `compared ${isPositive(grossProfitValue?.percentvalue) ? 'green' : 'red'}`
                    }>
                    {formatNumbers(grossProfitValue?.percentvalue)} %
                </Text>
            ),
        },
    ];
};
