import { createSlice } from '@reduxjs/toolkit';
import { getRegionalSummaryComplete, getEntitySummaryComplete } from './summary';

const initialState = {
    regionalComplete: {
        isLoading: true,
        isError: false,
        data: {},
    },
    entityComplete: {
        isLoading: true,
        isError: false,
        data: {},
    },
};

const summaryisSlice = createSlice({
    name: 'summary',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getRegionalSummaryComplete.pending, state => {
                state.regionalComplete.isLoading = true;
            })
            .addCase(getRegionalSummaryComplete.fulfilled, (state, action) => {
                state.regionalComplete.isLoading = false;
                state.regionalComplete.data = action.payload;
            })
            .addCase(getRegionalSummaryComplete.rejected, (state, action) => {
                state.regionalComplete.isLoading = false;
            })
            .addCase(getEntitySummaryComplete.pending, state => {
                state.entityComplete.isLoading = true;
            })
            .addCase(getEntitySummaryComplete.fulfilled, (state, action) => {
                state.entityComplete.isLoading = false;
                state.entityComplete.data = action.payload;
            })
            .addCase(getEntitySummaryComplete.rejected, (state, action) => {
                state.entityComplete.isLoading = false;
            });
    },
});

export default summaryisSlice.reducer;
