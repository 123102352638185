export const hideEntireFilterSet = ['glossary', 'summary'];

export const defaultChartSettings = {
    chart: {
        styledMode: true,
        height: 350,
    },
    title: {
        text: null,
        align: '',
    },
    color: ['#9e0b31'],
    xAxis: {
        categories: [],
    },
    yAxis: {
        title: {
            text: '',
        },
        show: true,
    },
    credits: {
        enabled: false,
    },
    tooltip: {
        valueSuffix: '',
    },
    plotOptions: {
        line: {
            lineWidth: 2,
            marker: {
                enabled: false,
            },
        },
        series: {},
    },
    legend: {
        enabled: false,
    },
    series: [],
};

/**
 * Gets the API path based on the current hostname.
 *
 * @returns {string} The API path.
 */
export const getPath = () => {
    const hostname = window.location.host;

    if (hostname === 'jvilledashboard.com') {
        return 'https://api.jvilledashboard.com';
    }

    return 'https://api-jvdashboard.strat-staging.com';
};
