import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
    setAllFilter,
    setApiPayload,
    setCheckedEntity,
    setCustomerType,
    setFromDate,
    setCompareTo,
    setGeography,
    setNewEntity,
    setToDate,
    setCustomerTypeLoading,
    setPreviousPeriodDate,
    setForeRender,
} from '../features/filters';
import dayjs from 'dayjs';
import { getCustomerTypeData, getCustomerTypeOnclick } from '../features/filters/filtersApi';
import { formatDates, getPreviousYearDate } from '../helpers/dateformate';

const monthFormat = 'YYYY-MM';

/**
 * A custom hook to manage query parameter state and set default values.
 *
 * @function
 * @example
 * // Use this hook to manage and set query parameters in your component.
 * const { date1, date2, geography, searchParam, setSearchParam } = useQuerySetter();
 *
 * @returns {object} An object containing query parameter values and the setSearchParam function.
 * @returns {string} returns.date1 - The value of the 'date1' query parameter.
 * @returns {string} returns.date2 - The value of the 'date2' query parameter.
 * @returns {string} returns.geography - The value of the 'geography' query parameter.
 * @returns {Object} returns.searchParam - searchParam Object.
 * @returns {function} returns.setSearchParam - Function to update query parameters.
 */
const useQuerySetter = () => {
    const [timer, setTimer] = useState(null);
    const dispatch = useDispatch();
    const isMounted = useRef(false);
    const paramMounted = useRef(false);
    const isCustomerMounted = useRef(false);
    const { search } = useLocation();
    const [searchParam, setSearchParam] = useSearchParams();
    const {
        isLoading,
        geographyData,
        fromDate,
        toDate,
        compareTo,
        customerTypeData,
        filterData,
        selectedEntity,
        checkedEntity,
    } = useSelector(state => state.filter);
    const { geoGraphies, datePickerDates: dates } = filterData;

    const date1 = searchParam.get('fromDate');
    const date2 = searchParam.get('toDate');
    const geography = searchParam.get('geography');
    const customerType = searchParam.get('customerType');
    const entity = searchParam.get('entity');
    const compareToData = searchParam.get('compareTo');
    const handleGeoGraphySelect = value => {
        dispatch(setGeography(value));

        const newSelectedEntity = geoGraphies.filter(item => value.includes(item.geographyID));
        dispatch(setNewEntity(newSelectedEntity));
        dispatch(setCheckedEntity([0]));
        handleCustomerType([0]);
        handleCustomerTypeChange(value, 'geography');
    };

    const handleDate1 = (value, callHandleCustomerTypeChange = true) => {
        dispatch(setFromDate(formatDates(value)));
        if (callHandleCustomerTypeChange) {
            handleCustomerTypeChange(value, 'date1');
        }
    };

    const handleDate2 = (value, callHandleCustomerTypeChange = true) => {
        dispatch(setToDate(formatDates(value)));
        if (callHandleCustomerTypeChange) {
            handleCustomerTypeChange(value, 'date2');
        }
    };

    const handleCompareTo = value => {
        dispatch(setCompareTo(value));
    };

    const handleCustomerType = value => {
        dispatch(setCustomerType(value));
    };

    const handleApply = () => {
        const parsedStartDate = dayjs(`${dates?.[0]?.start?.year}/0${dates?.[0]?.start?.month}`, monthFormat);
        const { previousYearFirstMonth, previousYearSubtractMonth } = getPreviousYearDate(toDate);
        if (previousYearFirstMonth >= parsedStartDate) {
            dispatch(
                setPreviousPeriodDate({
                    fromDate: formatDates(previousYearFirstMonth),
                    toDate: formatDates(previousYearSubtractMonth),
                }),
            );
        }
        dispatch(setForeRender(Math.random() + Math.random()));
        searchParam.set('fromDate', fromDate);
        searchParam.set('toDate', toDate);
        searchParam.set('geography', geographyData.join(','));
        searchParam.set('customerType', customerTypeData.join(','));
        searchParam.set('entity', checkedEntity.join(','));
        searchParam.set('compareTo', compareTo);
        setSearchParam(searchParam);
        dispatch(setApiPayload());
    };

    const handleCustomerTypeChange = (value, filterType = '') => {
        if (timer) {
            clearTimeout(timer);
        }
        dispatch(setCustomerTypeLoading(true));
        setTimer(
            setTimeout(() => {
                let payload = {
                    fromDate,
                    toDate,
                    geoLocationID: geographyData.join(','),
                    entityID: checkedEntity.join(','),
                };

                if (filterType === 'date1') {
                    payload = {
                        ...payload,
                        fromDate: formatDates(value),
                    };
                } else if (filterType === 'date2') {
                    payload = {
                        ...payload,
                        toDate: formatDates(value),
                    };
                } else if (filterType === 'geography') {
                    payload = {
                        ...payload,
                        geoLocationID: value.join(','),
                        entityID: '0',
                    };
                } else if (filterType === 'entity') {
                    payload = {
                        ...payload,
                        entityID: value.join(','),
                    };
                }
                dispatch(getCustomerTypeOnclick(payload));
            }, 1000),
        );
    };

    useEffect(() => {
        if (!geography || !entity || !date1 || !date2) {
            return;
        }

        if (isCustomerMounted.current) {
            return;
        }

        const payload = {
            fromDate: date1,
            toDate: date2,
            geoLocationID: geography,
            entityID: entity,
        };

        dispatch(getCustomerTypeData(payload));
        isCustomerMounted.current = true;
        // Adding dispatch call api many times.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date1, date2, entity, geography]);

    useEffect(() => {
        if (paramMounted.current || isLoading) {
            return;
        }

        const parsedEndDate = dayjs(`${dates?.[0]?.end?.year}/${dates?.[0]?.end?.month}`).format(monthFormat);

        // Get the current year
        const currentYear = dayjs().year();

        // Form the start date for the current year (January)
        const parsedStartDate = dayjs(`${currentYear}/01`).format(monthFormat);

        setSearchParam({
            fromDate: date1 || parsedStartDate,
            toDate: date2 || parsedEndDate || dayjs().format('YYYY-MM'),
            geography: geography || '0',
            customerType: customerType || '0',
            entity: entity || '0',
            compareTo: compareToData || 'none',
        });

        paramMounted.current = true;

        // 'setSearchParam' create infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date1, date2, compareToData, geography, customerType, search, entity, isLoading]);

    useEffect(() => {
        if (isMounted.current) {
            return;
        }

        if (!geoGraphies || !geography || !customerType || !entity || !compareToData) {
            return;
        }
        // update filter value only at beginning.
        const formatedgeography = geography?.split(',').map(Number);
        const formatedCustypeId = customerType?.split(',').map(Number);
        const entityData = entity?.split(',').map(Number);

        const newEntityArray = geoGraphies?.filter(item => formatedgeography.includes(item.geographyID)) || [];

        const parsedStartDate = dayjs(`${dates?.[0]?.start?.year}/0${dates?.[0]?.start?.month}`, monthFormat);
        const { previousYearFirstMonth, previousYearSubtractMonth } = getPreviousYearDate(date2);
        if (previousYearFirstMonth >= parsedStartDate) {
            dispatch(
                setPreviousPeriodDate({
                    fromDate: formatDates(previousYearFirstMonth),
                    toDate: formatDates(previousYearSubtractMonth),
                }),
            );
        }

        dispatch(
            setAllFilter({
                fromDate: date1,
                toDate: date2,
                compareTo: compareToData,
                geography: formatedgeography,
                customerTypeData: formatedCustypeId,
                selectedEntity: newEntityArray,
                checkedEntity: entityData,
            }),
        );
        dispatch(setApiPayload());
        isMounted.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, geography, date1, compareToData, date2, customerType, geoGraphies, entity]);

    return {
        customerTypeData,
        selectedEntity,
        fromDate,
        toDate,
        compareTo,
        geographyData,
        searchParam,
        filterData,
        setSearchParam,
        handleGeoGraphySelect,
        handleDate1,
        handleDate2,
        handleCompareTo,
        handleApply,
        handleCustomerType,
        handleCustomerTypeChange,
    };
};

export default useQuerySetter;
