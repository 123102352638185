import { Typography } from 'antd';
import { formatNumbers, isPositive } from './common';

const { Text } = Typography;

/**
 * Returns a string "0 %" when the input is false, otherwise returns null.
 *
 * @param {boolean} isTotal - The condition to check.
 * @returns {string|null} If `isTotal` is true, returns null; otherwise returns "0 %".
 */
const showZeroWhenNotAvailable = isTotal => {
    if (isTotal) {
        return null;
    }
    return `0.00 %`;
};

export const transformData = data => {
    if (!data) {
        return [];
    }

    const _newData = [];

    data.forEach(item => {
        const isTotal = item?.name === 'Total';
        _newData.push({
            key: item?.key,
            name: <Text strong={isTotal}>{item?.name}</Text>,
            geoLocationId: null,
            current_year: <Text strong={isTotal}>{`$ ${formatNumbers(item?.current_year)}`}</Text>,
            current_year_percentage:
                item?.current_year_percentage === 100 ? (
                    ''
                ) : (
                    <Text strong={isTotal}>
                        {item?.current_year_percentage
                            ? `${formatNumbers(item?.current_year_percentage, false)} %`
                            : showZeroWhenNotAvailable(isTotal)}
                    </Text>
                ),
            previous_year: <Text strong={isTotal}>{`$ ${formatNumbers(item?.previous_year)}`}</Text>,
            previous_year_percentage:
                item?.previous_year_percentage === 100 ? (
                    ''
                ) : (
                    <Text strong={isTotal}>
                        {item?.previous_year_percentage
                            ? `${formatNumbers(item?.previous_year_percentage, false)} %`
                            : showZeroWhenNotAvailable(isTotal)}
                    </Text>
                ),
            target_year: <Text strong={isTotal}>{`$ ${formatNumbers(item?.target_year)}`}</Text>,
            target_year_percentage:
                item?.target_year_percentage === 100 ? (
                    ''
                ) : (
                    <Text strong={isTotal}>
                        {item?.target_year_percentage
                            ? `${formatNumbers(item?.target_year_percentage, false)} %`
                            : showZeroWhenNotAvailable(isTotal)}
                    </Text>
                ),
        });
    });

    return _newData;
};

export const getSummaryColumns = data => {
    if (!data) {
        return [];
    }

    return [
        {
            title: data?.tableHeadings?.sub_title,
            dataIndex: 'name',
            key: 'name',
            width: 100,
        },
        {
            title: data?.tableHeadings?.main_title,
            children: [
                {
                    title: data?.tableHeadings?.current_year,
                    dataIndex: 'current_year',
                    key: 'current_year',
                    width: 100,
                },
                {
                    title: '',
                    dataIndex: 'current_year_percentage',
                    key: 'current_year_percentage',
                    width: 100,
                },
                {
                    title: data?.tableHeadings?.previous_year,
                    dataIndex: 'previous_year',
                    key: 'previous_year',
                    width: 100,
                },
                {
                    title: '',
                    dataIndex: 'previous_year_percentage',
                    key: 'previous_year_percentage',
                    width: 100,
                },
                {
                    title: data?.tableHeadings?.target_year,
                    dataIndex: 'target_year',
                    key: 'target_year',
                    width: 100,
                },
                {
                    title: '',
                    dataIndex: 'target_year_percentage',
                    key: 'target_year_percentage',
                    width: 100,
                },
            ],
        },
    ];
};
