// import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { formatNumbers } from './common';
const { Title } = Typography;

export const getProfitabilityColumn = tableHeadings => {
    if (!tableHeadings) return;

    return [
        {
            title: 'Components',
            dataIndex: 'components',
            key: 'components',
            width: '32%',
            render: components => <Title>{components}</Title>,
        },
        {
            title: tableHeadings?.actual,
            dataIndex: 'actual',
            key: 'actual',
            render: actual => <>{formatNumbers(actual.value)}</>,
        },
        {
            title: tableHeadings?.forecast,
            key: 'forecast',
            dataIndex: 'forecast',
            render: forecast => <>{formatNumbers(forecast.value)}</>,
        },
        {
            title: tableHeadings?.priorYear,
            key: 'target',
            dataIndex: 'target',
            render: target => <>{target.value}</>,
        },
        {
            title: tableHeadings?.target,
            key: 'target',
            dataIndex: 'target',
            render: target => <>{target.value}</>,
        },
        {
            title: tableHeadings?.priorYear,
            key: 'priorYear',
            dataIndex: 'priorYear',
            render: priorYear => <>{priorYear.value}</>,
        },
    ];
};
