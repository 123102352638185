import { createSlice } from '@reduxjs/toolkit';
import { getAllFilter, getCustomerTypeData, getCustomerTypeOnclick } from './filtersApi';

const initialState = {
    isLoading: true,
    customerTypeLoading: true,
    customerTypeLoadingOnClick: false,
    fromDate: '',
    toDate: '',
    compareTo: 'none',
    filterData: {},
    apiPayload: {},
    selectedEntity: [],
    geographyData: [],
    checkedEntity: [],
    customerTypeData: [],
    previousPeriodDate: {},
    forceRender: 1,
};

const breadCrumbFilter = createSlice({
    name: 'keyMetricsCards',
    initialState,
    reducers: {
        setForeRender: (state, action) => {
            state.forceRender = action.payload;
        },
        setPreviousPeriodDate: (state, action) => {
            state.previousPeriodDate = action.payload;
        },
        setFromDate: (state, action) => {
            state.fromDate = action.payload;
        },
        setToDate: (state, action) => {
            state.toDate = action.payload;
        },
        setCompareTo: (state, action) => {
            state.compareTo = action.payload;
        },
        setGeography: (state, action) => {
            state.geographyData = action.payload;
        },
        setCustomerType: (state, action) => {
            state.customerTypeData = action.payload;
        },
        setAllFilter: (state, action) => {
            state.fromDate = action.payload.fromDate;
            state.toDate = action.payload.toDate;
            state.compareTo = action.payload.compareTo;
            state.geographyData = action.payload.geography;
            state.customerTypeData = action.payload.customerTypeData;
            state.checkedEntity = action.payload.checkedEntity;
            state.selectedEntity = action.payload.selectedEntity;
        },
        setApiPayload: state => {
            state.apiPayload = {
                fromDate: state.fromDate,
                toDate: state.toDate,
                compareTo: state.compareTo,
                geoLocationID: state.geographyData?.join(','),
                customerTypeID: state.customerTypeData?.join(','),
                entityID: state.checkedEntity?.join(','),
            };
        },
        setNewEntity: (state, action) => {
            state.selectedEntity = action.payload;
        },
        setCheckedEntity: (state, action) => {
            state.checkedEntity = action.payload;
        },
        setCustomerTypeLoading: (state, action) => {
            state.customerTypeLoadingOnClick = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getAllFilter.pending, state => {
                state.isLoading = true;
            })
            .addCase(getAllFilter.fulfilled, (state, action) => {
                state.filterData = action.payload;
                state.isLoading = false;
            })
            .addCase(getAllFilter.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(getCustomerTypeData.pending, state => {
                state.customerTypeLoading = true;
            })
            .addCase(getCustomerTypeData.fulfilled, (state, action) => {
                state.filterData = { ...state.filterData, customerType: action.payload };
                state.customerTypeLoading = false;
            })
            .addCase(getCustomerTypeData.rejected, (state, action) => {
                state.customerTypeLoading = false;
            })
            .addCase(getCustomerTypeOnclick.pending, state => {
                state.customerTypeLoadingOnClick = true;
            })
            .addCase(getCustomerTypeOnclick.fulfilled, (state, action) => {
                state.filterData = { ...state.filterData, customerType: action.payload };
                state.customerTypeLoadingOnClick = false;
            })
            .addCase(getCustomerTypeOnclick.rejected, (state, action) => {
                state.customerTypeLoadingOnClick = false;
            });
    },
});

export const {
    setAllFilter,
    setFromDate,
    setToDate,
    setCompareTo,
    setGeography,
    setApiPayload,
    setCustomerType,
    setNewEntity,
    setCheckedEntity,
    setCustomerTypeLoading,
    setPreviousPeriodDate,
    setForeRender,
} = breadCrumbFilter.actions;

export default breadCrumbFilter.reducer;
