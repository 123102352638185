import React from 'react';

const OperationsAnalysis = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="none" viewBox="0 0 22 23">
            <path
                fill="#6A6A6A"
                d="M14.856 15.945c-.626.33-1.19.626-1.746.936a.408.408 0 00-.172.274c-.02.262 0 .53-.014.791-.014.385-.247.619-.632.626-.86.007-1.72.007-2.579 0-.398-.007-.68-.255-.625-.653.103-.715-.11-1.162-.839-1.348-.192-.048-.357-.2-.53-.316-.164-.11-.316-.227-.494-.35-.255.144-.523.302-.79.453-.4.213-.737.13-.97-.261a95.187 95.187 0 01-1.231-2.138c-.213-.379-.144-.764.254-.929.64-.26.874-.653.736-1.34-.069-.337.041-.701.048-1.052a.43.43 0 00-.151-.296c-.206-.144-.433-.247-.646-.385-.358-.227-.454-.55-.255-.914.413-.736.839-1.471 1.272-2.193.213-.351.55-.427.921-.234.275.144.537.302.736.42.619-.33 1.19-.626 1.753-.943a.374.374 0 00.158-.247c.021-.261 0-.53.007-.79.014-.406.24-.64.646-.64.846-.007 1.699-.007 2.544 0 .42 0 .702.247.647.667-.097.701.123 1.107.818 1.334.378.123.701.419 1.065.653.248-.138.51-.303.777-.447.378-.206.722-.117.942.254.426.715.846 1.437 1.251 2.166.207.364.11.687-.247.914-.213.138-.447.234-.646.385-.083.062-.145.213-.152.323a33.712 33.712 0 000 1.671c0 .11.076.254.159.316.192.145.412.24.618.365.378.233.475.557.261.935a84.46 84.46 0 01-1.25 2.165c-.22.365-.565.447-.943.241-.247-.144-.502-.296-.7-.413zm-1.925-4.468a1.915 1.915 0 00-1.918-1.919A1.908 1.908 0 009.08 11.49a1.91 1.91 0 001.918 1.919 1.908 1.908 0 001.932-1.932z"
            ></path>
            <path
                fill="#6A6A6A"
                d="M5.506 19.466a9.582 9.582 0 002.88 1.354c5.116 1.396 10.265-1.389 11.88-6.428.675-2.111.585-4.215-.198-6.284-.035-.083-.076-.165-.097-.255-.117-.385.041-.715.385-.838a.613.613 0 01.818.405c.248.777.537 1.561.674 2.365.935 5.5-2.544 10.746-7.776 12.259-3.327.962-6.442.378-9.322-1.527-.145-.096-.282-.192-.344-.233-.09.261-.117.577-.282.777-.282.343-.839.206-1.004-.234a58.001 58.001 0 01-.949-2.86c-.15-.488.385-.866.942-.688.62.2 1.231.413 1.85.619.192.062.392.124.584.192.426.145.626.351.633.674.007.323-.18.53-.674.702zM17.716 2.752c-.069-.33-.11-.626.158-.86.337-.295.86-.192 1.018.255.337.942.66 1.898.949 2.86.144.488-.385.86-.95.68-.618-.199-1.23-.412-1.849-.618-.192-.062-.392-.124-.584-.192-.426-.145-.632-.351-.626-.674.014-.392.262-.585.66-.674-.096-.076-.158-.138-.227-.179C10.532-.328 2.983 2.8 1.518 9.455c-.412 1.87-.247 3.7.461 5.48.192.488.096.839-.261 1.004-.344.158-.722.02-.873-.358-.62-1.512-.929-3.087-.825-4.723.137-2.248.886-4.29 2.282-6.064 1.794-2.29 4.118-3.72 7.02-4.166 2.983-.461 5.685.26 8.154 1.966.068.048.144.096.24.158z"
            ></path>
        </svg>
    );
};

export default OperationsAnalysis;
