import React from 'react';

const UserIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
            <path
                fill="#fff"
                stroke="#fff"
                d="M10.162 1.163c1.952.663 3.063 2.146 3.411 4.188.268 1.567.517 3.138.775 4.707.048.287.189.52.417.697.2.156.404.305.605.46.51.395.703.994.509 1.576-.194.58-.707.947-1.35.95-1.087.004-2.175 0-3.263 0h-.21c-.354 1.414-1.21 2.277-2.681 2.254-1.42-.022-2.246-.876-2.581-2.253h-.206c-1.088 0-2.176.003-3.264-.001-.705-.003-1.255-.429-1.394-1.096-.118-.567.062-1.04.512-1.402.202-.162.413-.313.619-.47a1.11 1.11 0 00.438-.724c.245-1.493.51-2.983.73-4.48.248-1.691 1.028-3.028 2.48-3.935.327-.204.694-.345 1.05-.495 0 0 1.664-.566 3.403.024h0zM8.42 12.978v-.006h2.294c1.27.002 2.54.005 3.81.005.327 0 .551-.155.642-.428.091-.273.003-.532-.253-.732-.205-.16-.415-.312-.618-.473a1.812 1.812 0 01-.684-1.143c-.11-.635-.212-1.27-.316-1.907-.17-1.032-.313-2.07-.515-3.096-.497-2.538-2.988-4.056-5.38-3.454-1.779.447-2.92 1.587-3.305 3.38-.331 1.547-.55 3.12-.78 4.687-.111.75-.403 1.349-1.048 1.759a3.194 3.194 0 00-.406.312.622.622 0 00-.05.89c.155.173.354.209.575.208 2.011-.002 4.023-.001 6.034-.001v-.001zm-1.822.773c.113.849.967 1.523 1.882 1.497.872-.024 1.708-.73 1.764-1.497H6.597z"
            ></path>
        </svg>
    );
};

export default UserIcon;
