import React from 'react';

const FilterIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" fill="none" viewBox="0 0 18 13">
            <g>
                <g stroke="#B5B5B5" strokeWidth="2">
                    <path d="M17.5 1.5H0"></path>
                    <path d="M14 6.5H3"></path>
                    <path d="M11 11.5H6"></path>
                </g>
            </g>
        </svg>
    );
};

export default FilterIcon;
