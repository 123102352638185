import React from 'react';
import { useSelector } from 'react-redux';
import { Tag, Row } from 'antd';
import './index.scss';
import { hideEntireFilterSet } from '../../helpers/settings';

const getEntityLabel = (geoGraphies, entityIDsToShow) => {
    if (!geoGraphies) return [];
    return geoGraphies.flatMap(item => item.entities.filter(entity => entityIDsToShow.includes(entity.value)));
};

const SelectedFilter = () => {
    const currentPage = location.pathname.substring(1);
    const { geographyData, fromDate, toDate, customerTypeData, filterData, checkedEntity, compareTo } = useSelector(
        state => state.filter,
    );

    if ( hideEntireFilterSet.includes( currentPage ) ) {
        return null;
    }

    const { geoGraphies, customerType } = filterData;

    const geoGraphyItem = geoGraphies?.filter(obj => geographyData.includes(obj.geographyID));
    const customerTypeItem = customerType?.filter(obj => customerTypeData.includes(obj.value));
    const entityItem = getEntityLabel(geoGraphies, checkedEntity);

    return (
        <div className="jv-filters">
            <Row gutter={[2, 2]} align="left">
                <Tag className="jv-tags__tag">
                    { fromDate } to { toDate }
                </Tag>
                {compareTo && (
                            <Tag className="jv-tags__tag jv-tags__capital">
                                Compare To: {compareTo}
                            </Tag>
                            )
                }
                {
                    geographyData.includes(0) && (
                        <Tag key="geography-0" className="jv-tags__tag">
                            Region: All
                        </Tag>
                    )
                }
                {
                    geoGraphyItem && geoGraphyItem.map( ( item, index ) => (
                        <Tag key={index} className="jv-tags__tag">
                            { item.geography }
                        </Tag>
                    ) )
                }
                {
                    checkedEntity.includes(0) && (
                        <Tag key="entity-type-0" className="jv-tags__tag">
                            Entity: All
                        </Tag>
                    )
                }
                {
                    entityItem && entityItem.map( ( item, index ) => (
                        <Tag key={index} className="jv-tags__tag">
                            { item.label }
                        </Tag>
                    ) )
                }
                {
                    customerTypeData.includes(0) && (
                        <Tag key="customer-type-0" className="jv-tags__tag">
                            Customer Group: All
                        </Tag>
                    )
                }
                {
                    customerTypeItem && customerTypeItem.map( ( item, index ) => (
                        <Tag key={index} className="jv-tags__tag">
                            { item.label }
                        </Tag>
                    ) )
                }  
            </Row>
        </div>
    );
};

export default SelectedFilter;