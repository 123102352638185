import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../service/axiosInstance';
import { transportRate } from '../../service/path';

export const getTransportMetrics = createAsyncThunk('transportRate/getTransportMetrics', async (payload, thunkAPI) => {
    try {
        const resp = await axios.post(transportRate.metrics, payload);
        return resp.data;
    } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
    }
});

export const getTransportImpact = createAsyncThunk('transportRate/getTransportImpact', async (payload, thunkAPI) => {
    try {
        const resp = await axios.post(transportRate.transportImpact, payload);
        return resp.data;
    } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
    }
});
