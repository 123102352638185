import { render } from '@testing-library/react';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import KeyMetrics from '../mock/keyMetrics.json';

const status = {
    up: <ArrowUpOutlined />,
    down: <ArrowDownOutlined />,
};

export const businessType = [
    {
        title: 'Components',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: KeyMetrics.KeyMetrics.allocations.business.TableHeading.volume,
        dataIndex: 'volume',
        key: 'volume',
        render: revenue => {
            return (
                <span>
                    {revenue.value}
                    <span>{revenue.unit}</span>
                </span>
            );
        },
    },
    {
        title: 'Revenue',
        dataIndex: 'revenue',
        key: 'revenue',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {(revenue.status === 'up' && status.up) ||
                        (revenue.status === 'down' && status.down) ||
                        (revenue.status === '' && '')}
                </span>
            );
        },
    },
    {
        title: 'margin',
        dataIndex: 'margin',
        key: 'margin',
        render: revenue => {
            return (
                <span>
                    {revenue.value}
                    {revenue.sign}
                    {(revenue.status === 'up' && status.up) ||
                        (revenue.status === 'down' && status.down) ||
                        (revenue.status === '' && '')}
                </span>
            );
        },
    },
];

export const shareofbussinessess = [
    {
        title: 'Components',
        dataIndex: 'components',
        key: 'components',
    },
    {
        title: 'Revenue',
        dataIndex: 'revenue',
        key: 'revenue',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {(revenue.status === 'up' && status.up) ||
                        (revenue.status === 'down' && status.down) ||
                        (revenue.status === '' && '')}
                </span>
            );
        },
    },
    {
        title: 'COGS',
        dataIndex: 'cogs',
        key: 'cogs',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {(revenue.status === 'up' && status.up) ||
                        (revenue.status === 'down' && status.down) ||
                        (revenue.status === '' && '')}
                </span>
            );
        },
    },
    {
        title: 'GrossProfit',
        dataIndex: 'grossProfit',
        key: 'grossProfit',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {(revenue.status === 'up' && status.up) ||
                        (revenue.status === 'down' && status.down) ||
                        (revenue.status === '' && '')}
                </span>
            );
        },
    },
    {
        title: 'EBITDA',
        dataIndex: 'ebitda',
        key: 'ebitda',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {(revenue.status === 'up' && status.up) ||
                        (revenue.status === 'down' && status.down) ||
                        (revenue.status === '' && '')}
                </span>
            );
        },
    },
];

export const salesContribution = [
    {
        title: 'Components',
        dataIndex: 'components',
        key: 'components',
    },
    {
        title: 'Actual',
        dataIndex: 'revenue',
        key: 'revenue',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {revenue.status}
                </span>
            );
        },
    },
    {
        title: 'Forecast',
        dataIndex: 'cogs',
        key: 'cogs',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {revenue.status}
                </span>
            );
        },
    },
    {
        title: 'Target',
        dataIndex: 'grossProfit',
        key: 'grossProfit',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {revenue.status}
                </span>
            );
        },
    },
    {
        title: 'Prior Year',
        dataIndex: 'ebitda',
        key: 'ebitda',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {revenue.status}
                </span>
            );
        },
    },
];

export const salesData = [
    {
        id: '1',
        components: 'Net Sales',
        revenue: {
            value: 18,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        cogs: {
            value: 18,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        grossProfit: {
            value: 18,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        ebitda: {
            value: 18,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
    },
    {
        id: '2',
        components: 'Royalties',
        revenue: {
            value: 4,
            currency: '$',
            unit: 'mn',
            status: status.down,
        },
        cogs: {
            value: 4,
            currency: '$',
            unit: 'mn',
            status: status.down,
        },
        grossProfit: {
            value: 4,
            currency: '$',
            unit: 'mn',
            status: status.down,
        },
        ebitda: {
            value: 4,
            currency: '$',
            unit: 'mn',
            status: status.down,
        },
    },
    {
        id: '2',
        components: 'Brokerage',
        revenue: {
            value: 1.8,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        cogs: {
            value: 1.8,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        grossProfit: {
            value: 1.8,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        ebitda: {
            value: 1.8,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
    },
    {
        id: '2',
        components: 'Trading',
        revenue: {
            value: 1.1,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        cogs: {
            value: 1.1,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        grossProfit: {
            value: 1.1,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        ebitda: {
            value: 1.1,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
    },
    {
        id: '3',
        components: 'Total',
        revenue: {
            value: 25.5,
            currency: '',
            unit: 'mn',
            status: '',
        },
        cogs: {
            value: 11.3,
            currency: '',
            unit: 'mn',
            status: '',
        },
        grossProfit: {
            value: 11.3,
            currency: '',
            unit: 'mn',
            status: '',
        },
        ebitda: {
            value: 11.3,
            currency: '',
            unit: 'mn',
            status: '',
        },
    },
];

export const profitContribution = [
    {
        title: 'Components',
        dataIndex: 'components',
        key: 'components',
    },
    {
        title: 'Actual',
        dataIndex: 'revenue',
        key: 'revenue',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {revenue.status}
                </span>
            );
        },
    },
    {
        title: 'Forecast',
        dataIndex: 'cogs',
        key: 'cogs',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {revenue.status}
                </span>
            );
        },
    },
    {
        title: 'Target',
        dataIndex: 'grossProfit',
        key: 'grossProfit',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {revenue.status}
                </span>
            );
        },
    },
    {
        title: 'Prior Year',
        dataIndex: 'ebitda',
        key: 'ebitda',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {revenue.status}
                </span>
            );
        },
    },
];

export const profitData = [
    {
        id: '1',
        components: 'Net Sales',
        revenue: {
            value: 18,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        cogs: {
            value: 18,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        grossProfit: {
            value: 18,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        ebitda: {
            value: 18,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
    },
    {
        id: '2',
        components: 'Royalties',
        revenue: {
            value: 4,
            currency: '$',
            unit: 'mn',
            status: status.down,
        },
        cogs: {
            value: 4,
            currency: '$',
            unit: 'mn',
            status: status.down,
        },
        grossProfit: {
            value: 4,
            currency: '$',
            unit: 'mn',
            status: status.down,
        },
        ebitda: {
            value: 4,
            currency: '$',
            unit: 'mn',
            status: status.down,
        },
    },
    {
        id: '2',
        components: 'Brokerage',
        revenue: {
            value: 1.8,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        cogs: {
            value: 1.8,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        grossProfit: {
            value: 1.8,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        ebitda: {
            value: 1.8,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
    },
    {
        id: '2',
        components: 'Trading',
        revenue: {
            value: 1.1,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        cogs: {
            value: 1.1,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        grossProfit: {
            value: 1.1,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        ebitda: {
            value: 1.1,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
    },
    {
        id: '3',
        components: 'Total',
        revenue: {
            value: 25.5,
            currency: '',
            unit: 'mn',
            status: '',
        },
        cogs: {
            value: 11.3,
            currency: '',
            unit: 'mn',
            status: '',
        },
        grossProfit: {
            value: 11.3,
            currency: '',
            unit: 'mn',
            status: '',
        },
        ebitda: {
            value: 11.3,
            currency: '',
            unit: 'mn',
            status: '',
        },
    },
];

export const operationContribution = [
    {
        title: 'Components',
        dataIndex: 'components',
        key: 'components',
    },
    {
        title: 'Truck',
        dataIndex: 'revenue',
        key: 'revenue',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {revenue.status}
                </span>
            );
        },
    },
    {
        title: 'Rail',
        dataIndex: 'cogs',
        key: 'cogs',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {revenue.status}
                </span>
            );
        },
    },
    {
        title: 'Ocean',
        dataIndex: 'grossProfit',
        key: 'grossProfit',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {revenue.status}
                </span>
            );
        },
    },
    {
        title: 'Air',
        dataIndex: 'ebitda',
        key: 'ebitda',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {revenue.status}
                </span>
            );
        },
    },
];

export const operationData = [
    {
        id: '1',
        components: 'JVL Export',
        revenue: {
            value: 18,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        cogs: {
            value: 18,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        grossProfit: {
            value: 18,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        ebitda: {
            value: 18,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
    },
    {
        id: '2',
        components: 'FBC Import',
        revenue: {
            value: 4,
            currency: '$',
            unit: 'mn',
            status: status.down,
        },
        cogs: {
            value: 4,
            currency: '$',
            unit: 'mn',
            status: status.down,
        },
        grossProfit: {
            value: 4,
            currency: '$',
            unit: 'mn',
            status: status.down,
        },
        ebitda: {
            value: 4,
            currency: '$',
            unit: 'mn',
            status: status.down,
        },
    },
    {
        id: '2',
        components: 'QB Food Import/Export',
        revenue: {
            value: 1.8,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        cogs: {
            value: 1.8,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        grossProfit: {
            value: 1.8,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        ebitda: {
            value: 1.8,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
    },
    {
        id: '2',
        components: 'PB Food Import/Export',
        revenue: {
            value: 1.1,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        cogs: {
            value: 1.1,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        grossProfit: {
            value: 1.1,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        ebitda: {
            value: 1.1,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
    },
    {
        id: '3',
        components: 'Total',
        revenue: {
            value: 25.5,
            currency: '',
            unit: 'mn',
            status: '',
        },
        cogs: {
            value: 11.3,
            currency: '',
            unit: 'mn',
            status: '',
        },
        grossProfit: {
            value: 11.3,
            currency: '',
            unit: 'mn',
            status: '',
        },
        ebitda: {
            value: 11.3,
            currency: '',
            unit: 'mn',
            status: '',
        },
    },
];

export const forexContribution = [
    {
        title: 'Components',
        dataIndex: 'components',
        key: 'components',
    },
    {
        title: 'Truck',
        dataIndex: 'revenue',
        key: 'revenue',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {revenue.status}
                </span>
            );
        },
    },
    {
        title: 'Rail',
        dataIndex: 'cogs',
        key: 'cogs',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {revenue.status}
                </span>
            );
        },
    },
    {
        title: 'Ocean',
        dataIndex: 'grossProfit',
        key: 'grossProfit',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {revenue.status}
                </span>
            );
        },
    },
    {
        title: 'Air',
        dataIndex: 'ebitda',
        key: 'ebitda',
        render: revenue => {
            return (
                <span>
                    {revenue.currency}
                    {revenue.value}
                    {revenue.unit}
                    {revenue.status}
                </span>
            );
        },
    },
];

export const forexData = [
    {
        id: '1',
        components: 'JVL Export',
        revenue: {
            value: 18,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        cogs: {
            value: 18,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        grossProfit: {
            value: 18,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        ebitda: {
            value: 18,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
    },
    {
        id: '2',
        components: 'FBC Import',
        revenue: {
            value: 4,
            currency: '$',
            unit: 'mn',
            status: status.down,
        },
        cogs: {
            value: 4,
            currency: '$',
            unit: 'mn',
            status: status.down,
        },
        grossProfit: {
            value: 4,
            currency: '$',
            unit: 'mn',
            status: status.down,
        },
        ebitda: {
            value: 4,
            currency: '$',
            unit: 'mn',
            status: status.down,
        },
    },
    {
        id: '2',
        components: 'QB Food Import/Export',
        revenue: {
            value: 1.8,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        cogs: {
            value: 1.8,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        grossProfit: {
            value: 1.8,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        ebitda: {
            value: 1.8,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
    },
    {
        id: '2',
        components: 'PB Food Import/Export',
        revenue: {
            value: 1.1,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        cogs: {
            value: 1.1,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        grossProfit: {
            value: 1.1,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
        ebitda: {
            value: 1.1,
            currency: '$',
            unit: 'mn',
            status: status.up,
        },
    },
    {
        id: '3',
        components: 'Total',
        revenue: {
            value: 25.5,
            currency: '',
            unit: 'mn',
            status: '',
        },
        cogs: {
            value: 11.3,
            currency: '',
            unit: 'mn',
            status: '',
        },
        grossProfit: {
            value: 11.3,
            currency: '',
            unit: 'mn',
            status: '',
        },
        ebitda: {
            value: 11.3,
            currency: '',
            unit: 'mn',
            status: '',
        },
    },
];
