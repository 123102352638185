export const pathName = {
    summary: 'summary',
    keyMetrics: 'key-metrics',
    salesAnalysis: 'sales-analysis',
    profitCenterAnalysis: 'profit-center-analysis',
    operationAnalysis: 'operation-analysis',
    forexImpact: 'forex-impact',
    transportationRate: 'transportation-rate-variance',
    glossary: 'glossary',
    login: '/',
};
