import { createSlice } from '@reduxjs/toolkit';
import {
    getBusinessShare,
    getBusinessType,
    getContribution,
    getEntityContribution,
    getKeyMetrics,
    getSalesTrending,
    getVolumeTrending,
    getRegionalContributionComponentVariance,
    getCustomergroupComponentVariance,
    getEntityContributionComponentVariance,
} from './keymetrcisAPI';

const initialState = {
    keyMetricsCards: {
        isLoading: true,
        isError: false,
        keyMetricsData: [],
    },
    keyMetricsSalesTrending: {
        isLoading: true,
        isError: false,
        salesTrendingData: [],
        salePreviousData: [],
    },
    keyMetricsVolumeTrending: {
        isLoading: true,
        isError: false,
        volumeTrendingData: [],
        previousVolumeTrendingData: [],
    },
    keyMetricsBusinessShare: {
        isLoading: true,
        isError: false,
        businessShareData: [],
    },
    keyMetricsContribution: {
        isLoading: true,
        isError: false,
        contributionData: [],
    },
    keyMetricsBusinessType: {
        isLoading: true,
        isError: false,
        businessTypeData: [],
    },
    keyMetricsEntityContribution: {
        isLoading: true,
        isError: false,
        EntityContributionData: [],
    },
    keyRegionalContributionComponentVariance: {
        isLoading: true,
        isError: false,
        RegionalContributionComponentVariance: [],
    },
    keyEntityContributionComponentVariance: {
        isLoading: true,
        isError: false,
        EntityContributionComponentVariance: [],
    },
    keyCustomergroupComponentVariance: {
        isLoading: true,
        isError: false,
        CustomergroupComponentVariance: [],
    },
};

const keyMetricsSlice = createSlice({
    name: 'keyMetricsCards',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getKeyMetrics.pending, state => {
                state.keyMetricsCards.isLoading = true;
            })
            .addCase(getKeyMetrics.fulfilled, (state, action) => {
                state.keyMetricsCards.isLoading = false;
                state.keyMetricsCards.keyMetricsData = action.payload;
            })
            .addCase(getKeyMetrics.rejected, (state, action) => {
                state.keyMetricsCards.isLoading = false;
            })
            .addCase(getSalesTrending.pending, state => {
                state.keyMetricsSalesTrending.isLoading = true;
            })
            .addCase(getSalesTrending.fulfilled, (state, action) => {
                state.keyMetricsSalesTrending.isLoading = false;
                state.keyMetricsSalesTrending.salesTrendingData = action.payload.current;
                state.keyMetricsSalesTrending.salePreviousData = action.payload.previous;
            })
            .addCase(getSalesTrending.rejected, (state, action) => {
                state.keyMetricsSalesTrending.isLoading = false;
            })
            .addCase(getVolumeTrending.pending, state => {
                state.keyMetricsVolumeTrending.isLoading = true;
            })
            .addCase(getVolumeTrending.fulfilled, (state, action) => {
                state.keyMetricsVolumeTrending.isLoading = false;
                state.keyMetricsVolumeTrending.volumeTrendingData = action.payload.current;
                state.keyMetricsVolumeTrending.previousVolumeTrendingData = action.payload.previous;
            })
            .addCase(getVolumeTrending.rejected, (state, action) => {
                state.keyMetricsVolumeTrending.isLoading = false;
            })
            .addCase(getBusinessShare.pending, state => {
                state.keyMetricsBusinessShare.isLoading = true;
            })
            .addCase(getBusinessShare.fulfilled, (state, action) => {
                state.keyMetricsBusinessShare.isLoading = false;
                state.keyMetricsBusinessShare.businessShareData = action.payload;
            })
            .addCase(getBusinessShare.rejected, (state, action) => {
                state.keyMetricsBusinessShare.isLoading = false;
            })
            .addCase(getContribution.pending, state => {
                state.keyMetricsContribution.isLoading = true;
            })
            .addCase(getContribution.fulfilled, (state, action) => {
                state.keyMetricsContribution.isLoading = false;
                state.keyMetricsContribution.contributionData = action.payload.geographySegment;
                state.keyMetricsContribution.isError = false;
            })
            .addCase(getContribution.rejected, (state, action) => {
                state.keyMetricsContribution.isLoading = false;
                state.keyMetricsContribution.isError = true;
            })
            .addCase(getBusinessType.pending, state => {
                state.keyMetricsBusinessType.isLoading = true;
            })
            .addCase(getBusinessType.fulfilled, (state, action) => {
                state.keyMetricsBusinessType.isLoading = false;
                state.keyMetricsBusinessType.businessTypeData = action.payload.businessType;
            })
            .addCase(getBusinessType.rejected, (state, action) => {
                state.keyMetricsBusinessType.isLoading = false;
            })
            .addCase(getEntityContribution.pending, state => {
                state.keyMetricsEntityContribution.isLoading = true;
            })
            .addCase(getEntityContribution.fulfilled, (state, action) => {
                state.keyMetricsEntityContribution.isLoading = false;
                state.keyMetricsEntityContribution.EntityContributionData = action.payload.entitySegment;
                state.keyMetricsEntityContribution.isError = false;
            })
            .addCase(getEntityContribution.rejected, (state, action) => {
                state.keyMetricsEntityContribution.isLoading = false;
                state.keyMetricsEntityContribution.isError = true;
            })
            .addCase(getRegionalContributionComponentVariance.pending, state => {
                state.keyRegionalContributionComponentVariance.isLoading = true;
            })
            .addCase(getRegionalContributionComponentVariance.fulfilled, (state, action) => {
                state.keyRegionalContributionComponentVariance.isLoading = false;
                state.keyRegionalContributionComponentVariance.RegionalContributionComponentVariance =
                    action.payload.keyMatrixVariances;
                state.keyRegionalContributionComponentVariance.isError = false;
            })
            .addCase(getRegionalContributionComponentVariance.rejected, (state, action) => {
                state.keyRegionalContributionComponentVariance.isLoading = false;
                state.keyRegionalContributionComponentVariance.isError = true;
            })
            .addCase(getCustomergroupComponentVariance.pending, state => {
                state.keyCustomergroupComponentVariance.isLoading = true;
            })
            .addCase(getCustomergroupComponentVariance.fulfilled, (state, action) => {
                state.keyCustomergroupComponentVariance.isLoading = false;
                state.keyCustomergroupComponentVariance.CustomergroupComponentVariance =
                    action.payload.keyMatrixVariances;
                state.keyCustomergroupComponentVariance.isError = false;
            })
            .addCase(getCustomergroupComponentVariance.rejected, (state, action) => {
                state.keyCustomergroupComponentVariance.isLoading = false;
                state.keyCustomergroupComponentVariance.isError = true;
            })
            .addCase(getEntityContributionComponentVariance.pending, state => {
                state.keyEntityContributionComponentVariance.isLoading = true;
            })
            .addCase(getEntityContributionComponentVariance.fulfilled, (state, action) => {
                state.keyEntityContributionComponentVariance.isLoading = false;
                state.keyEntityContributionComponentVariance.EntityContributionComponentVariance =
                    action.payload.keyMatrixVariances;
                state.keyEntityContributionComponentVariance.isError = false;
            })
            .addCase(getEntityContributionComponentVariance.rejected, (state, action) => {
                state.keyEntityContributionComponentVariance.isLoading = false;
                state.keyEntityContributionComponentVariance.isError = true;
            });
    },
});

export default keyMetricsSlice.reducer;
