import { pathName } from './routes';

export const navigations = [
    {
        key: '1',
        label: 'Summary',
        icon: 'summary',
        path: pathName.summary,
    },
    {
        key: '2',
        label: 'Key Metrics',
        icon: 'keyMetrics',
        path: pathName.keyMetrics,
    },
    {
        key: '3',
        label: 'Sales Analysis',
        icon: 'salesAnalysis',
        path: pathName.salesAnalysis,
    },
    {
        key: '4',
        label: 'Profit Center Analysis',
        icon: 'profitCenter',
        path: pathName.profitCenterAnalysis,
    },
    {
        key: '5',
        label: 'Operations Analysis',
        icon: 'operationsAnalysis',
        path: pathName.operationAnalysis,
    },
    {
        key: '6',
        label: 'Forex Impact',
        icon: 'forexImpact',
        path: pathName.forexImpact,
    },
    {
        key: '7',
        label: 'Transportation Rate Variance(TBD)',
        icon: 'transportRateVariance',
        path: pathName.transportationRate,
    },
    {
        key: '8',
        label: 'Glossary',
        icon: 'glossary',
        path: pathName.glossary,
    },
    {
        key: '9',
        label: 'Log Out',
        icon: 'logout',
        path: pathName.login,
    },
];
