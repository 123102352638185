import React from 'react';

const SalesAnalysis = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21">
            <path
                fill="#6A6A6A"
                d="M1.936 16.421c1.3-1.299 2.583-2.575 3.875-3.867.9.924 1.817 1.863 2.74 2.801 1.585-1.592 3.094-3.116 4.663-4.693l1.727 1.795c.023-.015.053-.038.075-.053V7.531h-4.828c.54.533 1.111 1.089 1.66 1.622-1.157 1.156-2.215 2.207-3.304 3.289l-2.68-2.748-4.889 4.933c-1.75-3.274-1.367-9.018 3.326-12.33C8.83-.892 15.19.294 18.291 4.897a9.993 9.993 0 01-2.862 14.018c-4.625 2.989-10.617 1.592-13.493-2.493z"
            ></path>
        </svg>
    );
};

export default SalesAnalysis;
