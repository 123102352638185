import { createSlice } from '@reduxjs/toolkit';
import { getExportDelivery, getImportDelivery, getProfitMetrics } from './operationAnalysisApi';

const initialState = {
    metrics: {
        isLoading: true,
        isError: false,
        data: [],
    },
    exportDelivery: {
        isLoading: true,
        isError: false,
        data: [],
    },
    importDelivery: {
        isLoading: true,
        isError: false,
        data: [],
    },
};

const profitAnalysisSlice = createSlice({
    name: 'operationAnalysis',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getProfitMetrics.pending, state => {
                state.metrics.isLoading = true;
            })
            .addCase(getProfitMetrics.fulfilled, (state, action) => {
                state.metrics.isLoading = false;
                state.metrics.data = action.payload;
            })
            .addCase(getProfitMetrics.rejected, (state, action) => {
                state.metrics.isLoading = false;
            })
            .addCase(getExportDelivery.pending, state => {
                state.exportDelivery.isLoading = true;
            })
            .addCase(getExportDelivery.fulfilled, (state, action) => {
                state.exportDelivery.isLoading = false;
                state.exportDelivery.data = action.payload;
            })
            .addCase(getExportDelivery.rejected, (state, action) => {
                state.exportDelivery.isLoading = false;
            })
            .addCase(getImportDelivery.pending, state => {
                state.importDelivery.isLoading = true;
            })
            .addCase(getImportDelivery.fulfilled, (state, action) => {
                state.importDelivery.isLoading = false;
                state.importDelivery.data = action.payload;
            })
            .addCase(getImportDelivery.rejected, (state, action) => {
                state.importDelivery.isLoading = false;
            });
    },
});

export default profitAnalysisSlice.reducer;
