import React from 'react';
// import Breadcrumb from '../../components/breadcrumb';
import { Row, Col, Space, Select, Table, Card, Typography, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { salesContribution, salesData } from '../../helpers/demoTable';
import Cards from '../../components/cards/index';
import { transportationCards, spline } from '../../helpers/charts';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import '../../helpers/override.scss';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

const TransportationRate = () => {
    const { Title, Text } = Typography;

    const status = {
        up: <ArrowUpOutlined />,
        down: <ArrowDownOutlined />,
    };
    const data = {
        head: 'Transportation Impact',
        month: 'Current Month',
        label: 'Transportation Impact',
    };

    const project = [
        {
            title: 'Components',
            dataIndex: 'name',
            width: '32%',
        },
        {
            title: 'Actual',
            dataIndex: 'age',
        },
        {
            title: 'Forcast',
            dataIndex: 'address',
        },
        {
            title: 'Target',
            dataIndex: 'completion',
        },
        {
            title: 'Prior Year',
            dataIndex: 'ebitda',
        },
    ];
    const dataproject = [
        {
            key: '1',

            name: (
                <>
                    <Avatar.Group>
                        <div className="avatar-info">
                            <Title level={5}>Net Sales</Title>
                        </div>
                    </Avatar.Group>
                </>
            ),
            age: (
                <>
                    <div className={`semibold ${status.up ? 'green' : status.down ? 'red' : ''}`}>
                        {status.up} $18 mn
                    </div>
                </>
            ),
            address: (
                <>
                    <div className={`semibold ${status.up ? 'green' : status.down ? 'red' : ''}`}>
                        {status.up} $18 mn
                    </div>
                </>
            ),
            completion: (
                <>
                    <div className={`semibold ${status.up ? 'green' : status.down ? 'red' : ''}`}>{status.up} 25%</div>
                </>
            ),
            ebitda: (
                <>
                    <div className={`semibold ${status.up ? 'green' : status.down ? 'red' : ''}`}>{status.up} 25%</div>
                </>
            ),
        },

        {
            key: '2',
            name: (
                <>
                    <Avatar.Group>
                        <div className="avatar-info">
                            <Title level={5}>Royalties</Title>
                        </div>
                    </Avatar.Group>
                </>
            ),
            age: (
                <>
                    <div className={`semibold ${status.down ? 'red' : status.up ? 'green' : ''}`}>
                        {status.down} $4 mn
                    </div>
                </>
            ),
            address: (
                <>
                    <div className={`semibold ${status.down ? 'red' : status.up ? 'green' : ''}`}>
                        {status.down} $4 mn
                    </div>
                </>
            ),
            completion: (
                <>
                    <div className={`semibold ${status.down ? 'red' : status.up ? 'green' : ''}`}>
                        {status.down} 19%
                    </div>
                </>
            ),
            ebitda: (
                <>
                    <div className={`semibold ${status.down ? 'red' : status.up ? 'green' : ''}`}>
                        {status.down} 19%
                    </div>
                </>
            ),
        },

        {
            key: '3',
            name: (
                <>
                    <Avatar.Group>
                        <div className="avatar-info">
                            <Title level={5}>Brokerage</Title>
                        </div>
                    </Avatar.Group>
                </>
            ),
            age: (
                <>
                    <div className={`semibold ${status.up ? 'green' : status.down ? 'red' : ''}`}>
                        {status.up} $1.8 mn
                    </div>
                </>
            ),
            address: (
                <>
                    <div className={`semibold ${status.up ? 'green' : status.down ? 'red' : ''}`}>
                        {status.up} $1.8 mn
                    </div>
                </>
            ),
            completion: (
                <>
                    <div className={`semibold ${status.down ? 'red' : status.up ? 'green' : ''}`}>
                        {status.down} 12%
                    </div>
                </>
            ),
            ebitda: (
                <>
                    <div className={`semibold ${status.down ? 'red' : status.up ? 'green' : ''}`}>
                        {status.down} 12%
                    </div>
                </>
            ),
        },

        {
            key: '4',
            name: (
                <>
                    <Avatar.Group>
                        <div className="avatar-info">
                            <Title level={5}>Trading</Title>
                        </div>
                    </Avatar.Group>
                </>
            ),
            age: (
                <>
                    <div className={`semibold ${status.up ? 'green' : status.down ? 'red' : ''}`}>
                        {status.up} $1.1 mn
                    </div>
                </>
            ),
            address: (
                <>
                    <div className={`semibold ${status.up ? 'green' : status.down ? 'red' : ''}`}>
                        {status.up} $1.1 mn
                    </div>
                </>
            ),
            completion: (
                <>
                    <div className={`semibold ${status.down ? 'red' : status.up ? 'green' : ''}`}>{status.down} 8%</div>
                </>
            ),
            ebitda: (
                <>
                    <div className={`semibold ${status.down ? 'red' : status.up ? 'green' : ''}`}>{status.down} 8%</div>
                </>
            ),
        },

        {
            key: '5',
            name: (
                <>
                    <Avatar.Group>
                        <div className="avatar-info">
                            <Title level={5}>Total</Title>
                        </div>
                    </Avatar.Group>
                </>
            ),
            age: (
                <>
                    <div className="semibold">25.2 mn</div>
                </>
            ),
            address: (
                <>
                    <div className="text-sm">11.3 mn</div>
                </>
            ),
            completion: (
                <>
                    <div className="ant-progress-project">11.3 mn</div>
                </>
            ),
            ebitda: (
                <>
                    <div className="text-sm">11.3 mn</div>
                </>
            ),
        },
    ];

    return (
        <div>
            {/* <Breadcrumb label={data.label} /> */}
            <div className="main-container">
                <Row className="rowgap-vbox" gutter={[24, 0]}>
                    {transportationCards.map((c, index) => (
                        <Col key={index} xs={24} sm={24} md={12} lg={12} xl={6} className="mb-24">
                            <Card
                                bordered={false}
                                className="criclebox "
                                style={{
                                    backgroundImage: `url(${c.image})`,
                                    backgroundColor: c.background,
                                }}
                            >
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col xs={16}>
                                            <span>{c.title}</span>
                                            <Title level={3}>
                                                <small className={c.size}>
                                                    {c.currency + ' ' + c.size + ' ' + c.unit}
                                                </small>
                                            </Title>
                                        </Col>
                                        <Col xs={8}>
                                            <Title level={3}>
                                                <small className={c.bnb}>{c.percentage}</small>
                                            </Title>
                                        </Col>
                                    </Row>
                                </div>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{ ...spline, series: c.dataSeries }}
                                />
                            </Card>
                        </Col>
                    ))}
                </Row>

                <div className="jv-table">
                    <Row gutter={[16, 24]}>
                        <Col span={24}>
                            <Card
                                bordered={false}
                                className="criclebox tablespace mb-24"
                                title="Transportation Impact"
                                extra={
                                    <>
                                        <h3>Current Month</h3>
                                    </>
                                }
                            >
                                <div className="table-responsive">
                                    <Table
                                        columns={project}
                                        dataSource={dataproject}
                                        pagination={false}
                                        className="ant-border-space"
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default TransportationRate;
