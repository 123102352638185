import { createSlice } from '@reduxjs/toolkit';
import {
    getSalesMetrics,
    getLinegraph,
    getRegionalContribution,
    getEntityContribution,
    getCustomerTypeContribution,
    getRegionalContributionComponentVariance,
    getEntityContributionComponentVariance,
    getCustomerTypeContributionComponentVariance,
} from './salesAnalysis';

const initialState = {
    salesMetrics: {
        isLoading: true,
        isError: false,
        data: [],
    },
    linegraph: {
        isLoading: true,
        isError: false,
        data: [],
        previousData: [],
    },
    regionalContribution: {
        isLoading: true,
        isError: false,
        data: [],
    },
    entityContribution: {
        isLoading: true,
        isError: false,
        data: [],
    },
    customerTypeContribution: {
        isLoading: true,
        isError: false,
        data: [],
    },
    regionalContributionComponentVariance: {
        isLoading: true,
        isError: false,
        data: [],
    },
    entityContributionComponentVariance: {
        isLoading: true,
        isError: false,
        data: [],
    },
    customerTypeContributionComponentVariance: {
        isLoading: true,
        isError: false,
        data: [],
    },
};

const salesAnalysisSlice = createSlice({
    name: 'salesAnalysis',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getSalesMetrics.pending, state => {
                state.salesMetrics.isLoading = true;
            })
            .addCase(getSalesMetrics.fulfilled, (state, action) => {
                state.salesMetrics.isLoading = false;
                state.salesMetrics.data = action.payload;
            })
            .addCase(getSalesMetrics.rejected, (state, action) => {
                state.salesMetrics.isLoading = false;
            })

            .addCase(getLinegraph.pending, state => {
                state.linegraph.isLoading = true;
            })
            .addCase(getLinegraph.fulfilled, (state, action) => {
                state.linegraph.isLoading = false;
                state.linegraph.data = action.payload.current;
                state.linegraph.previousData = action.payload.previous;
            })
            .addCase(getLinegraph.rejected, (state, action) => {
                state.linegraph.isLoading = false;
            })

            .addCase(getRegionalContribution.pending, state => {
                state.regionalContribution.isLoading = true;
            })
            .addCase(getRegionalContribution.fulfilled, (state, action) => {
                state.regionalContribution.isLoading = false;
                state.regionalContribution.data = action.payload;
            })
            .addCase(getRegionalContribution.rejected, (state, action) => {
                state.regionalContribution.isLoading = false;
            })

            .addCase(getEntityContribution.pending, state => {
                state.entityContribution.isLoading = true;
            })
            .addCase(getEntityContribution.fulfilled, (state, action) => {
                state.entityContribution.isLoading = false;
                state.entityContribution.data = action.payload;
            })
            .addCase(getCustomerTypeContribution.pending, state => {
                state.customerTypeContribution.isLoading = true;
            })
            .addCase(getCustomerTypeContribution.fulfilled, (state, action) => {
                state.customerTypeContribution.isLoading = false;
                state.customerTypeContribution.data = action.payload;
            })
            .addCase(getCustomerTypeContribution.rejected, (state, action) => {
                state.customerTypeContribution.isLoading = false;
            })

            .addCase(getRegionalContributionComponentVariance.pending, state => {
                state.regionalContributionComponentVariance.isLoading = true;
            })
            .addCase(getRegionalContributionComponentVariance.fulfilled, (state, action) => {
                state.regionalContributionComponentVariance.isLoading = false;
                state.regionalContributionComponentVariance.data = action.payload;
            })
            .addCase(getRegionalContributionComponentVariance.rejected, (state, action) => {
                state.regionalContributionComponentVariance.isLoading = false;
            })

            .addCase(getEntityContributionComponentVariance.pending, state => {
                state.entityContributionComponentVariance.isLoading = true;
            })
            .addCase(getEntityContributionComponentVariance.fulfilled, (state, action) => {
                state.entityContributionComponentVariance.isLoading = false;
                state.entityContributionComponentVariance.data = action.payload;
            })
            .addCase(getCustomerTypeContributionComponentVariance.pending, state => {
                state.customerTypeContributionComponentVariance.isLoading = true;
            })
            .addCase(getCustomerTypeContributionComponentVariance.fulfilled, (state, action) => {
                state.customerTypeContributionComponentVariance.isLoading = false;
                state.customerTypeContributionComponentVariance.data = action.payload;
            })
            .addCase(getCustomerTypeContributionComponentVariance.rejected, (state, action) => {
                state.customerTypeContributionComponentVariance.isLoading = false;
            });
    },
});

export default salesAnalysisSlice.reducer;
