import img3 from '../assets/images/AroowA.png';
import img1 from '../assets/images/BarB.png';
import img2 from '../assets/images/MechM.png';
import img4 from '../assets/images/ProfitP.png';
import { defaultChartSettings } from './settings';
import { formatNumbers } from './common';
import { disable } from 'workbox-navigation-preload';
export const spline = {
    chart: {
        type: 'spline',
        height: 80,
        margin: [0, 0, 0, -18],
    },
    title: {
        text: null,
    },
    xAxis: {
        visible: false,
        categories: Array.from({ length: 31 }, (v, k) => k + 1),
    },
    yAxis: {
        visible: false,
    },
    credits: {
        enabled: false,
    },
    plotOptions: {
        spline: {
            lineWidth: 2,
            marker: {
                enabled: false,
            },
        },
    },
    tooltip: {
        enabled: true,
        useHTML: true,
        backgroundColor: null,
        style: {
            color: '#000',
        },
    },
    legend: {
        enabled: false,
    },
    marker: {
        enabled: false,
    },
};

export const forexCards = [
    {
        title: 'Net fx Impact',
        currency: 'USD',
        size: 2,
        unit: 'Mn',
        bnb: 'bnb2',
        percentage: '+15%',
        image: img1,
        background: '#DCDDDE',
        dataSeries: [
            {
                name: '',
                data: [0.05, 0.01, 0.03, 0.02, 0.011],
                color: 'rgba(158, 11, 49, 1)',
                shadow: {
                    color: 'rgba(158, 11, 49, 1)',
                    offsetY: 2,
                    opacity: 0.3,
                    width: 3,
                },
            },
        ],
    },
    {
        title: 'COGS fx Impact',
        currency: 'USD',
        size: 0.5,
        unit: 'Mn',
        percentage: '+20%',
        bnb: 'bnb2',
        image: img2,
        background: 'rgba(171, 30, 44, 0.15)',
        dataSeries: [
            {
                name: '',
                data: [0.03, 0.05, 0.05, 0.02, 0.011],
                color: 'rgba(158, 11, 49, 1)',
                shadow: {
                    color: 'rgba(158, 11, 49, 1)',
                    offsetY: 2,
                    opacity: 0.3,
                    width: 3,
                },
            },
        ],
    },
];

export const operationCards = [
    {
        title: 'Export',
        currency: 'USD',
        size: 20,
        unit: 'Mn',
        percentage: '+15%',
        bnb: 'bnb2',
        image: img1,
        background: '#DCDDDE',
        dataSeries: [
            {
                name: '',
                data: [0.05, 0.01, 0.03, 0.02, 0.011],
                color: 'rgba(158, 11, 49, 1)',

                shadow: {
                    color: 'rgba(158, 11, 49, 1)',
                    offsetY: 2,
                    opacity: 0.3,
                    width: 3,
                },
                tooltip: {
                    enabled: true,
                    hideIndex: true,
                },
            },
        ],
    },
    {
        title: 'Import',
        currency: 'USD',
        size: 8,
        unit: 'Mn',
        percentage: '+20%',
        bnb: 'bnb2',
        image: img2,
        background: 'rgba(171, 30, 44, 0.15)',
        dataSeries: [
            {
                name: '',
                data: [0.03, 0.05, 0.05, 0.02, 0.01],
                color: 'rgba(158, 11, 49, 1)',
                shadow: {
                    color: 'rgba(158, 11, 49, 1)',
                    offsetY: 2,
                    opacity: 0.3,
                    width: 3,
                },
            },
        ],
    },
];

export const profitCards = [
    {
        title: 'Gross Profit',
        currency: '50,000',
        size: 'Pound',
        unit: '',
        percentage: '+12%',
        bnb: 'bnb2',
        image: img1,
        background: '#DCDDDE',
        dataSeries: [
            {
                name: '',
                data: [0.05, 0.01, 0.03, 0.02, 0.011],
                color: 'rgba(158, 11, 49, 1)',
                shadow: {
                    color: 'rgba(158, 11, 49, 1)',
                    offsetY: 2,
                    opacity: 0.3,
                    width: 3,
                },
            },
        ],
    },
    {
        title: 'EBITDA',
        currency: 'USD',
        size: 8,
        unit: 'Mn',
        percentage: '-20%',
        bnb: 'redtext',
        image: img2,
        background: 'rgba(171, 30, 44, 0.15)',
        dataSeries: [
            {
                name: '',
                data: [0.03, 0.05, 0.05, 0.02, 0.01],
                color: 'rgba(158, 11, 49, 1)',
                shadow: {
                    color: 'rgba(158, 11, 49, 1)',
                    offsetY: 2,
                    opacity: 0.3,
                    width: 3,
                },
            },
        ],
    },
    {
        title: 'Net Profit',
        currency: 'USD',
        size: 5,
        unit: 'Mn',
        percentage: '+20%',
        bnb: 'bnb2',
        image: img3,
        background: '#E5ECF1',
        dataSeries: [
            {
                name: '',
                data: [0.05, 0.01, 0.03, 0.02, 0.011],
                color: 'rgba(158, 11, 49, 1)',
                shadow: {
                    color: 'rgba(158, 11, 49, 1)',
                    offsetY: 2,
                    opacity: 0.3,
                    width: 3,
                },
            },
        ],
    },
    {
        title: 'Brokerage',
        currency: 'USD',
        size: 40,
        unit: 'Mn',
        percentage: '+10%',
        bnb: 'bnb2',
        image: img4,
        background: '#DCDDDE',
        dataSeries: [
            {
                name: '',
                data: [0.03, 0.05, 0.05, 0.02, 0.01],
                color: 'rgba(158, 11, 49, 1)',
                shadow: {
                    color: 'rgba(158, 11, 49, 1)',
                    offsetY: 2,
                    opacity: 0.3,
                    width: 3,
                },
            },
        ],
    },
];

export const salesAnalysisCards = [
    {
        title: 'Gross Profit',
        currency: '50,000',
        size: 'Pound',
        unit: '',
        percentage: '-12%',
        bnb: 'redtext',
        image: img1,
        background: '#DCDDDE',
        dataSeries: [
            {
                name: '',
                data: [0.05, 0.01, 0.03, 0.02, 0.011],
                color: 'rgba(158, 11, 49, 1)',
                shadow: {
                    color: 'rgba(158, 11, 49, 1)',
                    offsetY: 2,
                    opacity: 0.3,
                    width: 3,
                },
            },
        ],
    },
    {
        title: 'EBITDA',
        currency: 'USD',
        size: 8,
        unit: 'Mn',
        percentage: '+20%',
        bnb: 'bnb2',
        image: img2,
        background: 'rgba(171, 30, 44, 0.15)',
        dataSeries: [
            {
                name: '',
                data: [0.03, 0.05, 0.05, 0.02, 0.01],
                color: 'rgba(158, 11, 49, 1)',
                shadow: {
                    color: 'rgba(158, 11, 49, 1)',
                    offsetY: 2,
                    opacity: 0.3,
                    width: 3,
                },
            },
        ],
    },
    {
        title: 'Net Profit',
        currency: 'USD',
        size: 5,
        unit: 'Mn',
        percentage: '+20%',
        bnb: 'bnb2',
        image: img3,
        background: '#E5ECF1',
        dataSeries: [
            {
                name: '',
                data: [0.05, 0.01, 0.03, 0.02, 0.011],
                color: 'rgba(158, 11, 49, 1)',
                shadow: {
                    color: 'rgba(158, 11, 49, 1)',
                    offsetY: 2,
                    opacity: 0.3,
                    width: 3,
                },
            },
        ],
    },
    {
        title: 'Brokerage',
        currency: 'USD',
        size: 40,
        unit: 'Mn',
        percentage: '+10%',
        bnb: 'bnb2',
        image: img4,
        background: '#DCDDDE',
        dataSeries: [
            {
                name: '',
                data: [0.03, 0.05, 0.05, 0.02, 0.01],
                color: 'rgba(158, 11, 49, 1)',
                shadow: {
                    color: 'rgba(158, 11, 49, 1)',
                    offsetY: 2,
                    opacity: 0.3,
                    width: 3,
                },
            },
        ],
    },
];

export const transportationCards = [
    {
        title: 'Planned vs Actual',
        currency: 'USD',
        size: 0.7,
        unit: 'Mn',
        percentage: '+12%',
        bnb: 'bnb2',
        image: img1,
        background: '#DCDDDE',
        dataSeries: [
            {
                name: '',
                data: [0.05, 0.01, 0.03, 0.02, 0.011],
                color: 'rgba(158, 11, 49, 1)',

                shadow: {
                    color: 'rgba(158, 11, 49, 1)',
                    offsetY: 2,
                    opacity: 0.3,
                    width: 3,
                },
                tooltip: {
                    enabled: true,
                    hideIndex: true,
                },
            },
        ],
    },
    {
        title: 'Import - Delay Impact',
        currency: 'USD',
        size: 0.6,
        unit: 'Mn',
        percentage: '+11%',
        bnb: 'bnb2',
        image: img2,
        background: 'rgba(171, 30, 44, 0.15)',
        dataSeries: [
            {
                name: '',
                data: [0.05, 0.01, 0.03, 0.02, 0.011],
                color: 'rgba(158, 11, 49, 1)',

                shadow: {
                    color: 'rgba(158, 11, 49, 1)',
                    offsetY: 2,
                    opacity: 0.3,
                    width: 3,
                },
                tooltip: {
                    enabled: true,
                    hideIndex: true,
                },
            },
        ],
    },
    {
        title: 'Export - Delay Impact',
        currency: 'USD',
        size: 0.2,
        unit: 'Mn',
        percentage: '+11%',
        bnb: 'bnb2',
        image: img3,
        background: '#E5ECF1',
        dataSeries: [
            {
                name: '',
                data: [0.05, 0.01, 0.03, 0.02, 0.011],
                color: 'rgba(158, 11, 49, 1)',

                shadow: {
                    color: 'rgba(158, 11, 49, 1)',
                    offsetY: 2,
                    opacity: 0.3,
                    width: 3,
                },
                tooltip: {
                    enabled: true,
                    hideIndex: true,
                },
            },
        ],
    },
];

export const columnChart = {
    chart: {
        styledMode: true,
        height: 350,
    },
    title: {
        text: null,
        align: 'left',
    },
    xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    },
    yAxis: {
        title: {
            text: 'Sales in USD Mn',
        },
        show: true,
    },
    credits: {
        enabled: false,
    },
    tooltip: {
        valueSuffix: ' Million',
    },
    plotOptions: {
        line: {
            lineWidth: 2,

            marker: {
                enabled: false,
            },
        },
        series: {
            borderRadiusTopLeft: '60%',
            borderRadiusTopRight: '60%',
        },
    },
    legend: {
        enabled: false,
    },
    series: [
        {
            type: 'column',
            name: 'Current',
            data: [11, 13, 16, 18.5, 16, 17, 15, 18.5, 20, 17, 15.3, 15],
            zIndex: 1,
        },
        {
            type: 'line',
            name: 'Target',
            data: [10, 12, 13, 15.5, 16, 17, 16, 19.5, 20, 17, 15.3, 15],
            zIndex: 0,

            marker: {
                enabled: false,
            },
        },
    ],
};

export const setupChartDataSizeWithPercent = (data, dataInPercent, cardTitle) => {
    const combinedData = [];
    for (let i = 0; i < data.length; i++) {
        if (cardTitle === 'Total COGS') {
            combinedData.push([dataInPercent[i], data[i]]);
        } else {
            combinedData.push([data[i].toString(), parseFloat(dataInPercent[i])]);
        }
    }
    return combinedData;
};

export const setupLineChartConfiguration = (chartData, previousChart, compareTo) => {
    const { overviews, data } = chartData;
    const { overviews: previousOverviews, data: previousData } = previousChart;

    if (compareTo === 'none') {
        return {
            ...defaultChartSettings,
            title: { ...defaultChartSettings.title, align: 'left' },
            xAxis: { ...defaultChartSettings.xAxis, categories: overviews?.[0]?.xAxis?.label },
            yAxis: {
                ...defaultChartSettings.yAxis,
                title: { ...defaultChartSettings.yAxis.title, text: overviews?.[0]?.yAxis?.label },
                labels: {
                    formatter: function () {
                        return formatNumbers(this.value);
                    },
                },
            },
            tooltip: {
                formatter: function () {
                    const seriesNameCurrent = overviews?.[0]?.compareTo?.current;
                    return `<b>${this.x}</b><br/><br/><span>${seriesNameCurrent}</span>: <b>${
                        formatNumbers(this.y) || '0 Mn'
                    }</b>`;
                },
                // ... other tooltip settings
            },
            plotOptions: {
                column: {
                    stacking: 'overlap',
                },
                // ... other plot options
            },
            legend: {
                enabled: false,
            },
            series: [
                {
                    type: 'column',
                    name: overviews?.[0]?.current,
                    data: data?.[0]?.current,
                    zIndex: 1,
                    pointWidth: 30,
                },
            ],
        };
    }

    const newPreviousData = previousData?.[0]?.current?.slice(0, data?.[0]?.current?.length) ?? [];

    return {
        ...defaultChartSettings,
        title: { ...defaultChartSettings.title, align: 'left' },
        xAxis: { ...defaultChartSettings.xAxis, categories: overviews?.[0]?.xAxis?.label },
        yAxis: {
            ...defaultChartSettings.yAxis,
            title: { ...defaultChartSettings.yAxis.title, text: overviews?.[0]?.yAxis?.label },
            labels: {
                formatter: function () {
                    return formatNumbers(this.value);
                },
            },
        },
        tooltip: {
            formatter: function () {
                const seriesNameCurrent = overviews?.[0]?.compareTo?.current;
                const seriesNameTarget = previousOverviews?.[0]?.compareTo?.previous;
                const seriesName = this.series.name === seriesNameCurrent ? seriesNameCurrent : seriesNameTarget;
                let xLabel = this.x;
                if (seriesName === 'Previous Period') {
                    const prevLabels = previousOverviews?.[0]?.xAxis?.label[this.point.index];
                    xLabel = prevLabels;
                }

                return `<b>${xLabel}</b><br/><br/><span>${seriesName}</span>: <b>${
                    formatNumbers(this.y) || '0 Mn'
                }</b>`;
            },
            // ... other tooltip settings
        },
        plotOptions: {},
        legend: {
            enabled: true,
            align: 'right',
            verticalAlign: 'top',
            layout: 'horizontal',
            x: -30,
            y: -15,
        },
        series: [
            {
                type: 'column',
                name: overviews?.[0]?.compareTo?.current,
                data: data?.[0]?.current,
                // zIndex: 1,
                pointWidth: 40,
            },
            {
                type: 'column',
                name: overviews?.[0]?.compareTo?.previous,
                data: newPreviousData,
                pointWidth: 40,
                className: 'custom-series-second',
            },
        ],
    };
};
