import { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
    getSalesMetrics,
    getLinegraph,
    getEntityContribution,
    getCustomerTypeContribution,
    getRegionalContribution,
    getEntityContributionComponentVariance,
    getCustomerTypeContributionComponentVariance,
    getRegionalContributionComponentVariance,
} from '../../features/salesAnalysis/salesAnalysis';
import { defaultChartSettings } from '../../helpers/settings';
import { formatNumbers } from '../../helpers/common';

const useSalesAnalysis = () => {
    const dispatch = useDispatch();
    const { apiPayload, previousPeriodDate, forceRender } = useSelector(state => state.filter);
    const { fromDate, toDate, geoLocationID, customerTypeID, entityID, compareTo } = apiPayload;

    const {
        salesMetrics,
        linegraph,
        regionalContribution,
        entityContribution,
        customerTypeContribution,
        regionalContributionComponentVariance,
        entityContributionComponentVariance,
        customerTypeContributionComponentVariance,
    } = useSelector(state => state.salesAnalysis);

    const {
        isLoading: linegraphLoading,
        data: { overviews, data },
        previousData: { overviews: previousOverviews, data: previousData },
    } = linegraph;

    useEffect(() => {
        if (!fromDate && !toDate && !geoLocationID && !customerTypeID && !entityID) return;

        const payload = {
            fromDate,
            toDate,
            geoLocationID,
            entityID,
            customerTypeID,
        };

        dispatch(getSalesMetrics(payload));
        dispatch(getLinegraph({ payload, previous: { ...payload, ...previousPeriodDate }, compareTo: 'previous' }));
        dispatch(getRegionalContribution(payload));
        dispatch(getEntityContribution(payload));
        dispatch(getCustomerTypeContribution(payload));
        dispatch(getRegionalContributionComponentVariance(payload));
        dispatch(getEntityContributionComponentVariance(payload));
        dispatch(getCustomerTypeContributionComponentVariance(payload));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, fromDate, toDate, geoLocationID, customerTypeID, entityID]);

    const updatedChartSettings = useMemo(() => {
        if (!overviews) return defaultChartSettings;
        if (compareTo === 'none') {
            return {
                ...defaultChartSettings,
                chart: { ...defaultChartSettings.chart, type: 'line' },
                title: { ...defaultChartSettings.title, align: 'left' },
                xAxis: { ...defaultChartSettings.xAxis, categories: overviews?.[0]?.xAxis?.label },
                yAxis: {
                    ...defaultChartSettings.yAxis,
                    title: { ...defaultChartSettings.yAxis.title, text: overviews?.[0]?.yAxis?.label },
                },
                tooltip: {
                    formatter: function () {
                        const seriesNameCurrent = overviews?.[0]?.current;
                        const seriesNameTarget = overviews?.[0]?.target;
                        const seriesName =
                            this.series.name === seriesNameCurrent ? seriesNameCurrent : seriesNameTarget;
                        return `<b>${this.x}</b><br/><br/>
                            <span>${seriesName}</span>: <b>${formatNumbers(this.y || 0, false)}%</b>`;
                    },
                    // ... other tooltip settings
                },
                plotOptions: {
                    line: {
                        lineWidth: 2,
                        marker: {
                            enabled: true,
                        },
                    },
                },
                series: [
                    {
                        type: 'line',
                        name: overviews?.[0]?.current,
                        data: data?.[0]?.current,
                    },
                    // {
                    //     type: 'line',
                    //     name: overviews?.[0]?.target,
                    //     data: data?.[0]?.baseline,
                    // },
                ],
            };
        }

        return {
            ...defaultChartSettings,
            title: { ...defaultChartSettings.title, align: 'left', verticalAlign: 'bottom', y: -10 },
            xAxis: { ...defaultChartSettings.xAxis, categories: overviews?.[0]?.xAxis?.label },
            yAxis: {
                ...defaultChartSettings.yAxis,
                title: { ...defaultChartSettings.yAxis.title, text: overviews?.[0]?.yAxis?.label },
                labels: {
                    formatter: function () {
                        return formatNumbers(this.value);
                    },
                },
            },
            tooltip: {
                formatter: function () {
                    const seriesNameCurrent = overviews?.[0]?.compareTo?.current;
                    const seriesNameTarget = overviews?.[0]?.compareTo?.previous;
                    const seriesName = this.series.name === seriesNameCurrent ? seriesNameCurrent : seriesNameTarget;
                    let xLabel = this.x;

                    if (seriesName === 'Previous Period') {
                        const prevLabels = previousOverviews?.[0]?.xAxis?.label[this.point.index];
                        xLabel = prevLabels;
                    }

                    return `<b>${xLabel}</b><br/><br/><span>${seriesName}</span>: <b>${
                        formatNumbers(this.y) || '0 Mn'
                    }</b>`;
                },
                // ... other tooltip settings
            },
            plotOptions: {
                series: { borderRadiusTopLeft: '60%', borderRadiusTopRight: '60%' },
                // lang: { numericSymbols: [] },
                // ... other plot options
            },
            legend: {
                enabled: true,
                align: 'right',
                verticalAlign: 'top',
                layout: 'horizontal',
                x: -30,
                y: -20,
            },
            series: [
                {
                    type: 'line',
                    name: overviews?.[0]?.compareTo?.current,
                    data: data?.[0]?.current,
                    // zIndex: 1,
                    // pointWidth: 30,
                    // lang: { numericSymbols: '' },
                },
                {
                    type: 'line',
                    name: overviews?.[0]?.compareTo?.previous,
                    data: previousData?.[0]?.current || [],
                    // zIndex: 0,
                    // marker: {
                    //     enabled: false,
                    // },
                },
            ],
        };
    }, [overviews, compareTo, data, previousData, previousOverviews]);

    return {
        forceRender,
        compareTo,
        salesMetrics,
        linegraph: {
            overviews,
            updatedChartSettings,
            linegraphLoading,
        },
        regionalContribution,
        entityContribution,
        customerTypeContribution,
        regionalContributionComponentVariance,
        entityContributionComponentVariance,
        customerTypeContributionComponentVariance,
    };
};

export default useSalesAnalysis;
