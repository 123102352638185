import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
    KeyOutlined,
    PieChartOutlined,
    RadarChartOutlined,
    LogoutOutlined,
    BookOutlined,
    AuditOutlined,
} from '@ant-design/icons';
import { Menu, Row, Col } from 'antd';

import { navigations } from '../../constants/navigations';
import { pathName } from '../../constants/routes';
import { useAuth } from '../../context/authContext';
import logo from '../../assets/images/logo-jv.png';
import './sidebar.scss';

const Sidenav = () => {
    const { logout } = useAuth();
    const { pathname, search } = useLocation();
    const [selectedKey, setSelectedKey] = useState('');

    useEffect(() => {
        const matchingNavigation = navigations.find(navigation => navigation.path === pathname.slice(1));

        if (matchingNavigation) {
            setSelectedKey([matchingNavigation.path]);
        }
    }, [pathname]);

    const items = [
        {
            key: pathName.keyMetrics,
            label: <NavLink to={{ pathname: pathName.keyMetrics, search }}>Key Metrics</NavLink>,
            icon: <KeyOutlined />,
        },
        {
            key: pathName.summary,
            label: <NavLink to={{ pathname: pathName.summary, search }}>Summary</NavLink>,
            icon: <AuditOutlined />,
        },
        {
            key: pathName.salesAnalysis,
            label: <NavLink to={{ pathname: pathName.salesAnalysis, search }}>Sales Analysis</NavLink>,
            icon: <PieChartOutlined />,
        },
        {
            key: pathName.forexImpact,
            label: <NavLink to={{ pathname: pathName.forexImpact, search }}>Forex Impact</NavLink>,
            icon: <RadarChartOutlined />,
        },
        {
            key: pathName.glossary,
            label: <NavLink to={{ pathname: pathName.glossary, search }}>Glossary</NavLink>,
            icon: <BookOutlined />,
        },
        {
            key: pathName.login,
            label: (
                <NavLink onClick={logout} to={pathName.login}>
                    Log Out
                </NavLink>
            ),
            icon: <LogoutOutlined />,
        },
    ];

    return (
        <div className="jv-sidebar">
            <Row gutter={[8, 16]} align="middle" className="jv-sidebar__top">
                <Col span={24} className="jv-sidebar__logo">
                    <img src={logo} />
                </Col>
            </Row>
            <Row gutter={[8, 16]}>
                <Col span={24}>
                    <Menu mode="inline" selectedKeys={selectedKey} items={items} />
                </Col>
            </Row>
        </div>
    );
};

export default Sidenav;
