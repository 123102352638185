import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getExportDelivery,
    getImportDelivery,
    getProfitMetrics,
} from '../../features/operationAnalysis/operationAnalysisApi';
const useProfitAnalysis = () => {
    const dispatch = useDispatch();
    const { metrics, exportDelivery, importDelivery } = useSelector(state => state.operationAnalysis);
    const { apiPayload } = useSelector(state => state.filter);
    const { fromDate, toDate, geoLocationID, customerTypeID, entityID } = apiPayload;

    useEffect(() => {
        if (!fromDate && !toDate && !geoLocationID && !customerTypeID && !entityID) return;

        const payload = {
            fromDate,
            toDate,
            geoLocationID,
            entityID,
            customerTypeID,
        };

        dispatch(getProfitMetrics(payload));
        dispatch(getExportDelivery(payload));
        dispatch(getImportDelivery(payload));
    }, [dispatch, fromDate, toDate, geoLocationID, customerTypeID, entityID]);

    return {
        metrics,
        exportDelivery,
        importDelivery,
    };
};

export default useProfitAnalysis;
