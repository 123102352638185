import axios from 'axios';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFirstAndLast30Days } from '../helpers/dateformate';
import { Path, baseUrl } from '../service/path';
const AuthContext = createContext();

/**
 * Provides authentication context for the app.
 * Manages user authentication state using cookies.
 *
 * @component
 * @example
 * // Wrap your app with AuthProvider in your index.js or App.js.
 * <AuthProvider>
 *   <App />
 * </AuthProvider>
 *
 * @param {object} props - Component properties.
 * @param {ReactNode} props.children - The child elements to be wrapped by the provider.
 * @returns {ReactNode} The wrapped components with authentication context.
 */
export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const [user, setUser, removeUser] = useCookies(['token']);
    const [ name, setName ] = useCookies( ['firstName'] );
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState({
        isError: false,
        message: '',
    });

    /**
     * Authenticate the user and set the token cookie and navigate to dashboard.
     *
     * @param {object} credential - User authentication credential.
     * @param {string} credential.username - The user's username.
     */
    const login = useCallback(
        async credential => {
            if (!credential.password && !credential.username) {
                return;
            }

            try {
                setIsLoading(true);
                setStatus({
                    isError: false,
                    message: '',
                });

                const res = await axios.post(`${baseUrl}${Path.login}`, {
                    username: credential.username,
                    password: credential.password,
                });

                const data = await res.data;

                if (data.isSuccess) {
                    const expirationDate = new Date();
                    expirationDate.setDate(expirationDate.getDate() + 7);

                    setUser('token', data.loginToken, { expires: expirationDate });
                    setName('firstName', data.firstName || '', { expires: expirationDate } );
                    setIsLoading(false);
                    setStatus({
                        isError: false,
                        message: '',
                    });
                    const { currentDate, last30Days } = getFirstAndLast30Days();
                    navigate({
                        pathname: 'key-metrics',
                        search,
                    });
                } else {
                    setIsLoading(false);
                    setStatus({
                        isError: true,
                        message: data.errorOnFailure,
                    });
                }
            } catch (error) {
                setIsLoading(false);
                setStatus({
                    isError: true,
                    message: 'Some error occurred',
                });
            }
        },
        [setUser, navigate],
    );

    /**
     * Sign out the logged-in user by removing the token cookie.
     */
    const logout = useCallback(() => {
        removeUser('token');
        navigate('/');
    }, [navigate, removeUser]);

    const getName = useCallback( () => {
        return name?.firstName || 'lo';
    }, [ name ] );

    const value = useMemo(
        () => ({
            user,
            getName,
            login,
            logout,
            search,
            isLoading,
            status,
        }),
        [user, getName, login, logout, search, isLoading, status],
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

/**
 * A custom hook to access the authentication context.
 *
 * @function
 * @example
 * // Use this hook in any component to access authentication functions.
 * const { user, login, logout, search, isLoading, status } = useAuth();
 *
 * @returns {object} An object containing user authentication data and functions.
 * @returns {string} returns.user - The user authentication data.
 * @returns {function} returns.login - Function to authenticate the user.
 * @returns {function} returns.logout - Function to sign out the user.
 * @returns {string} returns.search - The search query from url.
 * @returns {string} returns.isLoading - The loading status.
 * @returns {string} returns.status - The status of the url.
 */
export const useAuth = () => {
    return useContext(AuthContext);
};
