import ReCAPTCHA from 'react-google-recaptcha'
import { UnlockOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Form, Input, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '../../assets/icon';
import loginImage from '../../assets/icon/bg-img.png';
import { useAuth } from '../../context/authContext';
import './login.scss';

const formRule = {
    username: [{ required: true, message: 'Please input E-mail!' }],
    password: [{ required: true, message: 'Please input Password!' }],
};

const Login = () => {
    const navigate = useNavigate();

    const isMounted = useRef(false);
    const recaptcha = useRef();

    const { login, search, user, isLoading, status } = useAuth();

    const onFinish = values => {
        const captchaValue = recaptcha.current.getValue();

        if ( ! captchaValue ) {
            alert('Please verify the reCAPTCHA!');
            return;
        }

        login(values);
    };

    const onFinishFailed = errorInfo => {
        // eslint-disable-next-line no-console
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if (user.token && !isMounted.current) {
            navigate({ pathname: 'key-metrics', search });
        }

        isMounted.current = true;
    }, [user, navigate, search]);

    return (
        <div className="scroll-main">
            <div className="jv-login" style={{ backgroundImage: `url(${ loginImage })` }}>
                <Row className="container">
                    <Col span={24} className="jv-login__content">
                        <Row align="middle">
                            <Col>
                                <Row gutter={ [ 16, 16 ] } className="jv-login__form">
                                    <Card>
                                        <div className="jv-login__header">
                                            <Icon icon="logo" />
                                        </div>
                                        { status.isError && <Alert message={ status.message } type="error" /> }
                                        <Form
                                            name="basic"
                                            labelCol={ { span: 24 } }
                                            wrapperCol={ { span: 24 } }
                                            style={ { maxWidth: 600 } }
                                            initialValues={ { remember: true } }
                                            onFinish={ onFinish }
                                            onFinishFailed={ onFinishFailed }
                                            autoComplete="on"
                                        >
                                            <Form.Item name="username" rules={ formRule.username }>
                                                <Input placeholder="Email ID" />
                                            </Form.Item>
                                            <Form.Item name="password" rules={ formRule.password }>
                                                <Input.Password placeholder="Password" />
                                            </Form.Item>
                                            <ReCAPTCHA ref={ recaptcha } sitekey={ process.env.REACT_APP_CAPTCHA_SITE_KEY } />
                                            <Form.Item style={ { marginTop: '20px' } }>
                                                <Button
                                                    className="ant-btn-primary--mod"
                                                    type="primary"
                                                    htmlType="submit"
                                                    disabled={ isLoading }
                                                    loading={ isLoading }
                                                    icon={ <UnlockOutlined />}
                                                >
                                                    Lets see those numbers!!
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Card>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Login;
