import React from 'react';
import { Table } from 'antd';
import { transformData, getSummaryColumns } from '../../helpers/summary';

const SummaryTable = ({ tabsData, colors }) => {
    return (
        <div className="jv-table ant-card">
            <div style={{ overflowX: 'auto' }}>
                <div style={{ display: 'flex' }}>
                    <Table
                        columns={getSummaryColumns(tabsData?.regionalsummary_netsales)}
                        dataSource={transformData(tabsData?.regionalsummary_netsales?.summaryData)}
                        className={`custom-table ant-card ant-border-space summary-table__summary summary-table__${colors.netSales}`}
                        pagination={false}
                        style={{ marginBottom: '20px' }}
                    />
                    <Table
                        columns={getSummaryColumns(tabsData?.regionalsummary_costofsales)}
                        dataSource={transformData(tabsData?.regionalsummary_costofsales?.summaryData)}
                        className={`custom-table ant-card ant-border-space summary-table__summary summary-table__${colors.costOfSales}`}
                        pagination={false}
                        style={{ marginBottom: '20px' }}
                    />
                    <Table
                        columns={getSummaryColumns(tabsData?.regionalsummary_operatingprofit)}
                        dataSource={transformData(tabsData?.regionalsummary_operatingprofit?.summaryData)}
                        className={`custom-table ant-card ant-border-space summary-table__summary summary-table__${colors.operatingProfit}`}
                        pagination={false}
                        style={{ marginBottom: '20px' }}
                    />
                </div>
            </div>
        </div>
    );
};

export default SummaryTable;
