import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getBusinessType,
    getContribution,
    getEntityContribution,
    getKeyMetrics,
    getSalesTrending,
    getVolumeTrending,
    getCustomergroupComponentVariance,
    getEntityContributionComponentVariance,
    getRegionalContributionComponentVariance,
} from '../../features/Keymetrics/keymetrcisAPI';
import { defaultChartSettings } from '../../helpers/settings';
import { formatNumbers } from '../../helpers/common';

const useKeyMetrics = () => {
    const dispatch = useDispatch();
    const { apiPayload, previousPeriodDate, forceRender } = useSelector(state => state.filter);
    const { fromDate, toDate, geoLocationID, customerTypeID, entityID, compareTo } = apiPayload;

    const {
        keyMetricsCards,
        keyMetricsSalesTrending,
        keyMetricsVolumeTrending,
        keyMetricsContribution,
        keyMetricsBusinessType,
        keyMetricsEntityContribution,
        keyRegionalContributionComponentVariance,
        keyEntityContributionComponentVariance,
        keyCustomergroupComponentVariance,
    } = useSelector(state => state.keyMetricsSlice);

    const {
        isLoading: salesTrendingLoading,
        salesTrendingData: { overviews, data },
        salePreviousData: { overviews: previousOverviews, data: previousData },
    } = keyMetricsSalesTrending;

    useEffect(() => {
        // We deconstructing and using the data because object as dependency causes api to call multiple time
        if (!fromDate && !toDate && !geoLocationID && !customerTypeID && !entityID) return;
        const payload = {
            fromDate,
            toDate,
            geoLocationID,
            entityID,
            customerTypeID,
        };

        dispatch(getKeyMetrics(payload));
        dispatch(getEntityContribution(payload));
        dispatch(getSalesTrending({ payload, previous: { ...payload, ...previousPeriodDate }, compareTo: 'previous' }));

        dispatch(
            getVolumeTrending({ payload, previous: { ...payload, ...previousPeriodDate }, compareTo: 'previous' }),
        );
        dispatch(getContribution(payload));
        dispatch(getBusinessType(payload));
        dispatch(getCustomergroupComponentVariance(payload));
        dispatch(getEntityContributionComponentVariance(payload));
        dispatch(getRegionalContributionComponentVariance(payload));
        // We dnt want previousPeriodDate to be dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, fromDate, toDate, geoLocationID, customerTypeID, entityID]);

    const updatedChartSettings = useMemo(() => {
        if (!overviews) return defaultChartSettings;

        if (compareTo === 'none') {
            return {
                ...defaultChartSettings,
                title: { ...defaultChartSettings.title, align: 'left' },
                xAxis: { ...defaultChartSettings.xAxis, categories: overviews?.[0]?.xAxis?.label },
                yAxis: {
                    ...defaultChartSettings.yAxis,
                    title: { ...defaultChartSettings.yAxis.title, text: overviews?.[0]?.yAxis?.label },
                    labels: {
                        formatter: function () {
                            return formatNumbers(this.value);
                        },
                    },
                },
                tooltip: {
                    formatter: function () {
                        const seriesNameCurrent = overviews?.[0]?.compareTo?.current;
                        const seriesNameTarget = previousOverviews?.[0]?.target;
                        const seriesName =
                            this.series.name === seriesNameCurrent ? seriesNameCurrent : seriesNameTarget;
                        console.log(seriesNameTarget);
                        console.log(this.x);
                        return `<b>${this.x}</b><br/><br/><span>${seriesName}</span>: <b>${
                            formatNumbers(this.y) || '0 Mn'
                        }</b>`;
                    },
                    // ... other tooltip settings
                },
                plotOptions: {
                    series: { borderRadiusTopLeft: '60%', borderRadiusTopRight: '60%' },
                    // lang: { numericSymbols: [] },
                    // ... other plot options
                },
                series: [
                    {
                        type: 'line',
                        name: overviews?.[0]?.compareTo?.current,
                        data: data?.[0]?.current,
                        zIndex: 1,
                        pointWidth: 30,
                        // lang: { numericSymbols: '' },
                    },
                    // {
                    //     type: 'line',
                    //     name: overviews?.[0]?.target,
                    //     data: data?.[0]?.baseline,
                    //     zIndex: 0,
                    //     marker: {
                    //         enabled: false,
                    //     },
                    // },
                ],
            };
        }

        return {
            ...defaultChartSettings,
            title: { ...defaultChartSettings.title, align: 'left', verticalAlign: 'bottom', y: -10 },
            xAxis: { ...defaultChartSettings.xAxis, categories: overviews?.[0]?.xAxis?.label },
            yAxis: {
                ...defaultChartSettings.yAxis,
                title: { ...defaultChartSettings.yAxis.title, text: overviews?.[0]?.yAxis?.label },
                labels: {
                    formatter: function () {
                        return formatNumbers(this.value);
                    },
                },
            },
            tooltip: {
                formatter: function () {
                    const seriesNameCurrent = overviews?.[0]?.compareTo?.current;
                    const seriesNameTarget = overviews?.[0]?.compareTo?.previous;

                    const seriesName = this.series.name === seriesNameCurrent ? seriesNameCurrent : seriesNameTarget;

                    let xLabel = this.x;
                    if (seriesName === 'Previous Period') {
                        const prevLabels = previousOverviews?.[0]?.xAxis?.label[this.point.index];
                        xLabel = prevLabels;
                    }

                    return `<b>${xLabel}</b><br/><br/><span>${seriesName}</span>: <b>${
                        formatNumbers(this.y) || '0 Mn'
                    }</b>`;
                },
                // ... other tooltip settings
            },
            plotOptions: {
                series: { borderRadiusTopLeft: '60%', borderRadiusTopRight: '60%' },
                // lang: { numericSymbols: [] },
                // ... other plot options
            },
            legend: {
                enabled: true,
                align: 'right',
                verticalAlign: 'top',
                layout: 'horizontal',
                x: -30,
                y: -20,
            },
            series: [
                {
                    type: 'line',
                    name: overviews?.[0]?.compareTo?.current,
                    data: data?.[0]?.current,
                    // zIndex: 1,
                    // pointWidth: 30,
                    // lang: { numericSymbols: '' },
                },
                {
                    type: 'line',
                    name: previousOverviews?.[0]?.compareTo?.previous,
                    data: previousData?.[0]?.current || [],
                    className: 'custom-series-second',
                },
            ],
        };
    }, [overviews, compareTo, data, previousOverviews, previousData]);

    return {
        forceRender,
        compareTo,
        keyMetricsCards,
        keyMetricsSalesTrending: {
            overviews,
            data,
            updatedChartSettings,
            salesTrendingLoading,
            previousOverviews,
            previousData,
        },
        keyMetricsVolumeTrending,
        keyMetricsContribution,
        keyMetricsBusinessType,
        keyMetricsEntityContribution,
        keyRegionalContributionComponentVariance,
        keyEntityContributionComponentVariance,
        keyCustomergroupComponentVariance,
    };
};

export default useKeyMetrics;
