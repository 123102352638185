/**
 * Transforms an array of objects containing geography data into an array of option objects.
 *
 * @param {Array} filterValue - The input array of geography objects.
 * @returns {Array} An array of option objects with 'label' and 'value' properties.
 */
export const getGeographyOptions = filterValue => {
    return filterValue.map(item => ({ label: item?.geoLocationName, value: item?.geoLocationID }));
};

/**
 * Transforms an array of objects containing entity data into an array of option objects.
 *
 * @param {Array} filterValue - The input array of entity objects.
 * @returns {Array} An array of option objects with 'label' and 'value' properties.
 */
export const getEntitiesOption = filterValue =>
    filterValue.map(item => ({
        ...item,
        entities: item.entities.map(entity => ({
            label: entity.entity,
            value: entity.entityID,
        })),
    }));

/**
 * Transforms an array of objects containing geography data into an array of option objects.
 *
 * @param {Array} filterValue - The input array of geography objects.
 * @returns {Array} An array of option objects with 'label' and 'value' properties.
 */
export const getCustomerType = filterValue => {
    return filterValue.map(item => ({ label: item?.customerType, value: item?.customerTypeID }));
};
