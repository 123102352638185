import React from 'react';

const CurrentPageName = () => {
    const url = window?.location?.href;
    const host = window?.location?.origin;

    if (!url || !host) {
        return '';
    }

    const finalPath = url.replace(host, '');
    const parts = finalPath.split('/').filter(part => part !== '');

    const breadcrumArray = parts.map((part, index) => {
        // Extract the first word and capitalize it
        const name = part
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        const breadcrumb = {
            name: name.split('?')[0],
            url: window?.location?.href + '/' + parts.slice(0, index + 1).join('/'),
        };

        return breadcrumb;
    });

    return breadcrumArray.map((breadcrumb, index) => {
        // Add separator unless it's the first item
        const separator = index > 0 ? ' | ' : '';

        // Use h1 for first item, h3 for others
        const Tag = index === 0 ? 'h1' : 'h3';

        return (
            <React.Fragment key={index}>
                {separator}
                <Tag>{breadcrumb.name}</Tag>
            </React.Fragment>
        );
    });
};
export default CurrentPageName;
