import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../service/axiosInstance';
import { profitAnalysis } from '../../service/path';

export const getProfitAnalysis = createAsyncThunk('profitAnalysis/getProfitAnalysis', async (payload, thunkAPI) => {
    try {
        const resp = await axios.post(profitAnalysis.metrics, payload);
        return resp.data.profitCenterMetrix;
    } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
    }
});

export const getProfitabilityTracker = createAsyncThunk(
    'profitAnalysis/getProfitabilityTracker',
    async (payload, thunkAPI) => {
        try {
            const resp = await axios.post(profitAnalysis.profitabilityTracker, payload);
            return resp.data.profitTrackers;
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    },
);
