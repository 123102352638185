import React from 'react';

import './index.scss';

const InfoIcon = props => {
    return (
        <span className="anticon jv-icon-info">
            <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="1.25em"
                height="1.25em"
                viewBox="80 -20 150 180"
                preserveAspectRatio="xMidYMid meet">
                <metadata>Created by potrace 1.10, written by Peter Selinger 2001-2011</metadata>
                <g transform="translate(0.000000,164.000000) scale(0.050000,-0.050000)" fill="#000000" stroke="none">
                    <path
                        d="M2700 2949 c-628 -135 -1065 -721 -1035 -1386 52 -1152 1454 -1697
2274 -883 957 952 83 2553 -1239 2269z m599 -242 c709 -197 1039 -1048 644
-1661 -414 -639 -1312 -702 -1793 -125 -698 834 101 2076 1149 1786z"
                    />
                    <path
                        d="M2911 2456 c-26 -38 -31 -127 -31 -566 0 -578 6 -610 120 -610 114 0
120 32 120 610 0 578 -6 610 -120 610 -37 0 -69 -16 -89 -44z"
                    />
                    <path
                        d="M2893 1123 c-118 -130 83 -325 204 -197 51 55 55 127 12 190 -40 57
-167 61 -216 7z"
                    />
                    <path
                        d="M1655 2875 c-550 -591 -635 -1541 -198 -2215 153 -236 254 -340 331
-340 148 0 152 112 11 274 -535 610 -512 1624 49 2144 92 86 54 222 -63 222
-35 0 -77 -27 -130 -85z"
                    />
                    <path
                        d="M4133 2923 c-48 -53 -41 -129 19 -185 563 -523 583 -1545 40 -2155
-132 -150 -124 -263 20 -263 52 0 81 19 149 95 621 698 613 1792 -19 2460 -88
93 -154 109 -209 48z"
                    />
                </g>
            </svg>
        </span>
    );
};

export default InfoIcon;
