import { Col, Row, Skeleton } from 'antd';
import React from 'react';
import Tables from '../../components/Tables';
import MetricsCards from '../../components/metricCards';
import '../../helpers/override.scss';
import { getProfitabilityColumn } from '../../helpers/profitAnalysis';
import useProfitAnalysis from './useProfitAnalysis';

const ProfitCenterAnalysis = () => {
    const { metrics, profitabilityTracker } = useProfitAnalysis();
    const { isLoading: metricsLoading, data: metricsData } = metrics;
    const { isLoading: profitabilityTrackerLoading, data: profitabilityTrackerData } = profitabilityTracker;

    return (
        <div className="main-container">
            {metricsLoading ? <Skeleton /> : <MetricsCards data={metricsData} />}
            <div className="jv-table">
                <Row gutter={[16, 24]}>
                    <Col span={24}>
                        {profitabilityTrackerLoading ? (
                            <Skeleton />
                        ) : (
                            <Tables
                                title={profitabilityTrackerData?.title}
                                columns={getProfitabilityColumn(profitabilityTrackerData?.profitTrackerHeadings) || []}
                                data={profitabilityTrackerData?.profitTrackerData}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ProfitCenterAnalysis;
