import { createSlice } from '@reduxjs/toolkit';
import {
    getforexforexMonthlyAnalysis,
    getForexImpactmetrics,
    getCurrencyPairImpact,
    getTopImpactItems,
    getForexLineGraph,
    getTopImpactItemsGain
} from './forexImpactApi';

const initialState = {
    metrics: {
        isLoading: true,
        isError: false,
        data: [],
    },
    analysis: {
        isLoading: true,
        isError: false,
        data: [],
    },
    currencyPairImpact: {
        isLoading: true,
        isError: false,
        data: [],
    },
    topImpactItems: {
        isLoading: true,
        isError: false,
        data: [],
    },
    lineGraph: {
        isLoading: true,
        isError: false,
        data: [],
    },
    topImpactGains: {
        isLoading: true,
        isError: false,
        data: [],
    }
};

const forexImpactSlice = createSlice({
    name: 'forexImpact',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getForexImpactmetrics.pending, state => {
                state.metrics.isLoading = true;
            })
            .addCase(getForexImpactmetrics.fulfilled, (state, action) => {
                state.metrics.isLoading = false;
                state.metrics.data = action.payload;
            })
            .addCase(getForexImpactmetrics.rejected, (state, action) => {
                state.metrics.isLoading = false;
            })
            .addCase(getforexforexMonthlyAnalysis.pending, state => {
                state.analysis.isLoading = true;
            })
            .addCase(getforexforexMonthlyAnalysis.fulfilled, (state, action) => {
                state.analysis.isLoading = false;
                state.analysis.data = action.payload;
            })
            .addCase(getforexforexMonthlyAnalysis.rejected, (state, action) => {
                state.analysis.isLoading = false;
            })
            .addCase(getCurrencyPairImpact.pending, state => {
                state.currencyPairImpact.isLoading = true;
            })
            .addCase(getCurrencyPairImpact.fulfilled, (state, action) => {
                state.currencyPairImpact.isLoading = false;
                state.currencyPairImpact.data = action.payload;
            })
            .addCase(getCurrencyPairImpact.rejected, (state, action) => {
                state.currencyPairImpact.isLoading = false;
            })
            .addCase(getTopImpactItems.pending, state => {
                state.topImpactItems.isLoading = true;
            })
            .addCase(getTopImpactItems.fulfilled, (state, action) => {
                state.topImpactItems.isLoading = false;
                state.topImpactItems.data = action.payload;
            })
            .addCase(getTopImpactItems.rejected, (state, action) => {
                state.topImpactItems.isLoading = false;
            })
            .addCase(getForexLineGraph.pending, state => {
                state.lineGraph.isLoading = true;
            })
            .addCase(getForexLineGraph.fulfilled, (state, action) => {
                state.lineGraph.isLoading = false;
                state.lineGraph.data = action.payload;
            })
            .addCase(getForexLineGraph.rejected, (state, action) => {
                state.lineGraph.isLoading = false;
            })
            .addCase(getTopImpactItemsGain.pending, state => {
                state.topImpactGains.isLoading = true;
            })
            .addCase(getTopImpactItemsGain.fulfilled, (state, action) => {
                state.topImpactGains.isLoading = false;
                state.topImpactGains.data = action.payload.forexAnalysisData                ;
            })
            .addCase(getTopImpactItemsGain.rejected, (state, action) => {
                state.topImpactGains.isLoading = false;
            });
    },
});

export default forexImpactSlice.reducer;
