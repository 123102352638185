import React from 'react';

const AvatarIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" fill="none" viewBox="0 0 61 61">
            <path
                fill="#1F3D7B"
                fillRule="evenodd"
                d="M30.5 10.167c-11.224 0-20.333 9.109-20.333 20.333 0 11.224 9.109 20.333 20.333 20.333 11.224 0 20.333-9.109 20.333-20.333 0-11.224-9.109-20.333-20.333-20.333zm0 6.1c3.375 0 6.1 2.724 6.1 6.1 0 3.375-2.725 6.1-6.1 6.1a6.092 6.092 0 01-6.1-6.1c0-3.376 2.725-6.1 6.1-6.1zm0 28.873a14.641 14.641 0 01-12.2-6.547c.061-4.047 8.133-6.263 12.2-6.263 4.046 0 12.139 2.216 12.2 6.263a14.641 14.641 0 01-12.2 6.547z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
};

export default AvatarIcon;
