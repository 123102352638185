import { Button, Checkbox, Col, Dropdown, Popover, Row, Space } from 'antd';

import { ArrowRightOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useQuerySetter from '../../hooks/useQuerySetter';
import CurrentPageName from './CurrentPageName';
import RangePicker from './RangePicker';
import './breadcrumb.scss';
import Entity from './entity';
import { setGeography, setCheckedEntity, setNewEntity, setCustomerType } from '../../features/filters';
import { hideEntireFilterSet } from '../../helpers/settings';

const breadcrumbData = {
    popover: {
        date: 'Date',
        geography: 'Region',
        entity: 'Entity',
        customerType: 'Customer Group',
    },
};

const hideFilterFromThisPage = ['forex-impact'];

const Breadcrumb = () => {
    const location = useLocation();
    const currentPage = location.pathname.substring(1);
    const dispatch = useDispatch();
    const { customerTypeLoadingOnClick } = useSelector(state => state.filter);
    const {
        geographyData,
        handleGeoGraphySelect,
        handleDate1,
        handleDate2,
        handleCompareTo,
        handleApply,
        selectedEntity,
        fromDate,
        toDate,
        compareTo,
        handleCustomerType,
        customerTypeData,
        filterData,
        handleCustomerTypeChange,
    } = useQuerySetter();
    const { geoLocations, customerType, datePickerDates } = filterData;
    const [open0, setOpen0] = useState(false);

    const handleOpenVisibleChange = (openState, dropdownIndex) => {
        if (dropdownIndex === 0) {
            setOpen0(openState);
        }
    };

    return (
        <div className="jv-breadcrumb">
            <Row gutter={[8, 16]}>
                <Col xs={24} sm={6} className="jv-breadcrumb__left">
                    <CurrentPageName />
                </Col>
                <Col xs={24} sm={18} className="jv-breadcrumb__right">
                    {!hideEntireFilterSet.includes(currentPage) ? (
                        <Space wrap>
                            <Dropdown
                                overlayClassName="jv-breadcrumb__datepicker-wrapper"
                                dropdownRender={() => (
                                    <div className="jv-breadcrumb__datepicker">
                                        <RangePicker
                                            handleDate1={handleDate1}
                                            handleDate2={handleDate2}
                                            handleCompareTo={handleCompareTo}
                                            date1value={fromDate}
                                            date2value={toDate}
                                            compareTo={compareTo}
                                            datePickerDates={datePickerDates}
                                            handleCustomerType={handleCustomerType}
                                        />
                                    </div>
                                )}
                                open={open0}
                                onOpenChange={openState => handleOpenVisibleChange(openState, 0)}>
                                <Button
                                    disabled={hideFilterFromThisPage.includes('summary')}
                                    className="jv-breadcrumb__action"
                                    type="dashed">
                                    {breadcrumbData.popover.date}
                                </Button>
                            </Dropdown>
                            {geoLocations && (
                                <Popover
                                    overlayClassName="jv-breadcrumb__popover--geography"
                                    placement="bottom"
                                    content={
                                        !hideFilterFromThisPage.includes(currentPage) && (
                                            <>
                                                <Checkbox
                                                    className="jv-breadcrumb__checkbox--all-checkbox"
                                                    checked={geographyData.includes(0)}
                                                    onClick={() => {
                                                        let newGeograpy;
                                                        if (geographyData.includes(0)) {
                                                            newGeograpy = [0];
                                                        } else {
                                                            newGeograpy = [];
                                                        }
                                                        const latestGeography = newGeograpy.length === 0 ? [0] : [];
                                                        dispatch(setGeography(latestGeography));
                                                        dispatch(setNewEntity([]));
                                                        dispatch(setCheckedEntity([0]));
                                                        handleCustomerType([0])
                                                        handleCustomerTypeChange(latestGeography, 'geography');
                                                    }}>
                                                    All
                                                </Checkbox>
                                                <Checkbox.Group
                                                    onChange={handleGeoGraphySelect}
                                                    className="jv-breadcrumb__checkbox"
                                                    options={geoLocations}
                                                    value={geographyData}
                                                />
                                            </>
                                        )
                                    }>
                                    <Button
                                        className="jv-breadcrumb__action"
                                        type="dashed"
                                        disabled={hideFilterFromThisPage.includes(currentPage) && true}>
                                        {breadcrumbData.popover.geography}
                                    </Button>
                                </Popover>
                            )}
                            <Popover
                                overlayClassName="jv-breadcrumb__popover--entity"
                                placement="bottom"
                                content={
                                    !hideFilterFromThisPage.includes(currentPage) && (
                                        <Entity
                                            handleCustomerTypeChange={handleCustomerTypeChange}
                                            selectedEntity={selectedEntity}
                                            handleCustomerType={handleCustomerType}
                                        />
                                    )
                                }>
                                <Button
                                    className="jv-breadcrumb__action"
                                    type="dashed"
                                    disabled={hideFilterFromThisPage.includes(currentPage) && true}>
                                    {breadcrumbData.popover.entity}
                                </Button>
                            </Popover>
                            {customerType && (
                                <Popover
                                    placement="bottom"
                                    content={
                                        !hideFilterFromThisPage.includes(currentPage) && (
                                            <>
                                                <Checkbox
                                                    checked={customerTypeData.includes(0)}
                                                    className="jv-breadcrumb__checkbox--all-checkbox"
                                                    disabled={customerTypeLoadingOnClick}
                                                    onClick={() => {
                                                        let newCustomer;
                                                        if (customerTypeData.includes(0)) {
                                                            newCustomer = [0];
                                                        } else {
                                                            newCustomer = [];
                                                        }
                                                        const latestCustomer = newCustomer.length === 0 ? [0] : [];
                                                        dispatch(setCustomerType(latestCustomer));
                                                    }}>
                                                    All
                                                </Checkbox>
                                                <Checkbox.Group
                                                    onChange={handleCustomerType}
                                                    className="jv-breadcrumb__checkbox"
                                                    options={customerType}
                                                    value={customerTypeData}
                                                    disabled={customerTypeLoadingOnClick}
                                                />
                                            </>
                                        )
                                    }>
                                    <Button
                                        className="jv-breadcrumb__action"
                                        type="dashed"
                                        disabled={hideFilterFromThisPage.includes(currentPage) && true}>
                                        {breadcrumbData.popover.customerType}
                                    </Button>
                                </Popover>
                            )}
                            <Button className="jv-breadcrumb__apply" type="dashed" onClick={handleApply}>
                                <ArrowRightOutlined />
                            </Button>
                        </Space>
                    ) : (
                        ''
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default Breadcrumb;
