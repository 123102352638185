import { useSelector } from 'react-redux';
import { Card, Col, Row, Skeleton, Table, Typography } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import Tables from '../../components/Tables';
import KeyMetricsCards from '../../components/metricCards';
import LineChart from '../../components/chart/LineChart';
import { setUpLineChart } from '../../helpers/charts';
import {
    getBusinessTypeColumn,
    getEntityContributionColumn,
    getGeoContributionCOlumn,
    entityContributionComponentColum,
    regionalContributionColumn,
    customergroupComponentColumn,
} from '../../helpers/keymetrics';
import useKeyMetrics from './usekeyMetrics';
import * as constants from '../../helpers/constant';
import '../../helpers/override.scss';
const { Title } = Typography;

const KeyMetrics = () => {
    const {
        keyMetricsCards,
        keyMetricsContribution,
        keyMetricsBusinessType,
        keyMetricsEntityContribution,
        keyMetricsSalesTrending,
        keyMetricsVolumeTrending,
        keyRegionalContributionComponentVariance,
        keyEntityContributionComponentVariance,
        keyCustomergroupComponentVariance,
        compareTo,
        forceRender,
    } = useKeyMetrics();

    const { isLoading: contributionLoading, contributionData } = keyMetricsContribution;
    const { isLoading: trendingLoading, businessTypeData } = keyMetricsBusinessType;
    const { isLoading: entityContributionLoading, EntityContributionData } = keyMetricsEntityContribution;
    const { overviews, updatedChartSettings, salesTrendingLoading } = keyMetricsSalesTrending;
    const {
        isLoading: volumeTrendingLoading,
        volumeTrendingData,
        previousVolumeTrendingData,
    } = keyMetricsVolumeTrending;
    const { isLoading: keyMetricsLoading, keyMetricsData } = keyMetricsCards;
    const { isLoading: regionalContributionLoading, RegionalContributionComponentVariance } =
        keyRegionalContributionComponentVariance;
    const { isLoading: entityContributionComponentLoading, EntityContributionComponentVariance } =
        keyEntityContributionComponentVariance;
    const { isLoading: customergroupComponentLoading, CustomergroupComponentVariance } =
        keyCustomergroupComponentVariance;
    return (
        <div>
            <div className="main-container">
                {keyMetricsLoading ? (
                    <Skeleton />
                ) : (
                    <KeyMetricsCards data={keyMetricsData?.keyMetricsTiles} forexDollar={true} />
                )}
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                        {!salesTrendingLoading ? (
                            updatedChartSettings?.series?.[0]?.data ? (
                                <Card
                                    bordered={false}
                                    className="criclebox h-full"
                                    title={<Title>{overviews?.[0]?.title}</Title>}>
                                    <HighchartsReact
                                        // By adding key to random number we ensure that the Highchart rerender.
                                        key={forceRender}
                                        highcharts={Highcharts}
                                        options={updatedChartSettings}
                                    />
                                </Card>
                            ) : (
                                <>
                                    <Card bordered={false} className="criclebox h-full" title={overviews?.[0]?.title}>
                                        No data available for chart
                                    </Card>
                                </>
                            )
                        ) : (
                            <Skeleton />
                        )}
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                        {!volumeTrendingLoading ? (
                            <LineChart
                                // By adding key to random number we ensure that the Highchart rerender.
                                key={forceRender}
                                chartTitle={volumeTrendingData?.overviews[0]?.title || 'Volume Trending'}
                                chartData={{ volumeTrendingData, previousVolumeTrendingData, compareTo }}
                            />
                        ) : (
                            <Skeleton />
                        )}
                    </Col>
                </Row>
                <div className="tabled jv-table">
                    <Row gutter={[16, 24]}>
                        <Col>
                            {contributionLoading ? (
                                <Skeleton />
                            ) : (
                                <Tables
                                    title={contributionData?.title || ''}
                                    columns={
                                        getGeoContributionCOlumn(
                                            contributionData?.tableHeadings,
                                            compareTo,
                                            contributionData?.info,
                                        ) || []
                                    }
                                    data={contributionData?.geographyData || []}
                                    bgColor="table-bg-light"
                                    hasTotal={true}
                                    type="key-metrics"
                                    totalDataSet={contributionData?.keyMetricsItemTotals}
                                    rightSideContent={contributionData?.info}
                                />
                            )}
                            {compareTo === constants.previousPeriod ? (
                                regionalContributionLoading ? (
                                    <Skeleton />
                                ) : (
                                    <Tables
                                        title={RegionalContributionComponentVariance?.title || ''}
                                        columns={
                                            regionalContributionColumn(
                                                RegionalContributionComponentVariance?.keyMatrixVarianceTableHeadings,
                                                compareTo,
                                                RegionalContributionComponentVariance?.info,
                                            ) || []
                                        }
                                        data={RegionalContributionComponentVariance?.keyMatrixVarianceData || []}
                                        bgColor="table-bg-light"
                                        // hasTotal={true}
                                        // type="key-metrics"
                                        // totalDataSet={RegionalContributionComponentVariance?.keyMatrixVarianceTotals}
                                        rightSideContent={RegionalContributionComponentVariance?.info}
                                    />
                                )
                            ) : null}
                            {entityContributionLoading ? (
                                <Skeleton />
                            ) : (
                                <Tables
                                    title={EntityContributionData?.title || ''}
                                    columns={
                                        getEntityContributionColumn(
                                            EntityContributionData?.tableHeadings,
                                            compareTo,
                                            EntityContributionData?.info,
                                        ) || []
                                    }
                                    data={EntityContributionData?.entityData || []}
                                    bgColor="table-bg-dark"
                                    hasTotal={true}
                                    type="key-metrics"
                                    totalDataSet={EntityContributionData?.keyMetricsItemTotals}
                                    rightSideContent={EntityContributionData?.info}
                                />
                            )}
                            {compareTo === constants.previousPeriod ? (
                                entityContributionComponentLoading ? (
                                    <Skeleton />
                                ) : (
                                    <Tables
                                        title={EntityContributionComponentVariance?.title || ''}
                                        columns={entityContributionComponentColum(
                                            EntityContributionComponentVariance?.keyMatrixVarianceTableHeadings,
                                            compareTo,
                                            EntityContributionComponentVariance?.info,
                                        )}
                                        data={EntityContributionComponentVariance?.keyMatrixVarianceData || []}
                                        bgColor="table-bg-dark"
                                        // hasTotal={true}
                                        // type="key-metrics"
                                        // totalDataSet={RegionalContributionComponentVariance?.keyMatrixVarianceTotals}
                                        rightSideContent={EntityContributionComponentVariance?.info}
                                    />
                                )
                            ) : null}
                            {trendingLoading ? (
                                <Skeleton />
                            ) : (
                                <Tables
                                    title={businessTypeData?.title || ''}
                                    columns={
                                        getBusinessTypeColumn(
                                            businessTypeData?.businessTypeTableHeadings,
                                            compareTo,
                                            businessTypeData?.info,
                                        ) || []
                                    }
                                    hasTotal={true}
                                    type="key-metrics"
                                    totalDataSet={businessTypeData?.keyMetricsItemTotals}
                                    data={businessTypeData?.businessTypeData || []}
                                    bgColor="table-bg-light"
                                    rightSideContent={businessTypeData?.info}
                                />
                            )}
                            {compareTo === constants.previousPeriod ? (
                                customergroupComponentLoading ? (
                                    <Skeleton />
                                ) : (
                                    <Tables
                                        title={CustomergroupComponentVariance?.title || ''}
                                        columns={
                                            customergroupComponentColumn(
                                                CustomergroupComponentVariance?.keyMatrixVarianceTableHeadings,
                                                compareTo,
                                                CustomergroupComponentVariance?.info,
                                            ) || []
                                        }
                                        data={CustomergroupComponentVariance?.keyMatrixVarianceData || []}
                                        bgColor="table-bg-light"
                                        rightSideContent={CustomergroupComponentVariance?.info}
                                    />
                                )
                            ) : null}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default KeyMetrics;
