import React from 'react';
import { Layout, Row, Col } from 'antd';
import { HeartFilled } from '@ant-design/icons';
import './footer.scss';

const Footer = () => {
    const { Footer: AntFooter } = Layout;

    return (
        <AntFooter className="jv-footer" style={{ background: '#fafafa' }}>
            <Row className="just">
                <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className="copyright">&copy; 2024 JOHNSONVILLE LLC.</div>
                </Col>
            </Row>
        </AntFooter>
    );
};

export default Footer;
