import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { Typography, Card, Empty } from 'antd';
import { setupLineChartConfiguration } from '../../helpers/charts';
const { Title } = Typography;

const LineChart = props => {
    const { chartData, chartTitle } = props;
    const { volumeTrendingData, previousVolumeTrendingData, compareTo } = chartData;
    return (
        <>
            <Card bordered={false} className="criclebox h-full" title={<Title>{chartTitle}</Title>}>
                {chartData?.volumeTrendingData?.data?.length > 0 &&
                chartData?.previousVolumeTrendingData?.data.length > 0 ? (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={setupLineChartConfiguration(volumeTrendingData, previousVolumeTrendingData, compareTo)}
                    />
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </Card>
        </>
    );
};

export default LineChart;
