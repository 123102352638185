import { CaretDownOutlined } from '@ant-design/icons';
import { Col, DatePicker, Radio, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useLocation } from 'react-router-dom';
import './rangepicker.scss';

const monthFormat = 'YYYY/MM';

const RangePickerData = {
    actions: {
        apply: 'Apply',
        cancel: 'Cancel',
    },
    compare: {
        label: 'Compare To',
        radios: [
            {
                label: 'None',
                value: 'none',
            },
            {
                label: 'Previous Period',
                value: 'previous period',
            },
        ],
    },
};

const RangePicker = ({ handleDate1, handleDate2, handleCompareTo, date1value, date2value, compareTo , datePickerDates: dates}) => {
    const location = useLocation();

    const onChange = e => {
        handleCompareTo(e.target.value);
    };

    const parsedStartDate = dayjs( `${ dates?.[0]?.start?.year }/0${ dates?.[0]?.start?.month }`, monthFormat );
    const parsedEndDate = dayjs( `${ dates?.[0]?.end?.year }/0${ dates?.[0]?.end?.month }`, monthFormat );

    /**
     * Cannot select date greater than date2value value.
     * @param {string} current 
     * @returns 
     */
    const disabledDate1 = current => {
        // Can not select days before today and today
        if (!date2value) {
            return false;
        }

        return current > dayjs(date2value).endOf('day') || current < parsedStartDate;
    };

    /**
     * Cannot select date smaller than date1value value.
     * @param {string} current 
     * @returns 
     */
    const disabledDate2 = current => {

        if (!date1value) {
            return false;
        }

        return current < dayjs(date1value).endOf('day') || current > parsedEndDate ;
    };

    return (
        <div className="jv-rangepicker">
            <Row gutter={16} align="middle">
                <Col span={11}>
                    <DatePicker
                        value={dayjs(date1value, monthFormat)}
                        onChange={handleDate1}
                        format={monthFormat}
                        picker="month"
                        allowClear={false}
                        disabled={location?.pathname === '/summary'}
                        suffixIcon={<CaretDownOutlined />}
                        disabledDate={disabledDate1}
                    />
                </Col>
                <Col className="jv-rangepicker__to" span={2}>
                    To
                </Col>
                <Col span={11}>
                    <DatePicker
                        value={dayjs(date2value, monthFormat)}
                        onChange={handleDate2}
                        format={monthFormat}
                        picker="month"
                        allowClear={false}
                        suffixIcon={<CaretDownOutlined />}
                        disabledDate={disabledDate2}
                    />
                </Col>
            </Row>
            <Typography className="jv-rangepicker__compto">{RangePickerData.compare.label}</Typography>
            <Col sm={24}>
                <Radio.Group onChange={onChange} value={compareTo}>
                    {
                        RangePickerData.compare.radios.map( (radio, index) => {
                            if (index === 1 && dates?.[0]?.start?.year === date1value.split('-')[0]) {
                                handleCompareTo('none');
                                return null; 
                            }

                            return (
                                <Radio disabled={location?.pathname === '/summary'} key={radio.value} value={radio.value}>
                                    {radio.label}
                                </Radio>
                            );
                        } )
                    }
                </Radio.Group>
            </Col>
        </div>
    );
};

export default RangePicker;
