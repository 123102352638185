import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getforexforexMonthlyAnalysis,
    getForexImpactmetrics,
    getCurrencyPairImpact,
    getTopImpactItems,
    getForexLineGraph,
    getTopImpactItemsGain,
} from '../../features/forexImpact/forexImpactApi';
import { defaultChartSettings } from '../../helpers/settings';
import { formatNumbers } from '../../helpers/common';
const useForexImpact = () => {
    const dispatch = useDispatch();
    const { metrics, analysis, currencyPairImpact, topImpactItems, lineGraph, topImpactGains } = useSelector(
        state => state.forexImpact,
    );
    const { apiPayload } = useSelector(state => state.filter);
    const { fromDate, toDate, geoLocationID, customerTypeID, entityID, compareTo } = apiPayload;
    const {
        isLoading: linegraphLoading,
        data: { overviews, data },
    } = lineGraph;

    useEffect(() => {
        if (!fromDate && !toDate && !geoLocationID && !customerTypeID && !entityID) return;

        const payload = {
            fromDate,
            toDate,
        };

        dispatch(getForexImpactmetrics(payload));
        dispatch(getForexLineGraph(payload));
        dispatch(getforexforexMonthlyAnalysis(payload));
        dispatch(getCurrencyPairImpact(payload));
        dispatch(getTopImpactItems(payload));
        dispatch(getTopImpactItemsGain(payload));
    }, [dispatch, fromDate, toDate, geoLocationID, customerTypeID, entityID]);

    const updatedChartSettings = useMemo(() => {
        if (!overviews) return defaultChartSettings;

        return {
            ...defaultChartSettings,
            chart: { ...defaultChartSettings.chart, type: 'line' },
            title: { ...defaultChartSettings.title, align: 'left' },
            xAxis: { ...defaultChartSettings.xAxis, categories: overviews?.[0]?.xAxis?.label },
            yAxis: {
                ...defaultChartSettings.yAxis,
                title: { ...defaultChartSettings.yAxis.title, text: overviews?.[0]?.yAxis?.label },
            },
            tooltip: {
                formatter: function () {
                    const seriesNameCurrent = overviews?.[0]?.current;
                    const seriesNameTarget = overviews?.[0]?.target;
                    const seriesName = this.series.name === seriesNameCurrent ? seriesNameCurrent : seriesNameTarget;
                    return `<b>${this.x}</b><br/><br/>
                        <span>${seriesName}</span>: <b>${formatNumbers(this.y || 0, false)}</b>`;
                },
                // ... other tooltip settings
            },
            legend: {
                enabled: true,
                align: 'right',
                verticalAlign: 'top',
                layout: 'vertical',
                x: -30,
                y: -20,
            },
            plotOptions: {
                line: {
                    lineWidth: 2,
                    marker: {
                        enabled: true,
                    },
                },
            },
            series: [
                {
                    className: 'highcharts-color-2',
                    type: 'line',
                    name: overviews?.[0]?.current,
                    data: data?.[0]?.gRvsPO,
                    marker: { symbol: 'circle' },
                },
                {
                    className: 'highcharts-color-0',
                    type: 'line',
                    name: overviews?.[0]?.target,
                    data: data?.[0]?.iRvsPO,
                },
            ],
        };
    }, [overviews, data]);

    return {
        compareTo,
        metrics,
        analysis,
        currencyPairImpact,
        topImpactItems,
        topImpactGains,
        linegraph: {
            overviews,
            updatedChartSettings,
            linegraphLoading,
        },
    };
};

export default useForexImpact;
