import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../service/axiosInstance';
import { operationAnalysis } from '../../service/path';

export const getProfitMetrics = createAsyncThunk('operationAnalysis/getProfitMetrics', async (payload, thunkAPI) => {
    try {
        const resp = await axios.post(operationAnalysis.metrics, payload);
        return resp.data;
    } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
    }
});

export const getExportDelivery = createAsyncThunk('operationAnalysis/getExportDelivery', async (payload, thunkAPI) => {
    try {
        const resp = await axios.post(operationAnalysis.exportDelivery, payload);
        return resp.data;
    } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
    }
});
export const getImportDelivery = createAsyncThunk('operationAnalysis/getImportDelivery', async (payload, thunkAPI) => {
    try {
        const resp = await axios.post(operationAnalysis.importDelivery, payload);
        return resp.data;
    } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
    }
});
