import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEntitySummaryComplete, getRegionalSummaryComplete } from '../../features/summary/summary';
import { formatDates } from '../../helpers/dateformate';
import dayjs from 'dayjs';

const useSummary = () => {
    const dispatch = useDispatch();
    const { regionalComplete, entityComplete } = useSelector(state => state.summary);

    const currentDate = dayjs();
    const currentYearDate = formatDates(currentDate);
    const currentYear = currentDate.year();
    
    // Get the same date last year
    const lastYearDate = currentDate.subtract(1, 'year');
    const lastYear = lastYearDate.year();

    useEffect(() => {
        if (!currentYearDate || !lastYear) {
            return;
        }

        const regionalPayload = {
            currentYearDate,
            previousYearDate: `${lastYear}-12`,
        };

        const entityPayload = {
            ...regionalPayload,
            geoLocationID: '0',
        };

        dispatch(getRegionalSummaryComplete(regionalPayload));
        dispatch(getEntitySummaryComplete(entityPayload));
    }, [dispatch, currentYearDate, lastYear]);

    return { regionalComplete,currentYear, lastYear, entityComplete };
};

export default useSummary;
