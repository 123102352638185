import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';

/**
 * A private route component that restricts access to authenticated users.
 * If the user is authenticated, the component renders the nested routes using Outlet.
 * If the user is not authenticated, the component navigates to the specified login route.
 *
 * @component
 * @example
 * // Use PrivateRoute to protect routes that require authentication.
 * <PrivateRoute />
 *
 * @returns {ReactNode} The protected route content based on user authentication.
 */
const PrivateRoute = () => {
    const { user } = useAuth();
    return user?.token ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
