import React from 'react';
import UserIcon from './UserIcon';
import SettingsIcon from './SettingsIcon';
import PowerIcon from './PowerIcon';
import SalesAnalysis from './SalesAnalysis';
import ProfitCenter from './ProfitCenter';
import OperationsAnalysis from './OperationsAnalysis';
import ForexImpact from './ForexImpact';
import TransportRateVariance from './TransportRateVariance';
import KeyMetrics from './KeyMetrics';
import PointerIcon from './PointerIcon';
import AvatarIcon from './AvatarIcon';
import LogoIcon from './LogoIcon';
import LogoutIcon from './LogoutIcon';
import ArrowIcon from './ArrowIcon';
import MailIcon from './MailIcon';
import FilterIcon from './FilterIcon';
import Hamburger from './Hamburger';
import DropDown from './DropDown';
import Profile from './Profile';

const icons = {
    user: UserIcon,
    settings: SettingsIcon,
    power: PowerIcon,
    salesAnalysis: SalesAnalysis,
    profitCenter: ProfitCenter,
    operationsAnalysis: OperationsAnalysis,
    forexImpact: ForexImpact,
    transportRateVariance: TransportRateVariance,
    keyMetrics: KeyMetrics,
    pointerIcon: PointerIcon,
    avtar: AvatarIcon,
    logo: LogoIcon,
    logout: LogoutIcon,
    arrow: ArrowIcon,
    mail: MailIcon,
    filter: FilterIcon,
    hamburger: Hamburger,
    dropDown: DropDown,
    profile: Profile,
};

const Icon = ({ icon, ...rest }) => {
    const IconEle = icons[icon];
    return <IconEle {...rest} />;
};

export default Icon;
