/**
 * Format a number as a string with thousands separators.
 *
 * @param {number} number - Passed number to format.
 * @returns {string} The formatted number as a string with thousands separators.
 */
/**
 * Format a number as a string with thousands separators and optional million formatting.
 *
 * @param {number} number - Passed number to format.
 * @param {boolean} isMillion - Flag to indicate whether to format as million.
 * @returns {string} The formatted number as a string with thousands separators.
 */
export const formatNumbers = (number, isMillion = true) => {
    if (!number) return number;

    const returnVal = number.toString();
    let tmp = [];
    let fraction = 0;
    let strNew = '';
    let isNegative = false;

    // Check if the input 'returnVal' contains a decimal point.
    if (returnVal.indexOf('.') !== -1) {
        const splitValue = returnVal.split('.');

        // Reverse the digits before the decimal point and store them in 'tmp'.
        tmp = splitValue[0].split('').reverse();
        fraction = parseInt(splitValue[1]);
    } else {
        tmp = returnVal.split('').reverse();
    }

    // Check for a negative sign.
    if (tmp[tmp.length - 1] === '-') {
        isNegative = true;
        tmp.pop(); // Remove the negative sign from the array.
    }

    // Check if the 'tmp' array, which contains the reversed digits, has more than three elements.
    if (tmp.length > 4) {
        for (let i = 0; i < tmp.length; i++) {
            if (i > 0 && i % 3 === 0) {
                strNew += ',';
            }

            strNew += tmp[i].toString();
        }

        strNew = strNew.split('').reverse().join('');
    }

    // Check if the 'strNew' value is not empty (meaning it contains digits).
    if (strNew) {
        // If there is a fractional part, conditionally add a negative sign and the fractional part to 'strNew'.
        if (fraction) {
            strNew = isNegative ? '-' + strNew + `.${fraction}` : strNew + `.${fraction}`;
        } else {
            // If there is no fractional part, conditionally add a negative sign to 'strNew'.
            strNew = isNegative ? '-' + strNew : strNew;
        }
        const numericValue = Math.abs(parseFloat(strNew.replace(/,/g, '')));
        // Format as million if the isMillion is true and absolute value is greater that or equal to 5000
        if (isMillion && numericValue >= 5000) {
            let formattedValue;
            if (isNegative) {
                formattedValue = `(${Math.abs(numericValue / 1000000).toFixed(2)}) Mn`;
            } else {
                formattedValue = (numericValue / 1000000).toFixed(2) + ' Mn';
            }

            return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }

        if (isNegative) {
            const convertToNumber = parseFloat(strNew.replace(/,/g, ''));
            const formattedValue = Math.abs(convertToNumber);
            const formatToInternational = formattedValue.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            return `(${formatToInternational})`;
        }

        return strNew;
    } else {
        const convertToNumber = parseFloat(returnVal.replace(/,/g, ''));

        if (isNegative) {
            const removeNegative = Math.abs(convertToNumber);
            return `(${removeNegative.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })})`;
        }

        return convertToNumber.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }
};

/**
 * Check if a given string represents a positive numeric value.
 *
 * @param {string} str - The input string to be checked.
 * @returns {boolean} Returns true if the input string represents a positive numeric value, otherwise false.
 * @example
 * const result = isPositive("12.345");
 * // result is true
 *
 * const result2 = isPositive("-5");
 * // result2 is false
 */
export const isPositive = str => {
    // If not a string i.e it does not have any previous comparison value, we need to return true.
    if (!str) {
        return true;
    };

    const convertToNumValue = Number(str);

    return convertToNumValue >= 0;
};

/**
 * Remove the negative sign from a string if present.
 *
 * @param {string} str - The input string that may contain a negative sign.
 * @returns {string} A new string with the negative sign removed, or the original string if it was positive.
 * @example
 * const input1 = "-12.002";
 * const result1 = removeNegativeSign(input1);
 * // result1 is "12.002"
 *
 * const input2 = "8.75";
 * const result2 = removeNegativeSign(input2);
 * // result2 is "8.75"
 */
export const removeNegativeSign = str => {
    const resultString = str.replace(/^-/, '');
    return resultString;
};
