import React from 'react';

const PointerIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" fill="none" viewBox="0 0 16 13">
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M15.273 12.26c-1.78-2.044-3.36-3.203-4.74-3.479-1.381-.275-2.696-.317-3.945-.125v3.66L.728 6.344l5.86-5.66v3.478c2.31.018 4.272.797 5.888 2.338 1.617 1.541 2.55 3.461 2.797 5.76z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M6.862.05a.686.686 0 01.454.634v2.823c2.194.164 4.096 1.005 5.678 2.512 1.74 1.66 2.74 3.729 3.002 6.172a.68.68 0 01-.444.7.757.757 0 01-.842-.198c-1.762-2.023-3.206-3.018-4.329-3.243a10.833 10.833 0 00-3.065-.192v3.058a.686.686 0 01-.463.637.76.76 0 01-.799-.173L.193 6.809a.655.655 0 01.013-.942L6.067.207A.76.76 0 016.862.05zM1.73 6.357l4.131 4.21v-1.91c0-.336.258-.622.61-.676 1.347-.207 2.753-.16 4.212.13 1.064.213 2.16.845 3.288 1.825a8.249 8.249 0 00-2.012-2.955m-10.23-.624l4.132-3.99v1.795c0 .376.322.682.722.685 2.116.015 3.895.722 5.376 2.134"
                clipRule="evenodd"
            ></path>
        </svg>
    );
};

export default PointerIcon;
