import { Skeleton, Row, Col, Typography, Tabs, Divider } from 'antd';
import React from 'react';
import useSummary from './useSummary';
import SummaryTable from './sumaryTable';
import '../../helpers/override.scss';
import './index.scss';

const { Title } = Typography;

const regionColor = {
    netSales:'light',
    costOfSales:'dark',
    operatingProfit:'light'
}

const entityColor =  {
    netSales:'dark',
    costOfSales:'light',
    operatingProfit:'dark'
}

const Summary = () => {
    const { regionalComplete, currentYear, lastYear, entityComplete } = useSummary();
    const { isLoading: regionalCompleteLoading, data: regionalCompleteData } = regionalComplete;
    const { isLoading: entityCompleteLoading, data: entityCompleteData } = entityComplete;
    const { regionalCurrentYearData, regionalPreviousYearData } = regionalCompleteData;
    const { entityCurrentYearData, entityPreviousYearData } = entityCompleteData;


    const SummaryItem = [
        {
            key: '1',
            label: regionalCurrentYearData?.tabDetails?.currentYear || currentYear,
            children: <>
            <Title className='main-container__title' >{regionalCurrentYearData?.yearlySummary?.regionalsummary_costofsales?.title}</Title>
            <SummaryTable colors={regionColor} tabsData={regionalCurrentYearData?.yearlySummary} />
            <Divider />
            <Title className='main-container__title'>{entityCurrentYearData?.yearlySummary?.regionalsummary_costofsales?.title}</Title>
            <SummaryTable  colors={entityColor} tabsData={entityCurrentYearData?.yearlySummary} />
            </>  ,
        },
        {
            key: '2',
            label: regionalCurrentYearData?.tabDetails?.previousYear || lastYear,
            children: <>
            <Title className='main-container__title'>{regionalPreviousYearData?.yearlySummary?.regionalsummary_costofsales?.title}</Title>
            <SummaryTable colors={regionColor} tabsData={regionalPreviousYearData?.yearlySummary} />
            <Divider />
            <Title className='main-container__title'>{entityPreviousYearData?.yearlySummary?.regionalsummary_costofsales?.title}</Title>
            <SummaryTable colors={entityColor} tabsData={entityPreviousYearData?.yearlySummary} />
            </>,
        }
    ];

    return (
        <div className="main-container">
            <Row gutter={[16, 24]}>
                <Col span={24}>
                    {regionalCompleteLoading ? (
                        <Skeleton />
                    ) : (
                        <>
                            <Tabs defaultActiveKey="1" items={SummaryItem} />
                        </>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default Summary;
