import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
const Error = () => {
    const navigate = useNavigate();
    return (
        <div>
            404 not found
            <Button onClick={() => navigate(-1)}>Go back</Button>
            merged
        </div>
    );
};

export default Error;
