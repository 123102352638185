import React from 'react';
// import Breadcrumb from '../../components/breadcrumb';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Avatar, Card, Col, Row, Table, Typography } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { operationCards, spline } from '../../helpers/charts';
import '../../helpers/override.scss';
import useProfitAnalysis from './useProfitAnalysis';
const { Title } = Typography;

const status = {
    up: <ArrowUpOutlined />,
    down: <ArrowDownOutlined />,
};
// project table start
const project = [
    {
        title: 'Components',
        dataIndex: 'name',
        width: '32%',
    },
    {
        title: 'Truck',
        dataIndex: 'age',
    },
    {
        title: 'Rail',
        dataIndex: 'address',
    },
    {
        title: 'Ocean',
        dataIndex: 'completion',
    },
    {
        title: 'Air',
        dataIndex: 'ebitda',
    },
];
const dataproject = [
    {
        key: '1',

        name: (
            <>
                <Avatar.Group>
                    <div className="avatar-info">
                        <Title level={5}>JVL Export</Title>
                    </div>
                </Avatar.Group>
            </>
        ),
        age: (
            <>
                <div className={`semibold ${status.up ? 'green' : status.down ? 'red' : ''}`}>{status.up} $18 mn</div>
            </>
        ),
        address: (
            <>
                <div className={`semibold ${status.up ? 'green' : status.down ? 'red' : ''}`}>{status.up} $18 mn</div>
            </>
        ),
        completion: (
            <>
                <div className={`semibold ${status.up ? 'green' : status.down ? 'red' : ''}`}>{status.up} 25%</div>
            </>
        ),
        ebitda: (
            <>
                <div className={`semibold ${status.up ? 'green' : status.down ? 'red' : ''}`}>{status.up} 25%</div>
            </>
        ),
    },

    {
        key: '2',
        name: (
            <>
                <Avatar.Group>
                    <div className="avatar-info">
                        <Title level={5}>FBC Import</Title>
                    </div>
                </Avatar.Group>
            </>
        ),
        age: (
            <>
                <div className={`semibold ${status.down ? 'red' : status.up ? 'green' : ''}`}>{status.down} $4 mn</div>
            </>
        ),
        address: (
            <>
                <div className={`semibold ${status.down ? 'red' : status.up ? 'green' : ''}`}>{status.down} $4 mn</div>
            </>
        ),
        completion: (
            <>
                <div className={`semibold ${status.down ? 'red' : status.up ? 'green' : ''}`}>{status.down} 19%</div>
            </>
        ),
        ebitda: (
            <>
                <div className={`semibold ${status.down ? 'red' : status.up ? 'green' : ''}`}>{status.down} 19%</div>
            </>
        ),
    },

    {
        key: '3',
        name: (
            <>
                <Avatar.Group>
                    <div className="avatar-info">
                        <Title level={5}>QB Food Import/Export</Title>
                    </div>
                </Avatar.Group>
            </>
        ),
        age: (
            <>
                <div className={`semibold ${status.up ? 'green' : status.down ? 'red' : ''}`}>{status.up} $1.8 mn</div>
            </>
        ),
        address: (
            <>
                <div className={`semibold ${status.up ? 'green' : status.down ? 'red' : ''}`}>{status.up} $1.8 mn</div>
            </>
        ),
        completion: (
            <>
                <div className={`semibold ${status.down ? 'red' : status.up ? 'green' : ''}`}>{status.down} 12%</div>
            </>
        ),
        ebitda: (
            <>
                <div className={`semibold ${status.down ? 'red' : status.up ? 'green' : ''}`}>{status.down} 12%</div>
            </>
        ),
    },

    {
        key: '4',
        name: (
            <>
                <Avatar.Group>
                    <div className="avatar-info">
                        <Title level={5}>PB Food Import/Export</Title>
                    </div>
                </Avatar.Group>
            </>
        ),
        age: (
            <>
                <div className={`semibold ${status.up ? 'green' : status.down ? 'red' : ''}`}>{status.up} $1.1 mn</div>
            </>
        ),
        address: (
            <>
                <div className={`semibold ${status.up ? 'green' : status.down ? 'red' : ''}`}>{status.up} $1.1 mn</div>
            </>
        ),
        completion: (
            <>
                <div className={`semibold ${status.down ? 'red' : status.up ? 'green' : ''}`}>{status.down} 8%</div>
            </>
        ),
        ebitda: (
            <>
                <div className={`semibold ${status.down ? 'red' : status.up ? 'green' : ''}`}>{status.down} 8%</div>
            </>
        ),
    },

    {
        key: '5',
        name: (
            <>
                <Avatar.Group>
                    <div className="avatar-info">
                        <Title level={5}>Total</Title>
                    </div>
                </Avatar.Group>
            </>
        ),
        age: (
            <>
                <div className="semibold">25.2 mn</div>
            </>
        ),
        address: (
            <>
                <div className="text-sm">11.3 mn</div>
            </>
        ),
        completion: (
            <>
                <div className="ant-progress-project">11.3 mn</div>
            </>
        ),
        ebitda: (
            <>
                <div className="text-sm">11.3 mn</div>
            </>
        ),
    },
];

const OperationAnalysis = () => {
    const { metrics, exportDelivery, importDelivery } = useProfitAnalysis();
    const data = {
        head1: 'Export-Delivery/Supply chain',
        head2: 'Import-Delivery/Supply chain',
        month: 'Current Month',
        label: 'Operations Analysis',
    };
    return (
        <div>
            {/* <Breadcrumb label={data.label} /> */}
            <div className="main-container">
                <Row className="rowgap-vbox" gutter={[24, 0]}>
                    {operationCards.map((c, index) => (
                        <Col key={index} xs={24} sm={24} md={12} lg={12} xl={6} className="mb-24">
                            <Card
                                bordered={false}
                                className="criclebox "
                                style={{
                                    backgroundImage: `url(${c.image})`,
                                    backgroundColor: c.background,
                                }}
                            >
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col xs={16}>
                                            <span>{c.title}</span>
                                            <Title level={3}>
                                                <small className={c.size}>
                                                    {c.currency + ' ' + c.size + ' ' + c.unit}
                                                </small>
                                            </Title>
                                        </Col>
                                        <Col xs={8}>
                                            <Title level={3}>
                                                <small className={c.bnb}>{c.percentage}</small>
                                            </Title>
                                        </Col>
                                    </Row>
                                </div>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{ ...spline, series: c.dataSeries }}
                                />
                            </Card>
                        </Col>
                    ))}
                </Row>
                <div className="jv-table">
                    <Row gutter={[16, 24]}>
                        <Col span={24}>
                            <Card
                                bordered={false}
                                className="criclebox tablespace mb-24"
                                title="Export - Delivery /Supply chain"
                                extra={
                                    <>
                                        <h3>Current Month</h3>
                                    </>
                                }
                            >
                                <div className="table-responsive">
                                    <Table
                                        columns={project}
                                        dataSource={dataproject}
                                        pagination={false}
                                        className="ant-border-space"
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className="jv-table">
                    <Row gutter={[16, 24]}>
                        <Col span={24}>
                            <Card
                                bordered={false}
                                className="criclebox tablespace mb-24"
                                title="Import - Delivery /Supply chain"
                                extra={
                                    <>
                                        <h3>Current Month</h3>
                                    </>
                                }
                            >
                                <div className="table-responsive">
                                    <Table
                                        columns={project}
                                        dataSource={dataproject}
                                        pagination={false}
                                        className="ant-border-space"
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default OperationAnalysis;
