import React from 'react';

const TransportRateVariance = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="none" viewBox="0 0 22 23">
            <path
                fill="#6A6A6A"
                d="M14.856 15.8c-.626.33-1.19.625-1.746.934a.408.408 0 00-.172.275c-.02.262 0 .53-.014.791-.014.385-.247.619-.632.626-.86.007-1.72.007-2.579 0-.398-.007-.68-.255-.625-.653.103-.715-.11-1.162-.839-1.348-.192-.048-.357-.2-.53-.316-.164-.11-.316-.227-.494-.35-.255.143-.523.302-.79.453-.4.213-.737.13-.97-.261a94.971 94.971 0 01-1.231-2.139c-.213-.378-.144-.763.254-.928.64-.261.874-.653.736-1.34-.069-.337.041-.702.048-1.052a.43.43 0 00-.151-.296c-.206-.144-.433-.247-.646-.385-.358-.227-.454-.55-.255-.914.413-.736.839-1.472 1.272-2.193.213-.351.55-.427.921-.234.275.144.537.302.736.42.619-.33 1.19-.626 1.753-.943A.374.374 0 009.06 5.7c.021-.261 0-.53.007-.79.014-.407.24-.64.646-.64.846-.007 1.699-.007 2.544 0 .42 0 .702.247.647.667-.097.7.123 1.107.818 1.333.378.124.701.42 1.065.654.248-.138.51-.303.777-.447.378-.207.722-.117.942.254.426.715.846 1.437 1.251 2.166.207.364.11.687-.247.914-.213.138-.447.234-.646.385-.083.062-.145.213-.152.323a33.716 33.716 0 000 1.67c0 .11.076.255.159.317.192.144.412.24.618.364.378.234.475.557.261.936-.405.728-.825 1.45-1.25 2.165-.22.365-.565.447-.943.24-.247-.143-.502-.295-.7-.412zm-1.925-4.47a1.915 1.915 0 00-1.918-1.918 1.908 1.908 0 00-1.932 1.932 1.91 1.91 0 001.918 1.918 1.908 1.908 0 001.932-1.931z"
            ></path>
            <path
                fill="#6A6A6A"
                d="M5.506 19.32a9.58 9.58 0 002.88 1.354c5.116 1.396 10.265-1.389 11.88-6.428.675-2.111.585-4.215-.198-6.284-.035-.083-.076-.165-.097-.255-.117-.385.041-.715.385-.838a.613.613 0 01.818.405c.248.777.537 1.56.674 2.365.935 5.5-2.544 10.746-7.776 12.259-3.327.962-6.442.378-9.322-1.527-.145-.096-.282-.192-.344-.233-.09.26-.117.577-.282.776-.282.344-.839.207-1.004-.233a58.036 58.036 0 01-.949-2.86c-.15-.489.385-.867.942-.688.62.2 1.231.413 1.85.619.192.062.392.124.584.192.426.145.626.351.633.674.007.323-.18.53-.674.701zM17.716 2.606c-.069-.33-.11-.626.158-.86.337-.295.86-.192 1.018.255.337.942.66 1.897.949 2.86.144.488-.385.86-.95.68-.618-.199-1.23-.412-1.849-.618-.192-.062-.392-.124-.584-.193-.426-.144-.632-.35-.626-.673.014-.392.262-.585.66-.674-.096-.076-.158-.138-.227-.179C10.532-.474 2.983 2.654 1.518 9.31c-.412 1.87-.247 3.7.461 5.48.192.488.096.838-.261 1.004-.344.158-.722.02-.873-.358-.62-1.513-.929-3.087-.825-4.723.137-2.248.886-4.29 2.282-6.064C4.096 2.358 6.42.928 9.322.482c2.983-.461 5.685.26 8.154 1.966.068.048.144.096.24.158z"
            ></path>
        </svg>
    );
};

export default TransportRateVariance;
