import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../service/axiosInstance';
import { summary } from '../../service/path';

export const getRegionalSummaryComplete = createAsyncThunk(
    'summary/getRegionalSummaryComplete',
    async (payload, thunkAPI) => {
        try {
            const { currentYearDate, previousYearDate } = payload;

            const [response1, response2] = await Promise.all([
                axios.post(summary.regionalSummaryComplete, { toDate: currentYearDate }),
                axios.post(summary.regionalSummaryComplete, { toDate: previousYearDate }),
            ]);

            const regionalCurrentYearData = response1.data;
            const regionalPreviousYearData = response2.data;

            return {
                regionalCurrentYearData,
                regionalPreviousYearData,
            };
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    },
);

export const getEntitySummaryComplete = createAsyncThunk(
    'summary/getEntitySummaryComplete',
    async (payload, thunkAPI) => {
        try {
            const { currentYearDate, previousYearDate, geoLocationID } = payload;
            const [response1, response2] = await Promise.all([
                axios.post(summary.entitySummaryComplete, { toDate: currentYearDate, geoLocationID }),
                axios.post(summary.entitySummaryComplete, { toDate: previousYearDate, geoLocationID }),
            ]);

            const entityCurrentYearData = response1.data;
            const entityPreviousYearData = response2.data;

            return {
                entityCurrentYearData,
                entityPreviousYearData,
            };
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    },
);
