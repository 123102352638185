import { Card, Col, Row, Typography } from 'antd';
import img3 from '../../assets/images/AroowA.png';
import img1 from '../../assets/images/BarB.png';
import img2 from '../../assets/images/MechM.png';
import img4 from '../../assets/images/ProfitP.png';
import { formatNumbers, isPositive } from '../../helpers/common';
import { setupChartDataSizeWithPercent } from '../../helpers/charts';
import Chart from './chart';
const { Title } = Typography;

const MatricsCards = ({
    data = [],
    showMillion = true,
    showDollar = true,
    showPercent = true,
    isMillion = true,
    forexDollar = false,
}) => {
    const background = ['#DCDDDE', 'rgba(171, 30, 44, 0.15)', '#DCDDDE', '#E5ECF1'];
    const images = [img1, img2, img3, img4];
    return (
        <Row className="rowgap-vbox" gutter={[24, 0]}>
            {data &&
                data?.map((card, index) => (
                    <Col key={index} xs={24} sm={24} md={12} lg={12} xl={6} className="mb-24">
                        <Card
                            bordered={false}
                            className="criclebox "
                            style={{
                                backgroundImage: `url( ${images[index]} )`,
                                backgroundColor: background[index],
                            }}>
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col lg={24} md={24} xs={24}>
                                        <span>{card.title}</span>
                                        <Title level={3}>
                                            <small className={card.size}>
                                                {card.showDollar === true && '$'}
                                                {showMillion
                                                    ? formatNumbers(card.size || '0')
                                                    : formatNumbers(card.size || '0', isMillion)}{' '}
                                                {card?.sizeInPercent && ` (` + card.sizeInPercent + ` %)`}
                                            </small>
                                        </Title>
                                    </Col>
                                </Row>
                            </div>
                            <Chart
                                data={
                                    card?.sizeInPercent
                                        ? setupChartDataSizeWithPercent(card?.data, card?.dataInPercent, card?.title)
                                        : card?.data
                                }
                                forexDollar={forexDollar}
                                showMillion={showMillion}
                                title={card?.title}
                                hasSizeInPercent={!!card?.sizeInPercent}
                                showDollar={card.showDollar}
                            />
                        </Card>
                    </Col>
                ))}
        </Row>
    );
};

export default MatricsCards;
