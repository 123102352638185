import React from 'react';

const SettingsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M8.961 10.916a1.914 1.914 0 100-3.829 1.914 1.914 0 000 3.829z"
            ></path>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M15.309 6.223l-.768-1.326a1.147 1.147 0 00-1.571-.417l-.38.216a1.148 1.148 0 01-1.72-.998v-.44c0-.633-.515-1.147-1.148-1.147H8.188c-.634 0-1.148.514-1.148 1.147v.44c0 .886-.953 1.438-1.72.998l-.38-.216a1.153 1.153 0 00-1.572.417l-.767 1.326a1.153 1.153 0 00.417 1.572l.38.216c.767.44.767 1.549 0 1.989l-.38.216a1.147 1.147 0 00-.417 1.571l.767 1.326c.32.551 1.02.738 1.572.417l.38-.216c.767-.44 1.72.112 1.72.998v.44c0 .633.514 1.147 1.148 1.147h1.534c.633 0 1.147-.514 1.147-1.147v-.44c0-.886.954-1.437 1.72-.998l.38.216c.552.32 1.252.127 1.572-.417l.768-1.326a1.153 1.153 0 00-.418-1.572l-.38-.215a1.147 1.147 0 010-1.99l.38-.215c.552-.32.738-1.028.418-1.572z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
};

export default SettingsIcon;
