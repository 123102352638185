import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../service/axiosInstance';
import { Path } from '../../service/path';

export const getKeyMetrics = createAsyncThunk('keyMetrics/getKeyMetrics', async (payload, thunkAPI) => {
    try {
        const resp = await axios.post(Path.metrics, payload);
        return resp.data;
    } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
    }
});

export const getSalesTrending = createAsyncThunk('keyMetrics/getSalesTrending', async (payload, thunkAPI) => {
    try {
        const [current, previous] = await Promise.all([
            axios.post(Path.salesTrending, payload.payload),
            axios.post(Path.salesTrending, payload.previous),
        ]);

        return {
            current: current.data,
            previous: previous.data,
        };
    } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
    }
});

export const getVolumeTrending = createAsyncThunk('keyMetrics/getVolumeTrending', async (payload, thunkAPI) => {
    try {
        // const resp = await axios.post(Path.volumeTrending, payload);
        const [current, previous] = await Promise.all([
            axios.post(Path.volumeTrending, payload.payload),
            axios.post(Path.volumeTrending, payload.previous),
        ]);
        return {
            current: current.data,
            previous: previous.data,
        };
    } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
    }
});

export const getBusinessShare = createAsyncThunk('keyMetrics/getBusinessShare', async (payload, thunkAPI) => {
    try {
        const resp = await axios.post(Path.businessShare, payload);
        return resp.data;
    } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
    }
});

export const getContribution = createAsyncThunk('keyMetrics/getContribution', async (payload, thunkAPI) => {
    try {
        const resp = await axios.post(Path.contribution, payload);
        return resp.data;
    } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
    }
});

export const getBusinessType = createAsyncThunk('keyMetrics/getBusinessType', async (payload, thunkAPI) => {
    try {
        const resp = await axios.post(Path.businessType, payload);
        return resp.data;
    } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
    }
});

export const getEntityContribution = createAsyncThunk('keyMetrics/getEntityContribution', async (payload, thunkAPI) => {
    try {
        const resp = await axios.post(Path.entitycontribution, payload);
        return resp.data;
    } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
    }
});

export const getRegionalContributionComponentVariance = createAsyncThunk(
    'keyMetrics/getRegionalContributionComponentVariance',
    async (payload, thunkAPI) => {
        try {
            const resp = await axios.post(Path.regionalContributionComponentVariance, payload);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    },
);

export const getEntityContributionComponentVariance = createAsyncThunk(
    'keyMetrics/getEntityContributionComponentVariance',
    async (payload, thunkAPI) => {
        try {
            const resp = await axios.post(Path.entityContributionComponentVariance, payload);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    },
);

export const getCustomergroupComponentVariance = createAsyncThunk(
    'keyMetrics/getCustomergroupComponentVariance',
    async (payload, thunkAPI) => {
        try {
            const resp = await axios.post(Path.customergroupComponentVariance, payload);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    },
);
