import { Col, Row, Skeleton, Card, Typography, Divider } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import Tables from '../../components/Tables';
import MatricsCards from '../../components/metricCards';
import '../../helpers/override.scss';
import {
    getRegionalContributionColumn,
    getRegionalContributionComponentVarianceColumn,
    getEntityContributionColumn,
    getEntityContributionComponentVarianceColumn,
    getCustomerTypeColumn,
    getCustomerTypeContributionComponentVarianceColumn,
    transformData,
    updateComponentVarianceData,
} from '../../helpers/salesAnalysis';
import useSalesAnalysis from './useSalesAnalysis';
import { useSelector } from 'react-redux';

const { Title } = Typography;

const SalesAnalysis = () => {
    const {
        salesMetrics,
        linegraph,
        regionalContribution,
        entityContribution,
        customerTypeContribution,
        regionalContributionComponentVariance,
        entityContributionComponentVariance,
        customerTypeContributionComponentVariance,
        compareTo,
        forceRender,
    } = useSalesAnalysis();
    const { isLoading: metricsLoading, data: metricsData } = salesMetrics;
    const {
        isLoading: regionalContributionLoading,
        data: { salesEntityContributions: regionalContributionData },
    } = regionalContribution;
    const {
        isLoading: entityContributionLoading,
        data: { salesEntityContributions: entityContributionData },
    } = entityContribution;
    const {
        isLoading: customerTypeLoading,
        data: { salesEntityContributions: customerTypeData },
    } = customerTypeContribution;
    const {
        isLoading: regionalContributionComponentVarianceLoading,
        data: { salesEntityContributions: regionalContributionComponentVarianceData },
    } = regionalContributionComponentVariance;
    const {
        isLoading: entityContributionComponentVarianceLoading,
        data: { salesEntityContributions: entityContributionComponentVarianceData },
    } = entityContributionComponentVariance;
    const {
        isLoading: customerTypeComponentVarianceLoading,
        data: { salesEntityContributions: customerTypeComponentVarianceData },
    } = customerTypeContributionComponentVariance;
    const { overviews, updatedChartSettings, linegraphLoading } = linegraph;
    const chartTitle = overviews?.[0]?.title;

    return (
        <div className="main-container">
            {metricsLoading ? <Skeleton /> : <MatricsCards data={metricsData} forexDollar={true} />}
            <div className="jv-table">
                <Row gutter={[16, 24]}>
                    <Col span={24}>
                        {!linegraphLoading ? (
                            updatedChartSettings?.series?.[0]?.data ? (
                                <Card bordered={false} className="criclebox h-full" title={<Title>{chartTitle}</Title>}>
                                    <HighchartsReact
                                        // By adding key to random number we ensure that the Highchart rerender.
                                        key={forceRender}
                                        highcharts={Highcharts}
                                        options={updatedChartSettings}
                                    />
                                </Card>
                            ) : (
                                <>
                                    <Card bordered={false} className="criclebox h-full" title={chartTitle}>
                                        No data available for chart
                                    </Card>
                                </>
                            )
                        ) : (
                            <Skeleton />
                        )}
                    </Col>
                </Row>
                <Divider />
                <Row gutter={[16, 24]}>
                    <Col span={24}>
                        {!regionalContributionLoading ? (
                            <Tables
                                columns={
                                    getRegionalContributionColumn(
                                        regionalContributionData?.salesEntityContributionTableHeadings,
                                        compareTo,
                                    ) || []
                                }
                                data={
                                    transformData(regionalContributionData?.salesEntityContributionData, 'region') || []
                                }
                                hasTotal={true}
                                totalDataSet={regionalContributionData?.salesEntityItemTotals}
                                title={regionalContributionData?.title || ''}
                                type="regionalContribution"
                                rightSideContent={regionalContributionData?.info}
                            />
                        ) : (
                            <Skeleton />
                        )}
                    </Col>
                </Row>
                {compareTo === 'previous period' && (
                    <Row gutter={[16, 24]}>
                        <Col span={24}>
                            {!regionalContributionComponentVarianceLoading ? (
                                <Tables
                                    title={regionalContributionComponentVarianceData?.title || ''}
                                    columns={
                                        getRegionalContributionComponentVarianceColumn(
                                            regionalContributionComponentVarianceData?.salesEntityContributionTableHeadings,
                                            compareTo,
                                        ) || []
                                    }
                                    data={updateComponentVarianceData(
                                        regionalContributionComponentVarianceData?.salesEntityContributionData,
                                        regionalContributionComponentVarianceData?.salesEntityItemTotals,
                                    )}
                                    rightSideContent={regionalContributionComponentVarianceData?.info}
                                />
                            ) : (
                                <Skeleton />
                            )}
                        </Col>
                    </Row>
                )}
                <Row gutter={[16, 24]}>
                    <Col span={24}>
                        {!entityContributionLoading ? (
                            <Tables
                                title={entityContributionData?.title || ''}
                                columns={
                                    getEntityContributionColumn(
                                        entityContributionData?.salesEntityContributionTableHeadings,
                                        compareTo,
                                    ) || []
                                }
                                data={
                                    transformData(entityContributionData?.salesEntityContributionData, 'entity') || []
                                }
                                hasTotal={true}
                                totalDataSet={regionalContributionData?.salesEntityItemTotals}
                                type="entityContribution"
                                rightSideContent={regionalContributionData?.info}
                            />
                        ) : (
                            <Skeleton />
                        )}
                    </Col>
                </Row>
                {compareTo === 'previous period' && (
                    <Row gutter={[16, 24]}>
                        <Col span={24}>
                            {!entityContributionComponentVarianceLoading ? (
                                <Tables
                                    title={entityContributionComponentVarianceData?.title || ''}
                                    columns={
                                        getEntityContributionComponentVarianceColumn(
                                            entityContributionComponentVarianceData?.salesEntityContributionTableHeadings,
                                            compareTo,
                                        ) || []
                                    }
                                    data={updateComponentVarianceData(
                                        entityContributionComponentVarianceData?.salesEntityContributionData,
                                        entityContributionComponentVarianceData?.salesEntityItemTotals,
                                    )}
                                    rightSideContent={entityContributionComponentVarianceData?.info}
                                />
                            ) : (
                                <Skeleton />
                            )}
                        </Col>
                    </Row>
                )}
                <Row gutter={[16, 24]}>
                    <Col span={24}>
                        {!customerTypeLoading ? (
                            <Tables
                                title={customerTypeData?.title || ''}
                                columns={
                                    getCustomerTypeColumn(
                                        customerTypeData?.salesEntityContributionTableHeadings,
                                        compareTo,
                                    ) || []
                                }
                                data={transformData(customerTypeData?.salesEntityContributionData, 'customer') || []}
                                hasTotal={true}
                                totalDataSet={regionalContributionData?.salesEntityItemTotals}
                                type="customerTypeContribution"
                                rightSideContent={regionalContributionData?.info}
                            />
                        ) : (
                            <Skeleton />
                        )}
                    </Col>
                </Row>
                {compareTo === 'previous period' && (
                    <Row gutter={[16, 24]}>
                        <Col span={24}>
                            {!customerTypeComponentVarianceLoading ? (
                                <Tables
                                    title={customerTypeComponentVarianceData?.title || ''}
                                    columns={
                                        getCustomerTypeContributionComponentVarianceColumn(
                                            customerTypeComponentVarianceData?.salesEntityContributionTableHeadings,
                                            compareTo,
                                        ) || []
                                    }
                                    data={updateComponentVarianceData(
                                        customerTypeComponentVarianceData?.salesEntityContributionData,
                                        customerTypeComponentVarianceData?.salesEntityItemTotals,
                                    )}
                                    rightSideContent={customerTypeComponentVarianceData?.info}
                                />
                            ) : (
                                <Skeleton />
                            )}
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
};

export default SalesAnalysis;
